export const EXCEL_COLUMNS_FOR_FORMATTING = {
  lw_revenue: {
    value: 18,
    isFractional: true,
    isCurruncy: true,
  },
  lw_margin: {
    value: 19,
    isFractional: true,
    isCurruncy: true,
  },
  price: {
    value: 21,
    isFractional: true,
    isCurruncy: true,
  },
  tw_revenue: {
    value: 34,
    isFractional: true,
    isCurruncy: true,
  },
  tw_margin: {
    value: 35,
    isFractional: true,
    isCurruncy: true,
  },
  tw_price: {
    value: 37,
    isFractional: true,
    isCurruncy: true,
  },
  lw_qty: 17,
  tw_qty: 33,
  bulk_remaining: 22,
  bulk_remaining_intransit: 23,
  oh: 24,
  oo: 25,
  it: 26,
  wos: {
    value: 27,
    isFractional: true,
  },
  // bulk_remaining_us: 27,
  // oo_us: 29,
  // it_us: 30,
  // wos_us: {
  //   value: 31,
  //   isFractional: true,
  // },
  tw_qty: {
    value: 33,
  },
  // oh_ca: 34,
  // oo_ca: 35,
  // it_ca: 36,
  // wos_ca: {
  //   value: 37,
  //   isFractional: true,
  // },
  stock_out: 29,
  shortfall: 30,
  normal: 31,
  excess: 32,
};

export const LOST_SALES_EXCEL_COLUMNS_FORMATTING = {
  lost_sales: {
    value: 16,
    isFractional: true,
    isCurruncy: true,
  },
};

export const ALLOCATION_DEEP_DIVE_EXCEL_COLUMNS_FORMATTING = {
  sku_depth_store: {
    value: 21,
    isFractional: true,
    isCurruncy: false,
  },
  grade_revenue: {
    value: 26,
    isFractional: true,
    isCurruncy: true,
  },
  revenue: {
    value: 27,
    isFractional: true,
    isCurruncy: true,
  },
  grade_margin: {
    value: 28,
    isFractional: true,
    isCurruncy: true,
  },
  margin: {
    value: 29,
    isFractional: true,
    isCurruncy: true,
  },

};

export const DAILY_SUMMARY_EXCEL_COLUMNS_FORMATTING = {
  units_allocated: 17,
  dc_available: 18,
  oh: 19,
  oo: 20,
  it: 21

};

export const EXCESS_INV_COLUMNS_FORMATTING = {
  inv: 13,
  unit_sold: 15,
  excess_inv: 16,
  excess_inv_cost : {
    value: 19,
    isFractional: true,
    isCurruncy: true,
  },
}

export const DEFAULT_CONSTRAINTS_COLUMNS_FORMATTING = {
  min_stock: 7,
  max_stock: 8,
  wos: 9
}