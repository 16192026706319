import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from 'lodash';
import ReactTable from '../../../../components/Table/ReactTable';
import WithDynamicPlugins from '../../../../components/Table/WithPlugins';
import Select from "../../../../components/Filters/SingleSelect";
import * as storeConfigDetailsActions from '../../actions/StoreDetailsConfigActions/StoreConfigActions';
import * as StoreDetailsConsts from './StoreDetailsDcConfigConstants';
import "./Storedetailsconfig.css";
import { getStoreGradeColumnNamesByClient } from "../../../../utils/commonUtilities"

/**
 * Table Plugin
 */
const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

/**
 * Week Level Filetring Component
 * @param {*} props 
 * @returns 
 */
const SisterStoreSectionView = (props) => {
    const { storeConfigDetailsActionsProp, sisterStoreDetailsData, sisterStoreOptionsData,
        selectedSisterStore, selectedSisterStoreCode, isDcSourceDataSuccess } = props;

    useEffect(() => {
        const { editModeDetails } = props;
        if (!isEmpty(editModeDetails) && editModeDetails?.isEditMode) {
            storeConfigDetailsActionsProp.setSelectedSisterStoreCode(
                {
                    value: editModeDetails?.editModeSisterStoreCode,
                    label: editModeDetails?.editModeSisterStoreCode
                }
            );
            const selectedStore = sisterStoreDetailsData.filter(detail => detail.store_code === editModeDetails?.editModeSisterStoreCode);
            storeConfigDetailsActionsProp.setSelectedSisterStoreDetail(selectedStore);
        }
    }, []);

    const onSisterStoreNumberChange = (storeCode) => {
        storeConfigDetailsActionsProp.setSelectedSisterStoreCode(storeCode);
        const selectedStore = sisterStoreDetailsData.filter(detail => detail.store_code === storeCode.value);
        storeConfigDetailsActionsProp.setSelectedSisterStoreDetail(selectedStore);
        storeConfigDetailsActionsProp.setEditModeDetails({
            isEditMode: false,
        });
    };

    function handleImportDc() {
        const { editModeDetails } = props;

        if (!isEmpty(editModeDetails) && editModeDetails?.isEditMode) {
            storeConfigDetailsActionsProp.getDcSourceDetailsData({
                store_code: editModeDetails?.editModeSisterStoreCode,
                is_edit: true,
            });
        } else {
            storeConfigDetailsActionsProp.getDcSourceDetailsData({
                store_code: selectedSisterStore[0].store_code,
            });
        }
    }

    const sisterDetailsTableColumns = React.useMemo(
        () => [
            {
                // sticky: "left",
                Header: StoreDetailsConsts.STORE_NUMBER_FIELD_LABEL,
                accessor: 'store_code',
                // disableFilters: true,
                width: 80,
            },
            {
                // sticky: "left",
                Header: StoreDetailsConsts.SAP_ID,
                accessor: 'sap_id',
                // disableFilters: true,
                width: 80,
            },
            {
                // sticky: "left",
                Header: StoreDetailsConsts.STORE_NAME_FIELD_LABEL,
                accessor: 'store_name',
                width: 120,
            },
            getStoreGradeColumnNamesByClient(),
            {
                Header: StoreDetailsConsts.REGION_FIELD_LABEL,
                accessor: 'region',
                width: 150,
            },
        ],
        []
    );

    return (
        <div id="SISTER_STORE_CONTAINER" className="sister-store-container">
            <div id="SISTER_STORE_SEARCH_FEILD" className="p-field p-grid required">
                <label className="non-editable-labels p-col-fixed">
                    {StoreDetailsConsts.SISTER_STORE_SEARCH_FIELD_LABEL}
                </label>
                <div className="p-col" style={{ marginTop: "1%" }}>
                    <Select
                        id="NEW_SISTER_STORE_SELECTOR"
                        name="storeNumber"
                        value={selectedSisterStoreCode}
                        onChange={onSisterStoreNumberChange}
                        placeHolder="Select Store"
                        options={sisterStoreOptionsData}
                    />
                </div>
            </div>
            {(selectedSisterStore && selectedSisterStore.length > 0 ?
                <>
                    <div className="sister-store-table mx-auto">
                        <ReactTableWithPlugins
                            shouldPagination={false}
                            data={selectedSisterStore}
                            columns={sisterDetailsTableColumns}
                            renderMarkup='TableMarkup'
                            tableId="newstore_sisterstore"
                        />
                    </div>
                    {
                        !isDcSourceDataSuccess ?
                            < button id="DC_IMPORT_BUTTON" className="btn btn-primary" onClick={() => handleImportDc()}>
                                {StoreDetailsConsts.BUTTON_IMPORT_DC_LABEL}
                            </button>
                            : null
                    }
                </>
                : null)
            }
        </div >
    );
}

const mapStateToProps = ({ newStoreDetailConfig }) => ({
    sisterStoreDetailsData: newStoreDetailConfig.sisterStoreDetailsData,
    sisterStoreOptionsData: newStoreDetailConfig.sisterStoreOptionsData,
    isDcSourceDataSuccess: newStoreDetailConfig.isDcSourceDataSuccess,
    selectedSisterStore: newStoreDetailConfig.selectedSisterStore,
    selectedSisterStoreCode: newStoreDetailConfig.selectedSisterStoreCode,
    editModeDetails: newStoreDetailConfig?.editModeDetails,
});

const mapDispatchToProps = (dispatch) => ({
    storeConfigDetailsActionsProp: bindActionCreators(storeConfigDetailsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SisterStoreSectionView);