import React, { useState } from "react";
import ColumnSelection from "./ColumnSelection";
import PageLoader from "../Loader/PageLoader";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Api from "../../configs/api_config";
import { SAVE_USER_PREFERENCE_COLUMNS } from "../../constants/apiConstants";
import * as Notify from "../Notification/Notifications";

const ColumnButton = (props) => {
  const { freezeColumns, tableId } = props;

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const saveChanges = async (column_preferences) => {
    const user_id = localStorage.getItem("user_id");
    setLoading(true);

    localStorage.setItem(
      "column_preferences",
      JSON.stringify(column_preferences)
    );
    let response = await Api.post(SAVE_USER_PREFERENCE_COLUMNS, {
      user_id,
      column_preferences: [column_preferences],
    });
    if (response?.data?.status) {
      setLoading(false);
      setTimeout(() => {
        setShowModal(false);
        Notify.success("Column preference saved successfully!");
      }, 200);
    }
  };

  const saveFreezeColumns = (columns) => {
    freezeColumns(columns);
    const pref = JSON.parse(localStorage.getItem("column_preferences"));
    const col_pref = {
      ...pref,
      freezedColumns: { ...(pref?.freezedColumns || {}), [tableId]: columns },
    };
    saveChanges(col_pref);
  };

  return (
    <>
      <button className="btn btn-primary px-2 mb-4" onClick={toggleModal}>
        Columns
      </button>

      {showModal && (
        // <div>
        <Modal
          onClosed={toggleModal}
          contentClassName="modalContent"
          scrollable={true}
          isOpen={showModal}
          modalTransition={{ timeout: 600 }}
          backdropTransition={{ timeout: 700 }}
          toggle={toggleModal}
          zIndex={10003}
        >
          <ModalHeader toggle={toggleModal} className="modalHeader">
            {"Select Columns"}
          </ModalHeader>
          <PageLoader gridLoader={true} loader={loading}>
            <ModalBody
              style={{
                height: "35rem",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <ColumnSelection
                {...props}
                saveChanges={saveChanges}
                saveFreezeColumns={saveFreezeColumns}
              />
            </ModalBody>
            <ModalFooter className="modalFooter">
              <Button color="secondary" onClick={toggleModal}>
                Close
              </Button>
            </ModalFooter>
          </PageLoader>
        </Modal>
        // </div>
      )}
    </>
  );
};

export default ColumnButton;
