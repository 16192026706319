import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import './Constraints.css'
import Constraints from './Constraints'
import DefaultConstraints from './DefaultConstraints'
import { connect } from 'react-redux'
import * as Notify from "../../components/Notification/Notifications";


const ConstraintsContainer = (props) => {
    const [activeTab, setActiveTab] = useState("constraints");
   
    const handleTabSelect = (key) => {
        setActiveTab(key);
    }

    // useEffect(() => {
    //     if (props?.location?.state?.storeDetails || props?.location?.state?.isNewStore) {
    //         setActiveTab("newstore")
    //     }
    // }, [props?.location?.state?.storeDetails, props?.location?.state?.isNewStore])

    // useEffect(()=>{
    //     if(props?.location?.state?.data){
    //         setActiveTab("constraints")
    //     }
    // },[props?.location?.state?.data])

    useEffect(() => {
      props.isUpdated &&
        !props.error &&
        Notify.success("Updated successfully!");
    }, [props.isUpdated]);

    useEffect(() => {
      if (props.error) {
        Notify.error("Failed in applying your edits!!");
      }
    }, [props.error]);
    
    return (
        <div className="product__store__status__container">
            <div className="container__header">
                <h1 className="fnt-lg fnt-bold">Constraints</h1>
            </div>
            <div className="container__body">
                <div className="container__tabs">
                    <Tabs defaultActiveKey="constraints" activeKey={activeTab} onSelect={handleTabSelect}>
                         <Tab eventKey="constraints" title="Edit Constraints">
                            {
                                activeTab === "constraints" &&
                                <Constraints />
                            }
                        </Tab> 
                        <Tab eventKey="defConstraints" title="Set Default Constraints">
                            {
                                activeTab === "defConstraints" &&
                                <DefaultConstraints />
                            }
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ constraints }) => ({
    isUpdated: constraints.isUpdated,
    error: constraints?.error,
  });
  
  const mapDispatchToProps = (dispatch) => ({
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(ConstraintsContainer);
