import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import '../ProductStoreStatus.css'
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import { getProductStatusData, getArticleConfigData, deleteProductConfig, resetAll, resetCreateConfigData } from "./Actions/ProductStatusAction"
import ProductStatusFilters from "./ProductStatusFilters"
import ArticleConfigTable from "./ArticleConfigTable"
import ColumnFilter from "../../UserManagement/ColumnFilter";
import EditIconCell from "../../../components/Table/Cellrenderer/EditIconCell";
import PageLoader from '../../../components/Loader/PageLoader';
import { CREATE_CONFIGURATION } from "../../../constants/routeConstants";
import * as Notify from '../../../components/Notification/Notifications'
import Notification from '../../../components/Notification/Notifications'
import MissingConfigTable from "./MissigConfigTable"
import { hideTabForClientID, hideToProd } from '../../../utils/commonUtilities';
import { levelFiltersLabelFormatter } from '../../../utils/filterLevelMapping';
import { cloneDeep, isEmpty } from 'lodash';
import { ERROR_MSG } from '../../../constants/globalConstant';
import { updateAutoAllocationRequired } from './Actions/CreateConfigAction';
import { getArticleMapping } from '../../../routes/api';
import ParallelExcelDownload from '../../../components/ParallelExcelDownload/ParallelExcelDownload';
import { EXCESS_INV_COLUMNS_FORMATTING } from '../../../components/ExportFile/ExportExcelConstants';
import { EXCEL_KEYS, formatExcelData, TABLE_COLUMNS } from './ProductStatusConstants';

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

let selectedFilters = {}

const ProductStatus = (props) => {
    const history = useHistory();
    const [isPopupOpened, setIsPopupOpened] = useState(false)
    const [filters, setFilters] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [showArticleConfig, setShowArticleConfig] = useState(false)
    const [showCreateConfig, setShowCreateConfig] = useState(false)
    const [selectedConfigId, setSelectedConfigId] = useState(null)
    const [articleData, setArticleData] = useState([])
    const [selectedConfigName, setConfigName] = useState("")
    const [selectedConfigFilters, setSelectedConfigFilters] = useState({})
    const [articleConfigData, setArticleConfigData] = useState([])
    const [RTInstance, setRTInstance] = useState({})
    const [initialIndex, setInitialIndex] = useState(0)
    const [prevIndex, setPrevIndex] = useState(0)
    const [nextIndex, setNextIndex] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [searchTermReq, setSearchTermReq] = useState({})
    const [sortReq, setSortReq] = useState({})
    const [isFirstCall, setisFirstCall] = useState(true)
    const [keyRT, setKeyRT] = useState("")
    const [checkAllReset, setCheckAllReset] = useState(false)
    const [pageSize, setPageSize] = useState(10);

    const columns = React.useMemo(
        () => [
            {
                Header: "Configuration Profile",
                accessor: "configuration_name",
                Cell: (instance) => (
                    <div>
                        <div className="clickable" onClick={() => getArticleConfiguration(instance)}>{instance.value}</div>
                    </div>
                ),
                Filter: ColumnFilter,
                sticky: "left"
            },
            {
                Header: levelFiltersLabelFormatter("level1"),
                accessor: "l1_name",
                Filter: ColumnFilter

            },
            {

                Header: levelFiltersLabelFormatter("level2"),
                accessor: "l2_name",
                Filter: ColumnFilter
            },
            {
                Header: levelFiltersLabelFormatter("level3"),
                accessor: "l3_name",
                Filter: ColumnFilter
            },
            {
                Header: levelFiltersLabelFormatter("level4"),
                accessor: "l4_name",
                Filter: ColumnFilter
            },
            {
                Header: levelFiltersLabelFormatter("level5"),
                accessor: "l5_name",
                Filter: ColumnFilter
            },
            {
                Header: levelFiltersLabelFormatter("level6"),
                accessor: "l6_name",
                Filter: ColumnFilter
            },
            {
                Header: levelFiltersLabelFormatter("level7"),
                accessor: "l7_name",
                Filter: ColumnFilter
            },
            {
                Header: "Style",
                accessor: "style",
                Filter: ColumnFilter
            },
            {
                Header: "Color",
                accessor: "color",
                Filter: ColumnFilter
            },
            {
                Header: "Product Type",
                accessor: "product_type",
                Filter: ColumnFilter
            },
            {
                Header: "Created By",
                accessor: "created_by",
                Filter: ColumnFilter,
                width: 250
            },
            {
                Header: "Created On",
                accessor: "created_on",
                Filter: ColumnFilter
            },
            {
                Header: "Last Modified",
                accessor: "last_modified",
                Filter: ColumnFilter
            },
            {
                Header: "Actions",
                accessor: "actions",
                sticky: "right",
                Cell: (instance) => (
                    <div>
                        <EditIconCell
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            instance={instance}
                        ></EditIconCell>
                    </div>
                ),
                disableFilters: true,
                resizable: true,
                width: 100,
            },
        ])

    const handleEdit = (instance) => {
        let filters = {
            l1_name: instance.l1_name,
            configuration_id: instance.configuration_id
        }
        let remainingFilters = ["l2_name", "l3_name", "l4_name", "l5_name", "l6_name", "l7_name", "color", "style"]
        remainingFilters.forEach(key => {
            if(instance[key]) {
                filters[key] = instance[key]
            }
        })
        history.push(`${CREATE_CONFIGURATION}`, { filters });
    };

    const handleDelete = (instance) => {
        if (window.confirm("Are you sure you wish to delete this configuration?")) {
            props.deleteProductConfig({ configuration_id: instance.configuration_id })
        }
    };

    const getProductStatusData = (filters) => {
        let reqFilters = {}
        for(let key in filters) {
            if(filters[key]) {
                filters[key] && (reqFilters[key] = filters[key])
            }
        }
        setNextIndex(0)
        setArticleConfigData([])
        setInitialIndex(0)
        setPrevIndex(0);
        setCheckAllReset(prev => !prev)
        // setShowArticleConfig(false)
        setFilters(reqFilters)
        if (reqFilters) {
            selectedFilters = reqFilters
        }
        props.getArticleConfigData({...selectedFilters, searchColumns: searchTermReq, sortColumn: sortReq, row_index: 0, row_count: 1000})
        // props.getProductStatusData(selectedFilters)
    }

    useEffect(() => {
        setKeyRT("ProductView")
        return () => {
            props.resetAll()
        }
    }, [])

    useEffect(() => {
        if (props.updateAutoAllocationError) {
            Notify.error("Error in Saving Dates !!");
        }
        if (props.updateAutoAllocationSuccess  && keyRT === "ProductView") {
            Notify.success("Saved Successfully!!");
            getProductStatusData(filters)
        }
    }, [props.updateAutoAllocationSuccess, props.updateAutoAllocationError]);


    const getArticleConfiguration = (instance) => {
        // setArticleData([])
        let rowData = instance?.row?.original
        let selectedRowFilters = {}
        if (rowData?.l1_name) {
            selectedRowFilters.l1_name = rowData?.l1_name
        }
        if (rowData?.l2_name) {
            selectedRowFilters.l2_name = rowData?.l2_name
        }
        if (rowData?.l3_name) {
            selectedRowFilters.l3_name = rowData?.l3_name
        }
        if (rowData?.l4_name) {
            selectedRowFilters.l4_name = rowData?.l4_name
        }
        if (rowData?.style) {
            selectedRowFilters.style = rowData?.style
        }
        if (rowData?.color) {
            selectedRowFilters.color = rowData?.color
        }
        let configuration_id
        if (instance) {
            setSelectedConfigFilters(selectedRowFilters)
            // configuration_id = instance?.row?.original?.configuration_id
            // setSelectedConfigId(configuration_id)
            // setConfigName(instance?.row?.original?.configuration_name)
        }
        // if (configuration_id || selectedConfigId) {
        //     setShowArticleConfig(true)
        //     props.getArticleConfigData({ configuration_id: configuration_id ? configuration_id : selectedConfigId, ...selectedRowFilters })
        // }
    }

    const goToCreateConfig = () => {
        history.push(`${CREATE_CONFIGURATION}`);
    }

    useEffect(() => {
        if (props.deleteConfigData?.status) {
            getProductStatusData()
            setArticleData([])
            Notify.success(`Configuration Deleted Successfully!!`)
            props.resetCreateConfigData()
        }
        else if (props.deleteConfigDataError) {
            Notify.error( ERROR_MSG)
            props.resetCreateConfigData()

        }
    }, [props.deleteConfigData, props.deleteConfigDataError])

    useEffect(() => {
        if (props.articleData?.length) {
            setArticleData(props.articleData)
        }
    }, [props.articleData])

    useEffect(() => {
        if (props.selectedFilters) {
            setSearchTermReq({});
            setSortReq({});
            setFilters(props.selectedFilters)
            getProductStatusData(props.selectedFilters)
        }
    }, [props.selectedFilters])

    const fetchData = (index) => {
        if (prevIndex >= index || nextIndex >= totalCount)
            return;

        let length = document.getElementById("page_size_select")?.value;
        if(length) {
            setPageSize(length);
        }
        else {
            length = pageSize;
        }
        setPrevIndex(index)
        setInitialIndex(Math.floor(nextIndex / length))
        props.getArticleConfigData({ ...selectedFilters, row_index: nextIndex, row_count: 1000, searchColumns: searchTermReq, sortColumn: sortReq })
    }

    useEffect(() => {
        if (props?.articleData?.length) {
            if (RTInstance?.data?.length) {
                setArticleConfigData([...RTInstance.data, ...props?.articleData])
            }
            else {
                setArticleConfigData(props?.articleData)
            }
        }
    }, [props?.articleData])

    const fecthDataWithSearchTerm = (p_term, p_id, p_type, split_type) => {
        if (p_type === "array") {
          if(split_type) {
            p_term = p_term.replace(/\s*,\s*/g, ",")
          }
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                var id = p_term.split(split_type ? split_type : " ")
                if (p_term?.trim() == 0) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {

                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "values": id,
                        "search": p_term
                    }
                }
                return {
                    ...l_searchTermReq
                }
            })
        }
        else if (Array.isArray(p_term)) {
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                let min = p_term[0] == "" ? null : p_term[0]
                let max = p_term[1] == "" ? null : p_term[1]
                if (min == null && max == null) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {
                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "value": {
                            "min": min,
                            "max": max,
                        }
                    }
                }
                return {
                    ...l_searchTermReq,
                }
            })
        }
        else {
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                if (p_term?.trim() == 0) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {
                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "value": p_term
                    }
                }
                return {
                    ...l_searchTermReq,
                }
            })
        }
        isFirstCall && setisFirstCall(false)

    }

    const fecthDataWithSorting = (p_sort) => {
        if (!isEmpty(sortReq)) {
            isFirstCall && setisFirstCall(false)
        }
        setSortReq(p_sort[0])
    }

    useEffect(() => {
        if (props.nextIndex == 100) {
            setPrevIndex(0)
            // setInitialIndex(0)
        }
        setTotalCount(props.reviewCall ? props.recDatatotalCount : props.totalCount)
        setNextIndex(props.reviewCall ? props.recDatanextIndex : props.nextIndex)
    }, [props.totalCount, props.nextIndex, props.recDatanextIndex, props.recDatatotalCount])

    useEffect(() => {
        if ((!isEmpty(searchTermReq) || !isFirstCall || !isEmpty(sortReq)) && !isEmpty(filters)) {
            setArticleConfigData([])
            setRTInstance(null)
            setInitialIndex(0)
            setPrevIndex(0)
            props.getArticleConfigData({...filters, row_index: 0, row_count: 1000, searchColumns: searchTermReq, sortColumn: sortReq})
        }
    }, [searchTermReq, sortReq])

    const updateArticleData = (setAllRows, allocationStatus, fromDate, uptoDate, req) => {
        setArticleConfigData((oldData) => {
            let newData = cloneDeep(oldData);
            setAllRows.forEach(setAllRow => {
                newData[setAllRow]["allocation_status"] =
                allocationStatus;
                newData[setAllRow]["from_date"] = fromDate;
                newData[setAllRow]["to_date"] = uptoDate;
                newData[setAllRow]["updated_by"] = localStorage?.getItem("email");
            });
            return newData;
        });
        if (!isEmpty(searchTermReq)) {
            req = {...req, searchColumns: searchTermReq}
        }
        if (!isEmpty(sortReq)) {
            req = {...req, sortColumn: sortReq}
        }
        props.updateAutoAllocationRequired(req);
    }

    const saveProductStatusData = (uniqueUpdatedIds, setAllRows) => {
        // Update the table instance updated_by to current user
        setAllRows.map((item) => {
            RTInstance?.updateMyData(item, "updated_by", localStorage?.getItem("email"));
        })
        let data = RTInstance?.data?.filter((val) =>
          uniqueUpdatedIds.includes(val.article)
        );
        let autoAllocationInline = [];
        // Create updated data for all the articles.
        autoAllocationInline = data.map(
          ({ article, from_date, to_date, allocation_status }) => {
              return {
                article,
                from_date: from_date === "-" ? null : from_date,
                to_date: to_date === "-" ? null : to_date,
                allocation_status,
              };
          }
        );
        
        let req = {
            ...filters,
            allocation_status_inline: autoAllocationInline,
        };
        if (!isEmpty(searchTermReq)) {
            req = {...req, searchColumns: searchTermReq}
        }
        if (!isEmpty(sortReq)) {
            req = {...req, sortColumn: sortReq}
        }
        props.updateAutoAllocationRequired(req);
    }

    const applyFilters = (filters) => {
        setSearchTermReq({});
        setSortReq({});
        getProductStatusData(filters)
    }

    return (

        <div className="product__store__status__container">
            <div className="container__header__with__button config-header">
                <h1 className="fnt-lg fnt-bold">Product Status</h1>
                {/* <div className="row config-btn-container" onClick={goToCreateConfig}>
                    <button className="btn-primary-rounded create-config-btn mr-2">
                        <i
                            className="fa fa-plus"
                            title="Create Allocation"
                            aria-hidden="true"
                        ></i>
                    </button>
                    <p className="fnt-sm m-0">Create Configuration</p>
                </div> */}
            </div>
            <div className="row">
                <ProductStatusFilters applyFilters={applyFilters}
                    // selectedFilters={filters} 
                    isPopupOpened={isPopupOpened} landingScreen/>
            </div>
            {
                !hideTabForClientID('missing_config') &&
                <MissingConfigTable filters = {filters} />
            }
            <section className="row section">
                {articleConfigData?.length ? 
                    <ParallelExcelDownload 
                        apiURL={getArticleMapping} 
                        filters={filters} 
                        formatExcelData={formatExcelData}
                        existingData = {articleConfigData}
                        searchTermReq={searchTermReq}
                        sortReq={sortReq}
                        totalCount={totalCount}
                        nextIndex={nextIndex}
                        fileName={`product_configuration${new Date().getTime()}`}
                        headers={Object.values(TABLE_COLUMNS)}
                        columnOrder={EXCEL_KEYS}
                        tableId = "product_config_article1"
                    /> : ""
                }
                {/* {
                    !isPopupOpened &&
                     <Notification /> 
                } */}
                 {(props.articleDataError) ? (<div className="error">{props.articleDataError}</div>) :
                        <ArticleConfigTable data={articleConfigData} dataLoading={props.articleDataLoading} filters={filters}
                            setIsPopupOpened={setIsPopupOpened} configuration_id={selectedConfigId} config_name={selectedConfigName}
                            getProductStatusData={getProductStatusData} selectedConfigFilters={selectedConfigFilters}
                            getArticleConfigData={getArticleConfiguration} 
                            setRTInstance={setRTInstance}
                            fetchData={fetchData}
                            initialPageIndex={initialIndex}
                            totalCount={totalCount}
                            fecthDataWithSorting={fecthDataWithSorting}
                            fecthDataWithSearchTerm={fecthDataWithSearchTerm}
                            searchTermReq={searchTermReq}
                            sortReq={sortReq}
                            updateArticleData={updateArticleData}
                            saveProductStatusData={saveProductStatusData}
                            checkAllReset={checkAllReset}
                        />
                    }
                {/* {(props.dataError) ? (<div className="error">{props.dataError}</div>) :
                    <PageLoader loader={props.dataLoading || props.deleteConfigDataLoading} gridLoader={true}>
                        <div className="storesStore__heading mb-4">
                            <h1 className="fnt-md fnt-bold">Product Configurations</h1>
                        </div>
                        <ReactTableWithPlugins
                            shouldPagination
                            totalRecordsLen={props.data?.length}
                            data={props.data}
                            columns={columns}
                            renderMarkup='TableMarkup'
                            keyRT="sortAndSearch"
                            tableId="product_config"
                        />
                    </PageLoader>
                } */}
            </section>
            {/* {
                showArticleConfig &&
                <section className="row section">
                    {(props.articleDataError) ? (<div className="error">{props.articleDataError}</div>) :
                        <ArticleConfigTable data={articleData} dataLoading={props.articleDataLoading} filters={filters}
                            setIsPopupOpened={setIsPopupOpened} configuration_id={selectedConfigId} config_name={selectedConfigName}
                            getProductStatusData={getProductStatusData} selectedConfigFilters={selectedConfigFilters}
                            getArticleConfigData={getArticleConfiguration} />
                    }
                </section>
            } */}
        </div>
    )
}

const mapStateToProps = ({ productStatus }) => ({
    data: productStatus.data,
    dataError: productStatus.dataError,
    dataLoading: productStatus.dataLoading,
    articleData: productStatus.articleData,
    articleDataLoading: productStatus.articleDataLoading,
    articleDataError: productStatus.articleDataError,
    deleteConfigDataLoading: productStatus.deleteConfigDataLoading,
    deleteConfigData: productStatus.deleteConfigData,
    deleteConfigDataError: productStatus.deleteConfigDataError,
    selectedFilters: productStatus.selectedFilters,
    totalCount: productStatus.productConfigTotalCount,
    nextIndex: productStatus.productConfigNextIndex,
    updateAutoAllocationSuccess: productStatus.updateAutoAllocationSuccess,
    updateAutoAllocationError: productStatus.updateAutoAllocationError,
})

const mapDispatchToProps = (dispatch) => ({
    getProductStatusData: (payload) => dispatch(getProductStatusData(payload)),
    getArticleConfigData: (payload) => dispatch(getArticleConfigData(payload)),
    deleteProductConfig: (payload) => dispatch(deleteProductConfig(payload)),
    resetCreateConfigData: () => dispatch(resetCreateConfigData()),
    resetAll: () => dispatch(resetAll()),
    updateAutoAllocationRequired: (payload) => dispatch(updateAutoAllocationRequired(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductStatus)
