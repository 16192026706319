import { cloneDeep, isEmpty } from "lodash";
import React, { useState, useEffect } from "react";
import SetAllModal from "../../../components/ErrorModal/SetAllModal";
import moment from "moment";
import DatePicker, { today } from "../../../components/DatePicker/DatePicker";

const EditModal = (props) => {
    const { DC_OPTIONS, closeModal, selectedRows, updateStoreStatusData } = props;

    const STATUS_OPTIONS = [
        {
          label: "Open",
          value: "open",
        },
        {
          label: "Closed",
          value: "closed",
        },
        {
          label: "Under Renovation",
          value: "under_renovation",
        },
      ];

    const [updatedValues, setValue] = useState({});
    const [primaryDCOptions, setDCOptions] = useState([]);
    const [secondaryDCOptions, setSecondaryDCOptions] = useState([]);
    const [dcMap, setDCMap] = useState({});

    const onChange = (val, key) => {
      let preObj = cloneDeep(updatedValues);
      if(val === "Invalid date") {
        val = "";
      }
      preObj[key] = val;
      if(key === "primary_dc") {
        preObj["secondary_dc"] = [];
        if(val?.length) {
          generateSecondaryDCOptinons(val);
        }
        else {
          setSecondaryDCOptions([]);
        }
      }
      else if(key === "inactive_start" && preObj["inactive_end"]) {
        preObj["upto"] = new Date(preObj["upto"]) >= new Date(val) ? preObj["upto"] : ""
      }
      setValue(preObj);
    }

    const handleClose = () => {
        closeModal(false);
    }

    const handleSetAllCancel = () => {
        closeModal(false);
    }

    const generateSecondaryDCOptinons = (primary_dc) => {
      let primary = primary_dc?.map((dc) => dc.value);

      setSecondaryDCOptions(
        primaryDCOptions
          ?.filter((dc) => !primary?.includes(dc.value))
          .map((dc) => {
            return { label: dc.label, value: dc.value };
          })
      );
    }

    useEffect(() => {
        let map = {
          "retail": {},
          "outlet": {}
        };
        DC_OPTIONS.forEach(dc => {
          if(dc.channel === "Retail-Store") {
            map["retail"][dc.label] = dc.value;
          }
          else {
            map["outlet"][dc.label] = dc.value;
          }
        })
        setDCMap(map);
        let dcValues = [];
        for(let key in map.retail) {
          dcValues.push({label: key, value: map["retail"][key]})
        }
        setDCOptions(dcValues)
        
    }, [DC_OPTIONS])

    const handleSetAllOk = () => {
      let data = [];
      let newRows = selectedRows.filter(item => !item.original.disable_status)
      newRows.forEach(row => {
        row = row.original;
        let primary_dc = updatedValues?.primary_dc?.length ? updatedValues?.primary_dc : row.primary_dc;
        let secondary_dc = updatedValues?.primary_dc?.length ? (updatedValues?.secondary_dc?.length ? updatedValues?.secondary_dc : []) : row.secondary_dc;

        let primaryCopy = cloneDeep(primary_dc);
        let secondaryCopy = cloneDeep(secondary_dc);
        const maxLength = primaryCopy?.length > secondaryCopy?.length ? primaryCopy?.length : secondaryCopy?.length;

        for(let i = 0; i < maxLength; i++) {
          if(row.channel === "Retail-Store") {
            if(primaryCopy[i]) {
              primaryCopy[i] = { label: primaryCopy[i].label, value: dcMap["retail"][primaryCopy[i].label] }
            }
            if(secondaryCopy[i]) {
              secondaryCopy[i] = { label: secondaryCopy[i].label, value: dcMap["retail"][secondaryCopy[i].label] }
            }
          }
          else {
            if(primaryCopy[i]) {
              primaryCopy[i] = { label: primaryCopy[i].label, value: dcMap["outlet"][primaryCopy[i].label] }
            }
            if(secondaryCopy[i]) {
              secondaryCopy[i] = { label: secondaryCopy[i].label, value: dcMap["outlet"][secondaryCopy[i].label] }
            }
          }
        }
        const store_status = row.store_status === "closed" ? row.store_status : (updatedValues?.store_status?.value ? updatedValues?.store_status?.value : row.store_status);

        const obj = {
          store_code: row.store_code,
          store_status,
          inactive_start: updatedValues?.store_status?.value ? updatedValues?.inactive_start : row.inactive_start,
          inactive_end: updatedValues?.store_status?.value ? updatedValues?.inactive_end : row.inactive_end,
          primary_dc: primaryCopy,
          secondary_dc: secondaryCopy
        }
        if(!updatedValues?.store_status?.value) {
          delete obj.store_status;
        }
        if(updatedValues?.store_status?.value !== "under_renovation") {
          delete obj.inactive_start;
          delete obj.inactive_end;
        }
        data.push(obj);
      })
      updateStoreStatusData(data)
    }
    
    const prepInput = React.useMemo(() => [
        {
          id: 1,
          isRequired: false,
          label: "Primary DC",
          name: "primary",
          className: "setAll__filter",
          dropdownValue: updatedValues?.primary_dc,
          onDropDownChange: (val) => {
              onChange(val, "primary_dc")
          },
          // onBlur: generateSecondaryDCOptinons,
          placeHolder: `Select Primary DC`,
          optionsArray: primaryDCOptions,
          isMultiSelect: true,
          hideSelectAll: true
        },
        // {
        //     id: 2,
        //     isRequired: false,
        //     label: "Secondary DC",
        //     name: "secondary",
        //     className: "setAll__filter",
        //     dropdownValue: updatedValues?.secondary_dc,
        //     onDropDownChange: (val) => {
        //         onChange(val, "secondary_dc")
        //     },            
        //     placeHolder: `Select Secondary DC`,
        //     optionsArray: secondaryDCOptions,
        //     isMultiSelect: true,
        //     hideSelectAll: true
        // },
        {
            id: 3,
            isRequired: false,
            label: "Status",
            name: "status",
            className: "setAll__filter",
            value: updatedValues?.store_status,
            onChange: (val) => {
                onChange(val, "store_status")
            },
            placeHolder: `Select Status`,
            options: STATUS_OPTIONS,
        },
        {
            id: 4,
            isRequired: true,
            label: "From Date",
            name: "from",
            className: "setAll__filter",
            input: true,
            Comp: <DatePicker minOrmax={{ min: today }}
            onChange={(val) => onChange(moment(val).format("MM/DD/YYYY"), "inactive_start")} dateFormat="MM/DD/yyyy" />
        },
        {
            id: 5,
            isRequired: true,
            label: "Upto Date",
            name: "upto",
            className: "setAll__filter",
            input: true,
            Comp: <DatePicker minOrmax={{ min: updatedValues?.inactive_start ? moment(new Date(updatedValues?.inactive_start)).format("YYYY-MM-DD") : today }}
            onChange={(val) => onChange(moment(val).format("MM/DD/YYYY"), "inactive_end")} dateFormat="MM/DD/yyyy"
            defaultValue={updatedValues?.inactive_end} />
        },
      ], [updatedValues, primaryDCOptions]);

    const disableSave = () => {
      let disable = !updatedValues?.primary_dc?.length;
      if(!isEmpty(updatedValues?.store_status )) {
        if(updatedValues?.store_status.value === "under_renovation") {
          if(updatedValues?.inactive_start && updatedValues?.inactive_end) {
            disable = false
          }
          else {
            disable = true;
          }
        }
        else {
          disable = false;
        }
      }
      return disable;
    }

  return (
    <SetAllModal
      modalBodyStyle={{ height: '33rem' }}
      filter={updatedValues?.store_status?.value === "under_renovation" ? prepInput : prepInput.slice(0, 2)}
      messageStyle={{ margin: "auto", color: "red" }}
      handleOk={handleSetAllOk}
      handleCancel={handleSetAllCancel}
      handleClose={() => handleClose()}
      disableApply={disableSave()}
      buttonLabel="Save"
    />
  );
};

export default EditModal;
