import { getLabel } from "../../../utils/commonUtilities";
import { updateArticleName } from "../../../utils/filterLevelMapping";

export const DEEP_DIVE_TABLE_HEADERS = {
  ARTICLE_ID: `${updateArticleName(false)} ID`,
  STYLE_ID: "Style ID",
  COLOR_ID: "Color ID",
  STYLE_DESCRIPTION: "Style Description",
  STYLE_COLOR: "Style Color",
  COLOR: "Color",
  CHANNEL: "Channel",
  DEPARTMENT: "Department",
  CLASS: "Class",
  STYLEGROUP: "Style Group",
  SILHOUETTE: "Silhouette",
  SUBCOLLECT: "Sub Collect",
  PRODUCT_TYPE: "Product Type",
  STORE_ID: "Store Number",
  SAP_ID: "SAP ID",
  STORE_NAME: "Store Name",
  SEASON_CODE_ACTIVE: "Season Code Active",
  SEASON_CODE_OPEN: getLabel("season_code_open"),
  STORE_GRADE: "Store Grade",
  PLANNED_ALLOCATION: "Planned Allocation",
  ACTUAL_ALLOCATION: "Actual Allocation",
  UNIT_SALES: "Unit Sales",
  QTY_MATCH_PER: "Qty Match %",
  SIZE_MATCH_PER: "Size Match %",
  ALLOCATION_MATCH: "Qty Match %",
  SIZE_MATCH_PER_SALES: "Size Match %",
  SKU_DEPTH: "SKU Depth",
  AVG_GRADE_REVENUE: "Avg Grade Revenue",
  STORE_REVENUE: "Store Revenue",
  AVG_GRADE_MARGIN: "Avg. Grade Margin",
  STORE_MARGIN: "Store Margin",
  QTY_MATCH_PER_EXCEL: "Qty Match % (Plan vs Actual Allocation)",
  SIZE_MATCH_PER_EXCEL: "Size Match % (Plan vs Actual Allocation)",
  ALLOCATION_MATCH_EXCEL: "Qty Match % (Actual Allocation vs Sales)",
  SIZE_MATCH_PER_SALES_EXCEL: "Size Match % (Actual Allocation vs Sales)",
  SKU_DEPTH_EXCEL: "SKU Depth (Actual Allocation vs Sales)",
  AVG_GRADE_REVENUE_EXCEL: "Avg Grade Revenue (Sales Performance)",
  STORE_REVENUE_EXCEL: "Store Revenue (Sales Performance)",
  AVG_GRADE_MARGIN_EXCEL: "Avg. Grade Margin (Sales Performance)",
  STORE_MARGIN_EXCEL: "Store Margin (Sales Performance)",
};

export const EXCEL_HEADERS = [
  DEEP_DIVE_TABLE_HEADERS.ARTICLE_ID,
  DEEP_DIVE_TABLE_HEADERS.STYLE_ID,
  DEEP_DIVE_TABLE_HEADERS.COLOR_ID,
  DEEP_DIVE_TABLE_HEADERS.STYLE_DESCRIPTION,
  DEEP_DIVE_TABLE_HEADERS.STYLE_COLOR,
  DEEP_DIVE_TABLE_HEADERS.COLOR,
  DEEP_DIVE_TABLE_HEADERS.CHANNEL,
  DEEP_DIVE_TABLE_HEADERS.DEPARTMENT,
  DEEP_DIVE_TABLE_HEADERS.CLASS,
  DEEP_DIVE_TABLE_HEADERS.STYLEGROUP,
  DEEP_DIVE_TABLE_HEADERS.SILHOUETTE,
  DEEP_DIVE_TABLE_HEADERS.SUBCOLLECT,
  DEEP_DIVE_TABLE_HEADERS.PRODUCT_TYPE,
  DEEP_DIVE_TABLE_HEADERS.STORE_ID,
  DEEP_DIVE_TABLE_HEADERS.SAP_ID,
  DEEP_DIVE_TABLE_HEADERS.STORE_NAME,
  DEEP_DIVE_TABLE_HEADERS.SEASON_CODE_OPEN,
  DEEP_DIVE_TABLE_HEADERS.STORE_GRADE,
  DEEP_DIVE_TABLE_HEADERS.PLANNED_ALLOCATION,
  DEEP_DIVE_TABLE_HEADERS.ACTUAL_ALLOCATION,
  DEEP_DIVE_TABLE_HEADERS.UNIT_SALES,
  DEEP_DIVE_TABLE_HEADERS.QTY_MATCH_PER_EXCEL,
  DEEP_DIVE_TABLE_HEADERS.SIZE_MATCH_PER_EXCEL,
  DEEP_DIVE_TABLE_HEADERS.ALLOCATION_MATCH_EXCEL,
  DEEP_DIVE_TABLE_HEADERS.SIZE_MATCH_PER_SALES_EXCEL,
  DEEP_DIVE_TABLE_HEADERS.SKU_DEPTH_EXCEL,
  DEEP_DIVE_TABLE_HEADERS.AVG_GRADE_REVENUE_EXCEL,
  DEEP_DIVE_TABLE_HEADERS.STORE_REVENUE_EXCEL,
  DEEP_DIVE_TABLE_HEADERS.AVG_GRADE_MARGIN_EXCEL,
  DEEP_DIVE_TABLE_HEADERS.STORE_MARGIN_EXCEL,
];

export const CARDS = {
  CARD1: "Total Planned Allocation",
  CARD2: "Total Actual Allocation",
  CARD3: "Qty Match % (Plan vs Actual)",
  CARD4: "Qty Match % (Actual vs Sales)",
  CARD5: "Size Match % (Plan vs Actual)",
  CARD6: "Size Match % (Actual vs Sales)",
};
