import React, { useEffect, useState } from "react";
import "../../../components/Filters/Filters.css";
import MultiSelect from "../../../components/Filters/MultiSelect";
import Select from "../../../components/Filters/SingleSelect";
import { connect } from "react-redux";
import {
    resetFiltersOptions,
    fetchFilterData
} from "./Actions/ProductStatusAction";
import {
    resetTableData
} from "./Actions/CreateConfigAction";
import * as Notify from "../../../components/Notification/Notifications";
import Notification from "../../../components/Notification/Notifications";
import { handleSelectAll } from "../../../utils/SelectAllHandler/SelectAllHander";
import { levelFiltersLabelFormatter } from "../../../utils/filterLevelMapping"
import { intersectionWith, isEqual } from "lodash";
import { getUserPreferenceFilter, shouldHideForCurrentClient, setUserPreferenceFilter, getLevelFiltersForUserPreference, getDefaultFiltersCondition, isMandatory } from "../../../utils/commonUtilities";
import AsyncSingle from "../../../components/Filters/AsyncSingle";
import { isEmpty } from "lodash-es";
import AsyncMulti from "../../../components/Filters/AsyncMulti";


const ProductStatusFilters = React.memo(
    ({
        ...props
    }) => {
        const [request, setRequest] = useState(null)

        const [department, setDepartment] = useState(null);
        const [gender, setGender] = useState(null);
        const [rbu, setRbu] = useState(null);
        const [dcs, setDcs] = useState(null);
        const [level5, setLevel5] = useState(null);
        const [level6, setLevel6] = useState(null);
        const [level7, setLevel7] = useState(null);
        const [style, setStyle] = useState(null)
        const [color, setColor] = useState(null)
        const [size, setSize] = useState(null)
        const [storeGroup, setStoreGroup] = useState(null)
        const [productType, setProductType] = useState(null)

        const [departmentOptions, setDepartmentOptions] = useState(null);
        const [genderOptions, setGenderOptions] = useState(null);
        const [rbuOptions, setRbuOptions] = useState(null);
        const [dcsOptions, setDcsOptions] = useState(null);
        const [level5Options, setLevel5Options] = useState(null);
        const [level6Options, setLevel6Options] = useState(null);
        const [level7Options, setLevel7Options] = useState(null);
        const [styleOptions, setStyleOptions] = useState(null);
        const [colorOptions, setColorOptions] = useState(null);
        const [sizeOptions, setSizeOptions] = useState(null);
        const [storeGroupOptions, setStoreGroupOptions] = useState([{ value: 0, label: "Default" }, { value: 1, label: "DPI/User Mapped" }])
        const [productTypeOptions, setProductTypeOptions] = useState(null)

        const [applyToNewProducts, setApplyToNewProducts] = useState(false)

        useEffect(() => {
            setDepartmentOptions(() => props.department);
            setGenderOptions(() => props.gender);
            setRbuOptions(() => props.rbu);
            setDcsOptions(() => props.dcs)
            setLevel5Options(() => props.level5)
            setLevel6Options(() => props.level6)
            setLevel7Options(() => props.level7)
            setStyleOptions(() => props.style)
            setColorOptions(() => props.color)
            setProductTypeOptions(() => props.produtType)

        },
            [props.department, props.dcs, props.level5, props.level6, props.level7, props.gender, props.rbu, props.style,
            props.color, props.produtType]);


        useEffect(() => {
            props.fetchFilterData({}, "department");
        }, []);

        const resetRestFilters = (p_index) => {
            // props.resetTableData()
            if (p_index == 0) {
                gender && setGender(null);
                genderOptions && setGenderOptions(null)
            }
            if (p_index == 0 || p_index == 1) {
                rbu && setRbu(null);
                rbuOptions && setRbuOptions(null)
            }
            if (p_index == 0 || p_index == 1 || p_index == 2) {
                dcs && setDcs(null);
                dcsOptions && setDcsOptions(null)
                level5 && setLevel5(null);
                level5Options && setLevel5Options(null);
                level6 && setLevel6(null)
                level6Options && setLevel6Options(null);
                level7 && setLevel7(null)
                level7Options && setLevel7Options(null);
            }
            if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3) {
                level5 && setLevel5(null);
                level5Options && setLevel5Options(null);
                level6 && setLevel6(null)
                level6Options && setLevel6Options(null);
                level7 && setLevel7(null)
                level7Options && setLevel7Options(null);
                color && setColor(null)
                colorOptions && setColorOptions(null)
                style && setStyle(null)
                styleOptions && setStyleOptions(null)
            }
            if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3 || p_index == 4) {
                level6 && setLevel6(null)
                level6Options && setLevel6Options(null);
                level7 && setLevel7(null)
                level7Options && setLevel7Options(null);
                color && setColor(null)
                colorOptions && setColorOptions(null)
                productType && setProductType(null)
                productTypeOptions && setProductTypeOptions(null)
                style && setStyle(null)
                styleOptions && setStyleOptions(null)
            }
            if (p_index == 5) {
                level7 && setLevel7(null)
                level7Options && setLevel7Options(null);
                color && setColor(null)
                colorOptions && setColorOptions(null)
                style && setStyle(null)
                styleOptions && setStyleOptions(null)
                productType && setProductType(null)
                productTypeOptions && setProductTypeOptions(null)
            }
            if (p_index == 6) {
                color && setColor(null)
                colorOptions && setColorOptions(null)
                style && setStyle(null)
                styleOptions && setStyleOptions(null)
                productType && setProductType(null)
                productTypeOptions && setProductTypeOptions(null)
            }
            if(p_index == 7){
                color && setColor(null)
                colorOptions && setColorOptions(null)
            }
        };

        useEffect(() => {
            if (props.selectedFilters?.l1_name?.value) {
                setDepartment(props.selectedFilters?.l1_name)
            }
            if (props.selectedFilters?.l2_name) {
                // let l2_name = props.selectedFilters.l2_name.map(item => { return { label: item, value: item } })
                setGender(props.selectedFilters.l2_name)
                props.fetchFilterData({ l1_name: [props.selectedFilters?.l1_name], l2_name: props.selectedFilters.l2_name },
                    "sub_category"
                );
            }
            if (props.selectedFilters?.l3_name) {
                // let l3_name = props.selectedFilters.l3_name.map(item => { return { label: item, value: item } })
                setRbu(props.selectedFilters?.l3_name)
            }
            if (props.selectedFilters?.l4_name) {
                // let l4_name = props.selectedFilters.l4_name.map(item => { return { label: item, value: item } })
                setDcs(props.selectedFilters?.l4_name)
            }
            if (props.selectedFilters?.l5_name) {
                // let l5_name = props.selectedFilters.l5_name.map(item => { return { label: item, value: item } })
                setLevel5(props.selectedFilters?.l5_name)
            }
            if (props.selectedFilters?.l6_name) {
                // let l6_name = props.selectedFilters.l6_name.map(item => { return { label: item, value: item } })
                setLevel6(props.selectedFilters?.l6_name)
            }
            if (props.selectedFilters?.l7_name) {
                // let l7_name = props.selectedFilters.l7_name.map(item => { return { label: item, value: item } })
                setLevel7(props.selectedFilters?.l7_name)
            }
            if (props.selectedFilters?.style_name) {
                // let style_name = props.selectedFilters.style_name.map(item => { return { label: item, value: item } })
                setStyle(props.selectedFilters?.style_name)
            }
            if (props.selectedFilters?.color) {
                // let color = props.selectedFilters.color.map(item => { return { label: item, value: item } })
                setColor(props.selectedFilters?.color)
            }
            if (props.selectedFilters?.product_type) {
                // let product_type = props.selectedFilters.product_type.map(item => { return { label: item, value: item } })
                setProductType(props.selectedFilters?.product_type)
            }
        }, [props.selectedFilters])

        const onDepartmentChange = (val, p_userPreference) => {
            setDepartment(val);
            resetRestFilters(0);
            if (p_userPreference?.user_preference) {
                props.fetchFilterData({ l1_name: val }, 'l10');
            }
            else {
                props.fetchFilterData({ l1_name: val }, "gender");
            }
            // props.fetchFilterData({ l1_name: [val.value] }, "style_name");
            // props.fetchFilterData({ l1_name: [val.value] }, "color");
            // props.fetchFilterData({ l1_name: [val.value] }, "product_type");

        };
        const onGenderChange = (value, event, that) => {
            if (Array.isArray(value)) {
                let valuesArray = handleSelectAll(value, event, that)
                setGender(valuesArray[1])
                setRequest(old => {
                    return {
                        ...old,
                        'gender': valuesArray[0]
                    }
                })
                let req_val = intersectionWith(valuesArray[0], genderOptions, isEqual);
                // setDepartment(valuesArray[0]);
                resetRestFilters(1)
                // API call
                if (event?.user_preference) {
                    props.fetchFilterData({ l1_name: event?.l1_name, l2_name: value }, 'sub_category');
                }
                else {
                    props.fetchFilterData({ l1_name: [department], l2_name: req_val }, "sub_category")
                }
                // if (valuesArray[0].length) {
                //     props.fetchFilterData({
                //         l1_name: department,
                //         l2_name: req_val,
                //     }, "sub_category");
                // }
            }
            else {
                setGender([value])
                if (event?.user_preference) {
                    props.fetchFilterData({ l1_name: event?.l1_name, l2_name: [value] }, 'sub_category');
                }
                else {
                    props.fetchFilterData({ l1_name: [department], l2_name: [value] }, "sub_category")
                }
                resetRestFilters(1)
                setRequest(old => {
                    return {
                        ...old,
                        'gender': value,
                    }
                })
            }
        };
        const onRbuChange = (value, event, that) => {
            if (Array.isArray(value)) {
                let valuesArray = handleSelectAll(value, event, that)
                setRbu(valuesArray[1])
                setRequest(old => {
                    return {
                        ...old,
                        'rbu': valuesArray[0]
                    }
                })
                let req_val = intersectionWith(valuesArray[0], rbuOptions, isEqual);
                // setDepartment(valuesArray[0]);
                resetRestFilters(2)
                // API call
                props.fetchFilterData({
                    l1_name: [department],
                    l2_name: gender,
                    l3_name: req_val
                }, "dcs");
            }
            else {
                setRbu(value)
                props.fetchFilterData({ l1_name: [department], l2_name: gender, l3_name: [value] }, "dcs")
                resetRestFilters(2)
                setRequest(old => {
                    return {
                        ...old,
                        'rbu': value,
                    }
                })
            }
        };

        const onDCSChange = (value, event, that) => {
            if (Array.isArray(value)) {
                let valuesArray = handleSelectAll(value, event, that)
                setDcs(valuesArray[1])
                setRequest(old => {
                    return {
                        ...old,
                        'dcs': valuesArray[0]
                    }
                })
                let req_val = intersectionWith(valuesArray[0], dcsOptions, isEqual);
                // setDepartment(valuesArray[0]);
                resetRestFilters(3)
                // API call
                props.fetchFilterData({
                    l1_name: [department],
                    l2_name: gender,
                    l3_name: rbu,
                    l4_name: req_val
                }, "level5");
            }
            else {
                setDcs(value)
                props.fetchFilterData({ l1_name: [department], l2_name: gender, l3_name: rbu, l4_name: [value] }, "level5")
                resetRestFilters(3)
                setRequest(old => {
                    return {
                        ...old,
                        'dcs': value,
                    }
                })
            }
        };
        const onLevel5Change = (value, event, that) => {
            if (Array.isArray(value)) {
                let valuesArray = handleSelectAll(value, event, that)
                setLevel5(valuesArray[1])
                setRequest(old => {
                    return {
                        ...old,
                        'level5': valuesArray[0]
                    }
                })
                let req_val = intersectionWith(valuesArray[0], level5Options, isEqual);
                // setDepartment(valuesArray[0]);
                resetRestFilters(4)
                // API call
                props.fetchFilterData({
                    l1_name: [department],
                    l2_name: gender,
                    l3_name: rbu,
                    l4_name: dcs,
                    l5_name: req_val
                }, "level6");
            }
            else {
                setLevel5(value)
                props.fetchFilterData({ l1_name: [department], l2_name: gender, l3_name: rbu, l4_name: dcs, l5_name: [value] }, "level6")
                resetRestFilters(4)
                setRequest(old => {
                    return {
                        ...old,
                        'level5': value,
                    }
                })
            }
        };

        const onLevel6Change = (value, event, that) => {
            if (Array.isArray(value)) {
                let valuesArray = handleSelectAll(value, event, that)
                setLevel6(valuesArray[1])
                setRequest(old => {
                    return {
                        ...old,
                        'level6': valuesArray[0]
                    }
                })
                let req_val = intersectionWith(valuesArray[0], level6Options, isEqual);
                // setDepartment(valuesArray[0]);
                resetRestFilters(5)
                // API call
                props.fetchFilterData({
                    l1_name: [department],
                    l2_name: gender,
                    l3_name: rbu,
                    l4_name: dcs,
                    l5_name: level5,
                    l6_name: req_val
                }, "level7");
            }
            else {
                setLevel6(value)
                props.fetchFilterData({ l1_name: [department], l2_name: gender, l3_name: rbu, l4_name: dcs, l5_name: level5, l6_name: [value] }, "level6")
                resetRestFilters(5)
                setRequest(old => {
                    return {
                        ...old,
                        'level6': value,
                    }
                })
            }
        };

        const onLevel7Change = (value, event, that) => {
            if (Array.isArray(value)) {
                let valuesArray = handleSelectAll(value, event, that)
                setLevel7(valuesArray[1])
                setRequest(old => {
                    return {
                        ...old,
                        'level6': valuesArray[0]
                    }
                })
                let req_val = intersectionWith(valuesArray[0], level7Options, isEqual);
                // setDepartment(valuesArray[0]);
                resetRestFilters(6)
                // API call
                props.fetchFilterData({
                    l1_name: [department],
                    l2_name: gender,
                    l3_name: rbu,
                    l4_name: dcs,
                    l5_name: level5,
                    l6_name: level6,
                    l7_name: req_val
                }, "level7");
            }
            else {
                setLevel7(value)
                props.fetchFilterData({ l1_name: [department], l2_name: gender, l3_name: rbu, l4_name: dcs, l5_name: level5, l6_name: level6, l7_name: [value] }, "level7")
                resetRestFilters(6)
                setRequest(old => {
                    return {
                        ...old,
                        'level6': value,
                    }
                })
            }
        }

        const onStyleChange = (value, event, that) => {
            if (Array.isArray(value)) {
                let valuesArray = handleSelectAll(value, event, that)
                setStyle(valuesArray[1])
                setRequest(old => {
                    return {
                        ...old,
                        'style': valuesArray[0]
                    }
                })
                let req_val = intersectionWith(valuesArray[0], styleOptions, isEqual);
                // setDepartment(valuesArray[0]);
                resetRestFilters(7)
                // API call
                props.fetchFilterData({
                    l1_name: [department],
                    l2_name: gender,
                    l3_name: rbu,
                    l4_name: dcs,
                    l5_name: level5,
                    l6_name: level6,
                    "style_name": req_val
                }, "color");
            }
            else {
                setStyle(value)
                props.fetchFilterData({ l1_name: [department], l2_name: gender, l3_name: rbu, l4_name: dcs, l5_name: level5, l6_name: level6, style_name: value }, "color")
                resetRestFilters(4)
                setRequest(old => {
                    return {
                        ...old,
                        'style': value,
                    }
                })
            }
        };

        const onColorChange = (value, event, that) => {
            if (Array.isArray(value)) {
                let valuesArray = handleSelectAll(value, event, that)
                setColor(valuesArray[1])
                setRequest(old => {
                    return {
                        ...old,
                        'color': valuesArray[0]
                    }
                })
                let req_val = intersectionWith(valuesArray[0], colorOptions, isEqual);
                // setDepartment(valuesArray[0]);
                // resetRestFilters(7)
                // API call
                props.fetchFilterData({
                    l1_name: [department],
                    l2_name: gender,
                    l3_name: rbu,
                    l4_name: dcs,
                    l5_name: level5,
                    l6_name: level6,
                    style_name: style,
                    color: req_val
                }, "product_type");
            }
            else {
                setColor(value)
                props.fetchFilterData({ l1_name: [department], l2_name: gender, l3_name: rbu, l4_name: dcs, l5_name: level5, l6_name: [value], style_name: style, color: value }, "product_type")
                resetRestFilters(7)
                setRequest(old => {
                    return {
                        ...old,
                        'color': value,
                    }
                })
            }
        };

        const onProductTypeChange = (value, event, that) => {
            if (Array.isArray(value)) {
                let valuesArray = handleSelectAll(value, event, that)
                setProductType(valuesArray[1])

            }
            else {
                setProductType(value)
            }
        }

        const onStoreGroupChange = (value, event, that) => {
            if (Array.isArray(value)) {
                let valuesArray = handleSelectAll(value, event, that)
                setStoreGroup(valuesArray[1])

            }
            else {
                setStoreGroup(value)
            }
        }


        const applyFilters = () => {
            if (getDefaultFiltersCondition({ l1_name: department, l2_name: gender, l3_name: rbu })) {
                let req = {
                    "l1_name": department,
                    "l2_name": gender,
                    "l3_name": rbu,
                    "l4_name": dcs,
                    "l5_name": level5,
                    "l6_name": level6,
                    "l7_name": level7,
                    "style_name": style,
                    "color": color,
                    "product_type": productType,
                    "storegroup_flag": storeGroup
                }
                props.applyFilters(req);
                // setUserPreferenceFilter({ "user_id": localStorage.getItem("user_id"), "department": department })
                setUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, getLevelFiltersForUserPreference({ l1_name: department, l2_name: gender }))
            } else {
                Notify.error("Please Select All Mandatory Options!!");
            }
        };

        const resetFilters = () => {
            setRequest(null)
            setDepartment(null);
            setGender(null);
            setRbu(null);
            setDcs(null);
            setLevel5(null);
            setLevel6(null);
            setLevel7(null);
            setStyle(null);
            setColor(null);
            setSize(null);
            setProductType(null);
            setStoreGroup(null);
            props.resetFiltersOptions([]);
        };

        useEffect(() => {
            // if (props.selectedFilters?.l1_name) {
            //     setDepartment({ value: props.selectedFilters.l1_name[0], label: props.selectedFilters.l1_name[0] })
            // }
            // else {
                getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, { onl1Change: onDepartmentChange, onl2Change: onGenderChange })
                // getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onDepartmentChange)
            // }
        }, [])

        const setApply = (e) => {
            props.setAutoApply(e.target.checked)
        }

        const disableLevel = (level) => {
            return props?.isDisabled || (props?.styleMapping && ["l1_name", "l2_name"].includes(level) && props?.selectedFilters?.[level])
        }


        return (
            <>
                {/* {
                    !props.isPopupOpened &&
                   <Notification /> 
                } */}
                <section className="row section" style={{ flexDirection: "column" }}>

                    <div className="row">
                        <div className="col-md-3 required mb-3">
                            <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level1}</label>
                            <Select
                                isDisabled={disableLevel("l1_name")}
                                name="department"
                                value={department}
                                onChange={onDepartmentChange}
                                placeHolder={`Select ${props.filterLabels?.level1}`}
                                options={departmentOptions?.length ? departmentOptions : []}
                            />
                        </div>
                        <div className={`col-md-3 mb-3 ${isMandatory("l2_name")}`}>
                            <label className="fnt-md fnt-bold  fnt-bold">{props.filterLabels?.level2 || levelFiltersLabelFormatter("level2")}</label>
                            <MultiSelect
                                isDisabled={disableLevel("l2_name")}
                                name="gender"
                                dropdownValue={gender}
                                onDropDownChange={onGenderChange}
                                placeHolder={`Select ${props.filterLabels?.level2 ? props.filterLabels?.level2 : levelFiltersLabelFormatter("level2")}`}
                                optionsArray={genderOptions?.length ? genderOptions : []}
                            />
                        </div>
                        {
                            !shouldHideForCurrentClient("l3_name") &&
                            <>
                                <div className={`col-md-3 mb-3 ${isMandatory("l3_name")}`}>
                                    <label className="fnt-md fnt-bold  fnt-bold">{props.filterLabels?.level3 || levelFiltersLabelFormatter("level3")}</label>
                                    <MultiSelect
                                        isDisabled={disableLevel("l3_name")}
                                        name="rbu"
                                        dropdownValue={rbu}
                                        onDropDownChange={onRbuChange}
                                        placeHolder={`Select ${props.filterLabels?.level3 ? props.filterLabels?.level3 : levelFiltersLabelFormatter("level3")}`}
                                        optionsArray={rbuOptions?.length ? rbuOptions : []}
                                    />
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label className="fnt-md fnt-bold">{props.filterLabels?.level4 || levelFiltersLabelFormatter("level4")}</label>
                                    <MultiSelect
                                        isDisabled={disableLevel("l4_name")}
                                        name="dcs"
                                        dropdownValue={dcs}
                                        onDropDownChange={onDCSChange}
                                        placeHolder={`Select ${props.filterLabels?.level4 ? props.filterLabels?.level4 : levelFiltersLabelFormatter("level4")}`}
                                        optionsArray={dcsOptions?.length ? dcsOptions : []}
                                    />
                                </div>
                            </>
                        }
                        {
                            !shouldHideForCurrentClient("l5_name") &&
                            <div className="col-md-3 mb-3">
                                <label className="fnt-md fnt-bold">{props.filterLabels?.level5}</label>
                                <MultiSelect
                                    isDisabled={disableLevel("l5_name")}
                                    name="level5"
                                    dropdownValue={level5}
                                    onDropDownChange={onLevel5Change}
                                    placeHolder={`Select ${props.filterLabels?.level5 ? props.filterLabels?.level5 : levelFiltersLabelFormatter("level5")}`}
                                    optionsArray={level5Options?.length ? level5Options : []}
                                />
                            </div>
                        }
                        {
                            !shouldHideForCurrentClient("l6_name") &&
                            <div className="col-md-3 mb-3">
                                <label className="fnt-md fnt-bold">{props.filterLabels?.level6}</label>
                                <MultiSelect
                                    isDisabled={disableLevel("l6_name")}
                                    name="level6"
                                    dropdownValue={level6}
                                    onDropDownChange={onLevel6Change}
                                    placeHolder={`Select ${props.filterLabels?.level6 ? props.filterLabels?.level6 : levelFiltersLabelFormatter("level6")}`}
                                    optionsArray={level6Options?.length ? level6Options : []}
                                />
                            </div>
                        }
                        {
                            !shouldHideForCurrentClient("l7_name") &&
                            <div className="col-md-3 mb-3">
                                <label className="fnt-md fnt-bold">{props.filterLabels?.level7}</label>
                                <MultiSelect
                                    isDisabled={disableLevel("l7_name")}
                                    name="level7"
                                    dropdownValue={level7}
                                    onDropDownChange={onLevel7Change}
                                    placeHolder={`Select ${props.filterLabels?.level7 ? props.filterLabels?.level7 : levelFiltersLabelFormatter("level7")}`}
                                    optionsArray={level7Options?.length ? level7Options : []}
                                />
                            </div>
                        }
                        <div className="col-md-3">
                            <label className="fnt-md fnt-bold">Style Name</label>
                            <AsyncMulti
                                isDisabled={props.isDisabled}
                                name="Style Name"
                                dropdownValue={style}
                                onDropDownChange={onStyleChange}
                                placeHolder={'Select Style'}
                                optionsArray={styleOptions?.length ? styleOptions : []}
                            />
                            {/* <AsyncMulti
                                name="style"
                                dropdownValue={style}
                                onDropDownChange={onStyleChange}
                                placeHolder={'Select Style'}
                                optionsArray={styleOptions?.length ? styleOptions : []}
                            /> */}
                        </div>
                        <div className="col-md-3">
                            <label className="fnt-md fnt-bold">Color</label>
                            <MultiSelect
                                isDisabled={disableLevel("color")}
                                name="Color"
                                dropdownValue={color}
                                onDropDownChange={onColorChange}
                                placeHolder={"Select Color"}
                                optionsArray={colorOptions?.length ? colorOptions : []}
                            />
                        </div>
                        {!shouldHideForCurrentClient("product_type") &&
                        <div className="col-md-3">
                            <label className="fnt-md fnt-bold">Product Type</label>
                            <MultiSelect
                                isDisabled={disableLevel("product_type")}
                                name="Product Type"
                                dropdownValue={productType}
                                onDropDownChange={onProductTypeChange}
                                placeHolder={"Select Product Type"}
                                optionsArray={productTypeOptions?.length ? productTypeOptions : []}
                            />
                        </div>
                        }
                        {!props?.styleMapping &&
                        <div className="col-md-3">
                            <label className="fnt-md fnt-bold">Store Group Mapped</label>
                            {/* <MultiSelect
                                isDisabled={props.isDisabled}
                                name="Store Group Mapped"
                                dropdownValue={storeGroup}
                                onDropDownChange={onStoreGroupChange}
                                placeHolder={"Select Store Group Mapped"}
                                optionsArray={storeGroupOptions ? storeGroupOptions : []}
                            /> */}
                            <Select
                                isDisabled={props.isDisabled}
                                name="Store Group Mapped"
                                value={storeGroup}
                                onChange={onStoreGroupChange}
                                placeHolder={"Select Store Group Mapped"}
                                options={storeGroupOptions ? storeGroupOptions : []}
                            />
                        </div>
                        }
                        {
                            props.isCreateConfig &&
                            <div className="checkbox__group align-items-end mb-2 mr-4">
                                <div className="checkbox">
                                    <input disabled={props?.isArticlesBulkEdit} checked={props?.selectedFilters?.auto_apply} id="applyToNewProducts" onClick={setApply} type="checkbox" />
                                    <label>Apply this configuration to New Products</label>
                                </div>
                            </div>
                        }

                        <div style={{ marginTop: "0rem", width: "21%" }}>
                            <button
                                disabled={props.isDisabled}
                                onClick={applyFilters}
                                className="btn btn-primary filter-constraints-mode"
                                title="Apply filters"
                            >
                                <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
                            </button>
                            {
                                (isEmpty(props.selectedFilters) || props?.landingScreen) &&
                                <button
                                    onClick={() => resetFilters("All")}
                                    className="btn undo-constraints-mode"
                                    title="Reset filters"
                                >
                                    <i className="fa fa-undo mr-2"></i>Reset
                                </button>
                            }
                        </div>
                        <div className="required fnt-md pt-5" style={{ color: "red", marginTop: "1rem" }}>
                            <label></label>Fields are mandatory
                        </div>
                    </div>
                </section>
            </>
        );
    }
);

const mapStateToProps = ({ productStatus }) => ({
    filterLabels: productStatus.filterlabels,
    department: productStatus.departmentOptions,
    gender: productStatus.genderOptions,
    rbu: productStatus.rbuOptions,
    dcs: productStatus.dcsOptions,
    level5: productStatus.level5Options,
    level6: productStatus.level6Options,
    level7: productStatus.level7Options,
    style: productStatus.styleOptions,
    color: productStatus.colorOptions,
    produtType: productStatus.productTypeOptions
});

const mapDispatchToProps = (dispatch) => ({
    resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
    fetchFilterData: (payload, filterKey, filterType) =>
        dispatch(fetchFilterData(payload, filterKey, filterType)),
    resetTableData: () => dispatch(resetTableData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductStatusFilters);
