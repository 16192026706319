export const STORE_SEARCH_FIELD_LABEL = "Store ID";
export const SAP_ID = "SAP ID";
export const SISTER_STORE_SEARCH_FIELD_LABEL = "Enter Sister Store #";
export const STORE_NUMBER_FIELD_LABEL = "Store#";
export const STORE_NAME_FIELD_LABEL = "Store Name";
export const STORE_GRADE_AP_FIELD_LABEL = "Store Grade AP";
export const STORE_GRADE_FT_FIELD_LABEL = "Store Grade FT";
export const STORE_GRADE_ACC_FIELD_LABEL = "Store Grade ACC";
export const REGION_FIELD_LABEL = "Region";
export const CAPACITY_AP_FIELD_LABEL = "Capacity AP";
export const CAPACITY_FT_FIELD_LABEL = "Capacity FT";
export const CAPACITY_ACC_FIELD_LABEL = "Capacity ACC";
export const EXPECTED_OPENING_DATE_FIELD_LABEL = "Expected Opening Date";
export const DC_SOURCING_SECTION_LABEL = "DC Sourcing";
export const DC_SOURCING_DC_LABEL = "DC";
export const DC_SOURCING_DC_NAME_LABEL = "DC Name";
export const DC_SOURCING_DC_STATE_LABEL = "State";
export const DC_SOURCING_DC_COUNTRY_LABEL = "Country";
export const DC_SOURCING_DC_LEADTIME_LABEL = "Lead Time (days)";
export const DC_SOURCING_DC_DISTANCE_LABEL = "Distance (miles)";
export const DC_SOURCING_NETWORK_ERROR_MSG = "Something went wrong!";
export const DC_SOURCING_NO_RECORDS_MSG = "No Records Found";
export const DC_SOURCING_NO_RECORDS_HELPER_MSG = "Try Adjusting the Selected Store!!!";
export const BUTTON_IMPORT_DC_LABEL = "Import Dc Configuration";
export const STORE_DETAILS_NETWORK_ERROR_MSG = "Something went wrong!";
export const STORE_DETAILS_NO_RECORDS_MSG = "No Stores Found";
export const STORE_DETAILS_NO_RECORDS_HELPER_MSG = "Try Again";
export const CAPACITY_FIELD = "Capacity"
export const ZIP_FIELD = "Zip"
export const ADDRESS_FIELD = "Address"
export const LATTITUDE_FIELD = "Lattitude"
export const LONGITUDE_FIELD = "Longitude"
export const STORE_GRADE_FIELD = "Store Grade"
export const COUNTRY = "Country"
export const CHANNEL = "Channel"