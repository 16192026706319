import React, { useState } from "react";
import { useEffect } from "react";
import Filter from "../../containers/ProductProfile/ProductProfileTable/Filter";
import {
  getDefaultHiddenColumns,
  getFreezedColumns,
} from "../../utils/commonUtilities";
import { handleSelectAll } from "../../utils/SelectAllHandler/SelectAllHander";

const FreezeColumns = (props) => {
  const { columnOptions, columnConfig, freezeColumns, tableId, groupColumns } = props;

  const [selectedColumns, setSelectedColumns] = useState([]);

  useEffect(() => {
    if (columnOptions?.length) {
      const defaultHideColumns = getDefaultHiddenColumns();
      const columns = getFreezedColumns(tableId);
      const freezedColumns = columnOptions
        .filter(
          (col) =>
            (columns?.length
              ? columns.includes(col.value)
              : col?.sticky ||
                columns.includes(col.value)) &&
            !defaultHideColumns.includes(col.value)
        )
        // .map((item) => {
        //   return { label: item.Header, value: item.id };
        // });

      freezedColumns?.length && setSelectedColumns(freezedColumns);
    }
  }, [columnOptions]);

  const onChangeHandler = (val, event, that) => {
    let valuesArray = handleSelectAll(val, event, that);
    setSelectedColumns(valuesArray[1]);
  };

  const saveFreezeColumns = () => {
    let columns = [];
    selectedColumns.forEach(col => { 
      if(col.isGroupColumn) {
        columns = [...columns, ...col.childColumns]
      }
      columns.push(col.value)
    })
    freezeColumns(columns);
  };

  const filterObj = [
    {
      id: 1,
      isRequired: false,
      label: "Freeze Columns",
      name: "Columns",
      onDropDownChange: onChangeHandler,
      placeHolder: `Select columns`,
      dropdownValue: selectedColumns,
      optionsArray: columnOptions,
      isMultiSelect: true,
      className: "fill p-0",
      // className: "setAll_filter",
      labelClass: "show_columns__label",
    },
  ];

  return (
    <div className="col-6">
      <Filter data={filterObj} />
      <button className="btn btn-primary px-2 mt-4 float-right" onClick={saveFreezeColumns}>
        Save
      </button>
    </div>
  );
};

export default React.memo(FreezeColumns);
