import { isEmpty } from "lodash";
import { currentClientName } from "../../utils/commonUtilities";
import { DC_NAMES, STORE_STOCK_TABLE_HEADERS } from "./ReportingDCConstants";

export let EXCEL_HEADERS = [];
export const formatReportingExcelData = (data) => {
  let dcNames = [];
  let dcColumnNames = {};
  let excelData = []
  if (!isEmpty(data)) {
    DC_NAMES[currentClientName].forEach((dcName) => {
      dcColumnNames[dcName?.acc] = dcName?.acc;
      dcNames.push(dcName?.name);
    });
  }
  data.forEach((item) => {
    let l_dcColumnNames = {};

    for (let i in dcColumnNames) {
      l_dcColumnNames[i] = item[dcColumnNames[i]];
    }

    excelData.push({
      store_code: item.store_code,
      sap_id: item.sap_id,
      store_name: item.store_name,
      l1_name: item.l1_name,
      l2_name: item.l2_name,
      l3_name: item.l3_name,
      l4_name: item.l4_name,
      l5_name: item.l5_name,
      l6_name: item.l6_name,
      l7_name: item.l7_name,
      store_grade: item.store_grade,
      ...l_dcColumnNames,
      // [STORE_STOCK_TABLE_HEADERS.REGION]: item.region,
      climate: item.climate,
      article: item.article,
      style_name: item.style_name,
      color_desc: item.color_desc,
      assortment_indicator: item.assortment_indicator,
      dpi_description: item.dpi_description,
      oh: item.oh,
      it: item.it,
      oo: item.oo,
      total_inventory: item.total_inventory,
      lw_qty: item.lw_qty,
      wos: item.wos,
      si_oh: item.si_oh,
      si_it: item.si_it,
      si_dc: item.si_dc,
      si_all: item.si_all,
      bulk_remaining: item.bulk_remaining,
      article_status: item.article_status,
      store_status: item.store_status,
      clearance: item.clearance,
    });
  });

  EXCEL_HEADERS = [...Object.values(STORE_STOCK_TABLE_HEADERS).slice(0, 11), ...dcNames, ...Object.values(STORE_STOCK_TABLE_HEADERS).slice(11)]

  return excelData
};
