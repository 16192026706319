import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import {
  fetchDailySummaryDataError,
  fetchDailySummaryDataSuccess,
  fetchExcelDataError,
  fetchExcelDataSuccesss,
  fetchFilterDataError,
  fetchFilterDataSuccesss,
  fetchSKUDataError,
  fetchSKUDataSuccess,
  FETCH_FILTER_DATA,
  FETCH_SKU_TABLE_DATA,
  FETCH_TABLE_DATA,
  GENERATE_EXCEL,
} from "./DailySummaryAction";


import { isEmpty } from "lodash";
import { getDailySummaryData, getDailySummaryFilterData, getDailySummarySKUData, getDailySummaryStoreArticleDetails } from "../../../routes/api";
import { CARDS, DAILY_SUMMARY_TABLE_HEADERS } from "./DailySummaryConstants";
import { appendPerc, isAshley } from "../../../utils/commonUtilities";
import { levelFiltersLabelFormatter } from "../../../utils/filterLevelMapping";
import { numberUSFormatting } from "../../../utils/formatters/valueFormatters";
import { ERROR_MSG } from "../../../constants/globalConstant";

const level_ln_map = {
  "level1": "l1_name",
  "level2": "l2_name",
  "level3": "l3_name",
  "level4": "l4_name",
  "level5": "l5_name",
  "level6":"l6_name",
  "level7":"l7_name",
  "article":"article",
  "color":"color",
  "store":"store",
}

function* fetchStoreDataWorker(action) {
  try {
    const {payload} = action;
    const {request} = payload;
    const req = {}
    for(let i in request){
      if(!isEmpty(request[i])){
         if(Array.isArray(request[i])){
            req[i] = request[i].map(val => val.value)
         }
         else{
              req[i] = request[i]
         }
      }
    }

    const res = yield call(getDailySummaryData,req);

    if (res.data.status) {
      const {aggregated_data,table_data, dc_map} = res?.data

      const l_aggregated_data = aggregated_data[0]

      const cardData = [
      {
        'title': CARDS.CARD1,
        value: l_aggregated_data?.allocation_count || 0
      },
      {
        'title': CARDS.CARD2,
        value:  l_aggregated_data?.style_color_count || 0
      },
      {
        'title': CARDS.CARD3,
        value: l_aggregated_data?.units_allocated || 0
      },
      {
        'title': CARDS.CARD4,
        value: l_aggregated_data?.dc_available || 0
      },
      // {
      //   'title': CARDS.CARD5,
      //   value: l_aggregated_data?.reserved_quantity || 0
      // },
    ]
    let excelData = [];
    let dcList = [];
    let dcMap = {
      "Outlet-Store": {},
      "Retail-Store": {}
    };
    for(let key in dc_map) {
      if(key.includes("Outlet")) {
        dcMap["Outlet-Store"][key] = dc_map[key];
      }
      else {
        dcMap["Retail-Store"][key] = dc_map[key];
      }
    }
    res.data.table_data.forEach(detail => {
      let dcObj = {};
      let dcAvailObj = {};
      for(let dcKey in dcMap[detail.l1_name]) {
        dcObj[`Units Allocated (${dcMap[detail.l1_name][dcKey]})`] = detail.units_allocated_dc?.[dcKey] >= 0 ? detail.units_allocated_dc?.[dcKey] : "";
        dcAvailObj[`DC Available (${dcMap[detail.l1_name][dcKey]})`] = detail.dc_available_split?.[dcKey] >=0 ? detail.dc_available_split?.[dcKey] : "" ;
        if(!dcList.includes(dcKey)) {
          dcList.push(dcKey);
        }
      }
      excelData.push({
        [DAILY_SUMMARY_TABLE_HEADERS.ARTICLE_ID]: detail.article,
        [DAILY_SUMMARY_TABLE_HEADERS.STYLE_DESCRIPTION]: detail.style_name,
        [DAILY_SUMMARY_TABLE_HEADERS.STYLE_COLOR]: detail.color_desc,
        [DAILY_SUMMARY_TABLE_HEADERS.COLOR]: detail.color,
        [levelFiltersLabelFormatter("level1")]: detail.l1_name,
        [levelFiltersLabelFormatter("level2")]: detail.l2_name,
        [levelFiltersLabelFormatter("level3")]: detail.l3_name,
        [levelFiltersLabelFormatter("level4")]: detail.l4_name,
        [levelFiltersLabelFormatter("level5")]: detail.l5_name,
        [DAILY_SUMMARY_TABLE_HEADERS.ALLOCATION_ID]: detail.allocation_code,
        ...dcObj,
        "Total Allocated": detail.units_allocated,
        ...dcAvailObj,
        [DAILY_SUMMARY_TABLE_HEADERS.DC_UNITS_OH]: detail.dc_available,
        // [DAILY_SUMMARY_TABLE_HEADERS.RESERVE_QTY]: detail.reserve_quantity,
      })
    })

      yield put(fetchDailySummaryDataSuccess({ data: res.data.table_data, dc: {dcList, dc_map}, cardData:cardData, excelData:excelData}));
    } 
    else {
      yield put(fetchDailySummaryDataError({ error: ERROR_MSG }));
    }
  }
  catch (error) {
    yield put(fetchDailySummaryDataError({ error: ERROR_MSG }));
  }
 
}

function getSizes (l_data, l_sizes, dc) {
  const { dcList, dc_map } = dc;
  let l_sizesObject = {}
  l_sizes?.forEach((val,index) => {
    dcList.forEach(dcKey => {
      l_sizesObject[`Units Allocated - ${val} (${dc_map[dcKey]})`] = (l_data?.units_allocated_dc?.[val]?.[dcKey] || 0)
      l_sizesObject[`DC Units OH - ${val} (${dc_map[dcKey]})`] = (l_data?.dc_available_split?.[val]?.[dcKey] || 0)
    })
  })
  return l_sizesObject
}

function* fetchSKUDataWorker(action) {
  try {
    const {payload} = action;
    const {request, dc} = payload;
    const req = {}
    for(let i in request){
      if(!isEmpty(request[i])){
         if(Array.isArray(request[i])){
            req[i] = request[i].map(val => val.value)
         }
         else{
              req[i] = request[i]
         }
      }
    }

    if(request?.is_store_view) {
      delete req?.is_store_view
    }

    const res = yield call( request?.is_store_view ? getDailySummaryStoreArticleDetails : getDailySummarySKUData,req);

    const excelData =  res.data.data.map(detail => ({
      [DAILY_SUMMARY_TABLE_HEADERS.ALLOCATION_ID]: detail.allocation_code,
      [DAILY_SUMMARY_TABLE_HEADERS.STORE_ID]: detail.store,
      [DAILY_SUMMARY_TABLE_HEADERS.SAP_ID]: detail.sap_id,
      ...getSizes(detail, res.data.sizes, dc)
    }));

    if (res.data.status) {
      yield put(fetchSKUDataSuccess({ data: res?.data?.data, excelData:excelData, sizeData:res.data.sizes}));
    } 
    else {
      yield put(fetchSKUDataError({ error: ERROR_MSG }));
    }
  }
  catch (error) {
    yield put(fetchSKUDataError({ error: ERROR_MSG }));
  }
}

function* fetchFilterDataWorker(action) {
  try {
    const { payload, filterType } = action;
    let req = {};
    if(payload?.start_date) {
      req["start_date"] = payload?.start_date
    }
    if(payload?.end_date) {
      req["end_date"] = payload?.end_date
    }
    delete payload?.start_date
    delete payload?.end_date
    if (payload) {
      for (const key in payload) {
          if(!isEmpty(payload[key])) { 
            if(Array.isArray(payload[key][0])) {
              req[level_ln_map[key]] = payload[key]?.[0].map((ele) => ele.value)
            }
            else{
              req[level_ln_map[key]] = payload[key].map((ele) => ele.value)
            }
          }
          else {
          }
      }
    }
    if(filterType){
      req["filter_type"] = filterType;
    }
    const res = yield call(getDailySummaryFilterData, req);
    if (res.data.status) {
      const data = {};
      data["filterMapping"] = res.data?.filterMapping
      for (const key in res.data.data[0]) {
      let k = "";
        if (key === "l1_name") {
          k = "departmentOptions";
        } else if (key === "l2_name") {
          k = "genderOptions";
        } else if (key === "l3_name") {
          k = "subCatOptions";
        } else if (key === "l4_name") {
          k = "dcsOptions";
        } else if (key === "l5_name") {
          k = "level5Options";
        } else if (key === "l6_name") {
          k = "level6Options";
        } else if (key === "l7_name") {
          k = "level7Options";
        } else if(key === "color") {
            k = "colorOptions";
        } else if(key === "article") {
          k = "articleIdOptions";
        } 
        else if(key === "style") {
            k = "styleOptions";
        } else if(key === "store") {
          k = "storeIdOptions";
        } else if(key === "store_name") {
          k = "storeNameOptions";
        } else if(key === "season_code_active") {
          k = "seasonCodeOptions";
        } else if(key === "season_code_opened") {
          k = "seasonCodeOpenOptions";
        } else if (key === "dc") {
          k = "dcOptions";
        }

        if (key == "dc") {
            data["dcOptions"] = Object.entries(res.data.data[0][key]).map((element) => ({
              value: element[0],
              label: element[1]
            }))
          }
        else if(Array.isArray(res?.data?.data?.[0]?.[key])) {
          data[k] = res.data.data[0][key]?.filter(val =>val).map((element) => ({
              value: element,
              label: element,
            }));
        }
        else{
          data[k] = []
          if(res?.data?.data?.[0]?.[key]) {
            for (const [label, value] of Object.entries(res?.data?.data?.[0]?.[key])) {
              data[k].push({
                "label": label,
                "value": value
              })
            }
          }        
        }

      }
        yield put(fetchFilterDataSuccesss({ data: data, key: action?.filterKey?.key }));
      } 
  }
  catch (error) {
    yield put(fetchFilterDataError({ error: ERROR_MSG }));
  }
}

function* generateExcelWorker(action) {
  try {
      const {payload} = action;
      const {request} = payload;

      const req = {}
      for(let i in request){
        if(request[i]){
          if(Array.isArray(request[i])){
              req[i] = request[i].map(val => val.value)
          }
          else{
                req[i] = request[i]
          }
        }
      }
      if(!isEmpty(payload?.searchTermReq)) {
        req["searchColumns"] = {...payload?.searchTermReq}
      }
      const res = yield call(getDailySummaryData, req);
      if (res.data.status) {
          let excelData = []
          res.data.deepdive_data.forEach(item => {
              excelData.push({
                [DAILY_SUMMARY_TABLE_HEADERS.ARTICLE_ID]: item.article,
                [DAILY_SUMMARY_TABLE_HEADERS.STYLE_DESCRIPTION]: item.style_name,
                [DAILY_SUMMARY_TABLE_HEADERS.STYLE_COLOR]: item.color_desc,
                [DAILY_SUMMARY_TABLE_HEADERS.COLOR]: item.color,
                [DAILY_SUMMARY_TABLE_HEADERS.DEPARTMENT]: item.l1_name,
                [DAILY_SUMMARY_TABLE_HEADERS.GENDER]: item.l2_name,
                [DAILY_SUMMARY_TABLE_HEADERS.SUBCAT]: item.l3_name,
                [DAILY_SUMMARY_TABLE_HEADERS.DCS]: item.l4_name,
                [DAILY_SUMMARY_TABLE_HEADERS.STORE_ID]: item.store_code,
                [DAILY_SUMMARY_TABLE_HEADERS.SAP_ID]: item.sap_id,
                [DAILY_SUMMARY_TABLE_HEADERS.STORE_NAME]: item.store_name,
                ... isAshley() && {[DAILY_SUMMARY_TABLE_HEADERS.SEASON_CODE_ACTIVE]: item.season_code_active},
                [DAILY_SUMMARY_TABLE_HEADERS.SEASON_CODE_OPEN]: item.season_code_opened,
                [DAILY_SUMMARY_TABLE_HEADERS.STORE_GRADE]: item.store_grade,
                [DAILY_SUMMARY_TABLE_HEADERS.PLANNED_ALLOCATION]: item.planned_units,
                [DAILY_SUMMARY_TABLE_HEADERS.ACTUAL_ALLOCATION]: item.allocated_units,
                [DAILY_SUMMARY_TABLE_HEADERS.UNIT_SALES]: item.units,
                [DAILY_SUMMARY_TABLE_HEADERS.QTY_MATCH_PER_EXCEL]: item.planned_perc,
                [DAILY_SUMMARY_TABLE_HEADERS.SIZE_MATCH_PER_EXCEL]: item.size_perc_planned,
                [DAILY_SUMMARY_TABLE_HEADERS.ALLOCATION_MATCH_EXCEL]: item.match_perc,
                [DAILY_SUMMARY_TABLE_HEADERS.SIZE_MATCH_PER_SALES_EXCEL]: item.match_perc_size,
                [DAILY_SUMMARY_TABLE_HEADERS.SKU_DEPTH_EXCEL]: item.sku_depth_store,
                [DAILY_SUMMARY_TABLE_HEADERS.AVG_GRADE_REVENUE_EXCEL]: item.grade_revenue,
                [DAILY_SUMMARY_TABLE_HEADERS.STORE_REVENUE_EXCEL]: item.revenue,
                [DAILY_SUMMARY_TABLE_HEADERS.AVG_GRADE_MARGIN_EXCEL]: item.grade_margin,
                [DAILY_SUMMARY_TABLE_HEADERS.STORE_MARGIN_EXCEL] : item.margin,
              })
          })
          yield put(fetchExcelDataSuccesss({data:excelData }));

      }
      else {
          yield put(fetchExcelDataError({error: ERROR_MSG }));
      }
  }
  catch (error) {
      yield put(fetchExcelDataError({error: ERROR_MSG }));
  }
}

function* fetchTableData() {
  yield takeLatest(FETCH_TABLE_DATA, fetchStoreDataWorker);
}

function* fetchSKUTableData() {
  yield takeLatest(FETCH_SKU_TABLE_DATA, fetchSKUDataWorker);
}

function* fetchFilterDataWatcher() {
  yield takeEvery(FETCH_FILTER_DATA, fetchFilterDataWorker);
}

function* generateExcelWatcher() {
  yield takeLatest(GENERATE_EXCEL, generateExcelWorker)
}

export function* dailySummarySaga() {
  yield all([,
    fetchTableData(),
    fetchFilterDataWatcher(),
    generateExcelWatcher(),
    fetchSKUTableData(),
  ]);
}
