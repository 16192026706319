import React, { useState, useEffect } from "react";
import Modal from "react-awesome-modal";
import { connect } from "react-redux";
import { getExcelData, resetDownloadExcel } from "./Actions/ExcelAction";
import ExportFile from "../../components/ExportFile/ExportFile";
import DownloadProgressBar from "./DownloadProgressBar";
import * as Notify from "../../components/Notification/Notifications";
import { cloneDeep, isEmpty } from "lodash";
import "./ParallelExcelDownload.css"

const ROW_COUNT = 500;

const ParallelExcelDownload = (props) => {

  const {
    existingData,
    nextIndex,
    totalCount,
    apiURL,
    excelData,
    totalCountExcel,
    nextIndexExcel,
    downloadNextIndex,
    formatExcelData,
    getData,
    downloadExcelError,
    excelColumnsToBeFormatted,
    filters,
    searchTermReq,
    sortReq,
    reset,
    fileName,
    excelFormatColumnIndex,
    headers,
    columnOrder,
    tableId
  } = props;

  const [overAllExcelData, setExcelData] = useState([]);
  const [downloadExcel, setDownloadExcel] = useState(false);
  const [downloadExcelLoading, setDownloadLoading] = useState(false);
  const [columnFormatting, setColumnFormatting] = useState({});


  useEffect(() => {
    if (excelData?.length > 0) {
      if (!isEmpty(overAllExcelData)) {
          setExcelData([...overAllExcelData, ...excelData]);
      }
      else {
          setExcelData([...excelData]);
      }
    }
    // else {
    //   setExcelData([])
    // }
    // setTotalCountExcel(totalCountExcel)
    // setNextIndexExcel(nextIndexExcel)
  }, [excelData]);


  useEffect(() => {
    if (downloadExcelLoading && downloadNextIndex < totalCountExcel) {
      getNextExcelData(downloadNextIndex);
    } else if (downloadExcelLoading && downloadNextIndex > totalCountExcel) {
        let allData = [...overAllExcelData, ...excelData]
        formatData(allData);
    }
  }, [downloadNextIndex]);

  const closeModal = () => {
    setExcelData([]);
    setDownloadLoading(false);
    reset();
  };

  useEffect(() => {
    if (downloadExcelError) {
      setDownloadLoading(false)
      Notify.error(downloadExcelError)
      reset();
    }
  }, [downloadExcelError]);

  const formatData = (data) => {
    setExcelData(formatExcelData ? formatExcelData(data) : data);
    excelFormatColumnIndex?.diff !== 0 && excelFormatColumnIndex?.hideColumnIndex > 0 && formatExcelColumns()
    setTimeout(() => {
      setDownloadLoading(false);
      setDownloadExcel(true);
    }, 1000);

    setTimeout(() => {
      setExcelData([]);
      reset();
    }, 2000);
  };

  const generateExcel = () => {
    setColumnFormatting(excelColumnsToBeFormatted)
    setExcelData(existingData || [])
    setDownloadLoading(true);
    setDownloadExcel(false);
    if(totalCount > existingData?.length || totalCount === undefined) {
      getNextExcelData(nextIndex, totalCount === undefined ? 1 : 0)
    }
    else {
      formatData(existingData)
    }
  };

  const getNextExcelData = (nextIndex, count) => {
    let filterData = {};
    const notArrayKeys = ["start_date", "end_date", "storegroup_flag", "screen_type", "page"]
    for(let key in filters) {
      if(notArrayKeys.includes(key)) {
        filterData[key] = filters[key]?.hasOwnProperty("value") ? filters[key]?.value : filters[key]
      }
      else {
        if(Array.isArray(filters[key]) && filters[key]?.length) {
          filterData[key] = filters[key].map(item => item?.value || item);
        }
        else if(!isEmpty(filters[key])) {
          filterData[key] = [filters[key]?.hasOwnProperty("value") ? filters[key]?.value : filters[key]];
        }
      }
    }
    getData({
      ...filterData,
      row_index: nextIndex,
      row_count: ROW_COUNT,
      searchColumns: searchTermReq,
      sortColumn: Array.isArray(sortReq) ? sortReq?.[0] : sortReq,
      total: totalCount === undefined ? (totalCountExcel || 10000) : totalCount,
      apiCount: count
    }, apiURL);
  }

  useEffect(() => {
    setExcelData(existingData)
  }, [existingData])

  // useEffect(() => {
  //   setColumnFormatting(excelColumnsToBeFormatted)
  // }, [excelColumnsToBeFormatted])

  const formatExcelColumns = () => {
    let columnsFormattingCopy = cloneDeep(columnFormatting)
    for(const key in columnsFormattingCopy) {
      if(columnsFormattingCopy[key].hasOwnProperty("value")) {
        columnsFormattingCopy[key].value += excelFormatColumnIndex.diff
      }
      else {
        columnsFormattingCopy[key] += excelFormatColumnIndex.diff
      }
    }
    setColumnFormatting(columnsFormattingCopy)
  }

  return (
    <div className="download_btn">
      <DownloadProgressBar
        showModal={downloadExcelLoading}
        closeModal={closeModal}
        nextIndex={overAllExcelData?.length}
        totalCount={totalCountExcel || totalCount}
      />
      <ExportFile
        downloadExcelData={downloadExcel}
        callFunc={generateExcel}
        text={"Excel"}
        data={overAllExcelData}
        fileName={fileName}
        type={1}
        formateXLSX={true}
        excelColumnsToBeFormatted={columnFormatting}
        headers={headers}
        columnOrder={columnOrder}
        tableId={tableId}
      />
    </div>
  );
};

const mapStateToProps = ({ excelDownload }) => {
  return {
    excelData: excelDownload.excelData,
    totalCountExcel: excelDownload.downloadTotalCount,
    nextIndexExcel: excelDownload.downloadNextIndex,
    downloadNextIndex: excelDownload.downloadNextIndex,
    downloadExcelError: excelDownload.downloadExcelError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getData: (payload, api) => dispatch(getExcelData(payload, api)),
  reset: () => dispatch(resetDownloadExcel()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParallelExcelDownload);
