export const STORE_VIEW_TABLE_HEADERS = {
    STORE_NUMBER: "Store Number",
    SAP_ID: "SAP ID",
    STORE_NAME: "Store Name",
    UNITS_ALLOCATED: "Units Allocated",
    DC_AVAIL: "Net DC Available",
    RESERVED_QTY: "Reserved Qty",
    OH: "OH",
    OO: "OO",
    IT: "IT"
}