import React, { useState, useEffect } from "react";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ReactTable from "../../../components/Table/ReactTable"
import "../Finalize.css"
import { connect } from "react-redux";
import { getProductData, getProductDetails, getPackDetails, getStoreData } from '../FinalizeAction'
import PageLoader from '../../../components/Loader/PageLoader';
import ProductListTable from './ProductListTable'
import ProductDetailsTable from './ProductDetailsTable'
import { numberUSFormatting } from "../../../utils/formatters/valueFormatters";
import ArticleListTable from "../../NewStoreApproveRelease/views/ArticleList/ArticleListTable"
import { getNewStoreArticleDetails } from "../../NewStoreApproveRelease/actions/NewStoreApprove/NewApproveAction"
import { findSum } from "../../../utils/commonUtilities";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ProductView = (props) => {
    const [selectedArticle, setSelectedArticle] = useState(null)
    const [RTInstance, setRTinstance] = useState({})
    const [productDetailsData, setProductDetailsData] = useState([]);
    const [sizeLevelAllocated, setSizeLevelAllocated] = useState({});

    const getProductDetails = (instance) => {
        if (instance) {
            setSizeLevelAllocatedQty(instance);
            setProductDetailsData([]);
            setSelectedArticle(instance.article)
            setRTinstance({
                allocationcode: props.allocationCode,
                article: instance.article,
                color: instance.color
            })
        }
        let req = { allocationcode: props.allocationCode, article: instance ? instance.article : selectedArticle }
        if (props.viewAllocationCall) {
            req["page"] = "viewPastAllocation"
        }
        if (props.releasedDate) {
            req["released_date"] = props.releasedDate
        }
        props.isPOASNFlow && (req["partial_articles"] = props.articles)
        props.getProductDetails(req)

    }

    useEffect(() => {
        if (props.activeTab == "fpv" && props.isPOASNFlow) {
            let req = {
                allocationcode: props.allocationCode
            }
            if (props.isPOASNFlow) {
                req["article"] = props.articles
            }
            if (!props?.is_new_store) {
                props.getProductData(req)
                props.getStoreData(req)
            }
        }
        if (props.activeTab == "fpv" && !(props.viewAllocationCall && props.productData?.data?.length) && !props.isPOASNFlow) {
            let req = {
                allocationcode: props.allocationCode
            }
            if (props.viewAllocationCall) {
                req["page"] = "viewPastAllocation"
            }
            if (props.releasedDate) {
                req["released_date"] = props.releasedDate
            }
            if (!props?.is_new_store) {
                props.getProductData(req)
                props.getStoreData(req)
            }
        }
    }, [props.activeTab, props.isPOASNFlow])

    // useEffect(()=>{
    //     if(!props.tableInstance?.data){
    //         let req = {
    //             allocationcode: props.allocationCode
    //         }
    //         if (props.isPOASNFlow) {
    //             req["article"] = props.articles
    //         }
    //         if (props.viewAllocationCall) {
    //             req["page"] = "viewPastAllocation"
    //             if (props.releasedDate) {
    //                 req["released_date"] = props.releasedDate
    //             }
    //         }
    //         req["rerelease_flag"] = props.retransmit ? props.retransmit : false
    //         props.getStoreData(req)
    //     }
    // },[props.isPOASNFlow,props.viewAllocationCall,props.releasedDate])

    useEffect(() => {
        props.setTableInstance(props.storeData);
    }, [props?.storeData])

    const callAllProductApis = () => {
        let req = {
            allocationcode: props.allocationCode
        }
        if (props.isPOASNFlow) {
            req["article"] = props.articles
        }
        props.getProductData(req)
        getProductDetails(false)
    }

    const allocationDataColumns = React.useMemo(() => [
        {
            Header: '# Style Color',
            accessor: "style_color",
            id: "style_color_cnt",
        },
        {
            Header: '# Allocated Stores',
            accessor: "store_cnt"
        },
        {
            Header: 'Allocated Stores per StyleColor',
            accessor: "stores_per_style_color",
            width: 250
        },
        {
            Header: 'Allocated Quantity',
            columns: [],
        },
        {
            Header: 'Net DC Available',
            columns: [],
        },
        {
            Header: 'Allocation Quantity (by Size)',
            columns: []
        },
        {
            Header: 'Allocation % (by Size)',
            columns: []
        }
    ])

    const prepareStoreGradeColumns = () => {
        let data = props.productAllocationData[0]
        data?.sizes?.map((s, index) => {
            allocationDataColumns[5].columns.push({
                Header: s + " ",
                accessor: row => row.sizes_allocated_qty[index],
                width: data?.sizes?.length == 1 ? 200 : 100

            })
            allocationDataColumns[6].columns.push({
                Header: s,
                accessor: row => row.sizes_allocated_perc[index],
                width: data?.sizes?.length == 1 ? 200 : 100

            })
        })
        data?.dc && Object.keys(data?.dc)?.map((val) =>{
            if(val){
              allocationDataColumns[3].columns.push({
                  Header: val + " ",
                  accessor: row => row.dc?.[val]?.["allocated_qty"],
                  Cell: (inst) => numberUSFormatting(inst?.value)
              })
              allocationDataColumns[4].columns.push({
                  Header: val,
                  accessor: row => row.dc?.[val]?.["non_allocated_qty"],
                  Cell: (inst) => numberUSFormatting(inst?.value)
              })
            }  
        })
        allocationDataColumns.splice(3, 0, {
            Header: 'Total Allocated Units',
            accessor: row => findSum(row.sizes_allocated_qty),
        })
    }

    useEffect(() => {
        if (props?.is_new_store) {
            props.getNewStoreArticleDetails({ isRelease: true, plan_id: props?.is_new_store })
        }
    }, [props?.is_new_store])

    useEffect(() => {
        setProductDetailsData(props?.productDetails?.data);
    }, [props.productDetails])

    useEffect(() => {
        if(props?.productData?.data?.length && selectedArticle) {
            const { data } = props?.productData;
            setSizeLevelAllocatedQty(data.filter(item => item.article === selectedArticle)?.[0]);
        }
    }, [props.productData])

    const setSizeLevelAllocatedQty = (row) => {
        const { size, size_value } = row;
        const sizeValues = {};
        size.forEach((s, index) => {
            sizeValues[s] = size_value[index]
        })
        setSizeLevelAllocated(sizeValues);
    }

    return (
        <div className="product__view__tables">
            {!props.is_new_store ?
                <>
                    <div className="f__table mb-4" >
                        <PageLoader loader={props.productDataLoading} gridLoader={true}>
                            <div className="storesStore__heading">
                                <h1 className="fnt-md fnt-bold">Allocation Summary</h1>
                            </div>
                            {prepareStoreGradeColumns()}
                            {(props.productDataError) ? (<div className="error">{props.productDataError}</div>) :
                                (props.productAllocationData ? (
                                    <ReactTableWithPlugins
                                        data={(props.productAllocationData ? props.productAllocationData : [])}
                                        columns={allocationDataColumns}
                                        renderMarkup='TableMarkup'
                                        tableId="finalize_product_view"
                                    />
                                ) : null)}
                        </PageLoader>
                    </div>
                    <div className="f__table mt-4">

                        {/* <PageLoader loader={props.productDataLoading} gridLoader={true}> */}
                        {(props.productDataError) ? (<div className="error">{props.productDataError}</div>) :
                            (props.productData.data.length ? (
                                <>
                                    <div className="storesStore__heading">
                                        <h1 className="fnt-md fnt-bold">Products Details</h1>
                                    </div>
                                    <ProductListTable
                                        data={props.productData.data} sizes={props.productData.sizes} dc_list={props.productData.dc_list}
                                        getProductDetails={getProductDetails} />
                                </>
                            ) : null)}
                        {/* </PageLoader> */}
                    </div>
                </>
                :
                <PageLoader loader={props?.articleDataLoading} gridLoader={true}>
                    <ArticleListTable data={props?.articleData} type={"new_store_release"} page={"view_allocation"}
                        getPackDetails={props?.getPackDetails} />
                </PageLoader>
            }
            <hr />
            <div className="f__table">
                <PageLoader loader={props.productDetailsDataLoading && !props.productDataLoading} gridLoader={true}>
                    {(props.productDetailsDataError) ? (<div className="error">{props.productDetailsDataError}</div>) :
                        (productDetailsData.length ? (
                            <ProductDetailsTable
                                isFinalized={props.isFinalized}
                                viewAllocationCall={props.viewAllocationCall}
                                data={productDetailsData}
                                callAllApis={callAllProductApis}
                                selectedRow={RTInstance}
                                sizes={props.productDetails.sizes}
                                productDetails={props.productDetails}
                                originalAllocationCode={props.originalAllocationCode}  
                                allocationcode={props.allocationCode}
                                articles={props?.articles}
                                sizeValue={sizeLevelAllocated}
                            />
                        ) : null)}
                </PageLoader>
            </div>

        </div>
    )
}

const mapStateToProps = ({ finalize, nsApproveReducer }) => {
    return {
        productDataLoading: finalize.productDataLoading,
        productData: finalize.productData,
        storeData: finalize.storeData,
        productDataError: finalize.productDataError,
        productDetailsDataLoading: finalize.productDetailsDataLoading,
        productDetails: finalize.productDetailsData,
        productDetailsDataError: finalize.productDetailsDataError,
        productAllocationData: finalize.productAllocationData,
        articleData: nsApproveReducer.articleData,
        articleDataLoading: nsApproveReducer.articleDataLoading,
        articleDataError: nsApproveReducer.articleDataError,
    }
}

const mapDispatchToProps = (dispatch) => ({
    getStoreData: (payload) => dispatch(getStoreData(payload)),
    getProductData: (payload) => dispatch(getProductData(payload)),
    getProductDetails: (payload) => dispatch(getProductDetails(payload)),
    getNewStoreArticleDetails: (payload) => dispatch(getNewStoreArticleDetails(payload)),
    getPackDetails: (payload) => dispatch(getPackDetails(payload))
})


export default connect(mapStateToProps, mapDispatchToProps)(ProductView)