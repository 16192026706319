import { getLabel } from "../../../utils/commonUtilities";
import { updateArticleName } from "../../../utils/filterLevelMapping";

export const LOST_SALES_FILTER_DEPARTMENT = "Department";
export const LOST_SALES_FILTER_GENDER = "Gender";
export const LOST_SALES_FILTER_SUBCATEGORY = "Sub-Category";
export const LOST_SALES_FILTER_DCS = "DCS";
export const LOST_SALES_FILTER_STYLE = "Style";
export const LOST_SALES_FILTER_COLOR = "Color";
export const LOST_SALES_FILTER_ARTICLE_ID = `${updateArticleName(false)} ID`;
export const LOST_SALES_FILTER_STORE_ID = "Store Number";
export const LOST_SALES_FILTER_STORE_NAME = "Store Name";
export const LOST_SALES_FILTER_WIDTH = "Width";
export const LOST_SALES_FILTER_SEASON_CODE = "Season Code Active"
export const LOST_SALES_FILTER_SEASON_CODE_OPEN = getLabel("season_code_open")
export const GRAPH_LOST_SALES_LABEL = "Lost Sales";
export const LOST_SALES_OPP_LABEL = "Lost Sales Opportunity";
export const LOST_SALES_UNITS_LABEL = "Lost Sales Unit";
export const LOST_SALES_REVENUE_LABEL = "Lost Sales Revenue";
export const LOST_SALES_DETAILS_TABLE_HEADER = "Lost Sales - Detailed view";
export const LOST_SALES_NETWORK_ERROR_MSG = "Something went wrong!";
export const LOST_SALES_WEEK_FILTER_LABEL = "Week";
export const LOST_SALES_QUARTER_FILTER_LABEL = "Quarter";
export const LOST_SALES_NO_RECORDS_MSG = "No Records Found";
export const LOST_SALES_NO_RECORDS_HELPER_MSG = "Try Adjusting the Filters!!!";
export const LOST_SALES_DETAILS_TABLE_HEADERS = {
    STORE_ID: "Store",
    SAP_ID: "SAP ID",
    STORE_NAME: "Store Name",
    FISCAL_QUARTER: "Fiscal Quarter",
    FISCAL_WEEK: "Fiscal Week",
    FISCAL_YEAR: "Fiscal Year",
    ARTICLE_ID: `${updateArticleName(false)} ID`,
    STYLE_ID: "Style ID",
    COLOR_ID: "Color ID",
    STYLE_DESC: "Style Description",
    STYLE_COLOR: "Style Color",
    COLOR: "Color",
    SIZE: "Size",
    WIDTH: "Width",
    UNITS_SOLD: "Units Sold",
    AVG_STORE_CLUSTER_UNITS: "Avg Store Cluster Units",
    LOST_OPPORTUNITY_UNITS: "Lost Opportunity (Units)",
    LOST_OPPORTUNITY_SALES: "Lost Opportunity (Revenue)",
    OPENING_INVENTORY: "Opening Inventory",
};

export const LOST_SALES_REPORT_BAR_GRAPH_PARAMS = {
    labels: [],
    units: {
        series: [{
            name: GRAPH_LOST_SALES_LABEL,//Units Data
            data: [],
        }],
        colorOption: ["##42A5F5"],
        xAxisLabel: "Week",
        yAxisLabel: "Lost Sales(Units)",
    },
    percentage: {
        series: [{
            name: GRAPH_LOST_SALES_LABEL,//Percentage data
            data: [],
        }],
        colorOption: ["##42A5F5"],
        xAxisLabel: "Week",
        yAxisLabel: "Lost Sales (% Units Sold)",
    },
};

export const REQ_BODY_KEY = {
    department: "level1",
    gender: "level2",
    subCat: "level3",
    dcs: "level4",
    level5: "level5",
    level6:"level6",
    level7:"level7",
    style: "style",
    color: "color",
    articleId: "article",
    storeId: "store",
    storeName: "storeName",
    sortColumn: "sortColumn",
    width: "width"
};