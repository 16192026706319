import React from "react";
import { numberUSFormatting } from "../../../utils/formatters/valueFormatters";

const LinkCell = ({ value, onClick }) => {
  return (
    <div
      style={{ textDecoration: "none", cursor: "pointer" }}
      onClick={onClick}
      className="clickable"
    >
      {numberUSFormatting(value)}
    </div>
  );
};

export default LinkCell;
