import { cloneDeep } from "lodash";
import React, { useEffect } from "react"
import { useState } from "react";
import ReactTollTip from "../../../../components/Table/Cellrenderer/ReactToolTip";
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ColumnFilter from "../../../UserManagement/ColumnFilter";
import { TABLE_HEADERS } from "../NewStoreSetupList/NewStoreListConstants";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const DetailsTable = (props) => {
    const { data, setSelectedSisterStores, setDcSrcRowsSelection, saveDCSource } = props
    const [sisterStoreData, setData] = useState([])
    const [initialSelectedRows, setInitialSelectedRows] = useState({})

    useEffect(() => {
        let arr = cloneDeep(data?.sisterStores);
        arr.forEach(item => {
            item.department = item.department?.length > data.filterData?.l1_name?.length ? "All Departments" : item.department
            item.seasoncode = item.seasoncode?.length > data.filterData?.seasoncode?.length ? "All Season Codes" : item.seasoncode
        })
        setData(arr)
        let allDc = data.dcDetails?.map(item => item.dc)
        let rows = {}
        data.selectedDc?.forEach(dc => {
            if(allDc.indexOf(dc) >= 0) {
                rows[allDc.indexOf(dc)] = true
            }
        })
        setInitialSelectedRows(rows)
    }, [data])

    const sisterStoreTableColumns = [
        {
            sticky: "left",
            Header: TABLE_HEADERS.STORE_CODE,
            accessor: 'store_code',
            Filter: ColumnFilter,
        },
        {
            sticky: "left",
            Header: TABLE_HEADERS.SAP_ID,
            accessor: 'sap_id',
            Filter: ColumnFilter,
        },
        {
            Header: TABLE_HEADERS.STORE_NAME,
            accessor: 'store_name',
            Filter: ColumnFilter,
            width: 250
        },
        {
            Header: TABLE_HEADERS.REGION,
            accessor: 'region',
            Filter: ColumnFilter,
        },
        {
            Header: TABLE_HEADERS.SEASON_CODE,
            accessor: 'seasoncode',
            Cell: (instance) => <ReactTollTip {...instance} />,
            Filter: ColumnFilter,
            width: 300
        },
        {
            Header: TABLE_HEADERS.DEPARTMENT,
            accessor: 'department',
            Cell: (instance) => <ReactTollTip {...instance} />,
            Filter: ColumnFilter,
            width: 300
        }
    ]

    const dcColumns = [
        {
            Header: "DC ID",
            accessor: 'dc',
            Filter: ColumnFilter,
        },
        {
            Header: "DC Name",
            accessor: 'dc_name',
            Filter: ColumnFilter,
            width: 250
        },
        // {
        //     Header: "State",
        //     accessor: 'state',
        //     Filter: ColumnFilter,
        // },
        {
            Header: "Country",
            accessor: 'country',
            Filter: ColumnFilter,
        },
        {
            Header: "Lead Time",
            accessor: 'transit_time',
            Cell: ({value, row}) => <span>{data?.leadTimeDcMapping?.[row?.original?.dc] || value}</span>,
            Filter: ColumnFilter,
        },
        {
            Header: "Distance (Miles)",
            accessor: 'distance',
            Filter: ColumnFilter,
        }
    ]

    const onDCSourcingInventoryMount = (inst) => {
        saveDCSource({data: inst?.selectedFlatRows.map(item => item?.original?.dc)})
        setDcSrcRowsSelection(inst.getSelectedRows);
    }

    return (
        <>
            <section className="section my-4 m-0">
                <h3 className="fnt-bold m-0 py-3">Sister Stores</h3>
                <hr className="m-0 mb-2" />

                {/* {(dataError) ? (<div className="error">{dataError}</div>) :
                    (data ? ( */}
                <ReactTableWithPlugins
                    shouldPagination
                    data={(sisterStoreData || [])}
                    columns={sisterStoreTableColumns}
                    renderMarkup='TableMarkup'
                    keyRT="sortAndSearch"
                    tableId="newstore_sister_stores"
                />
                {/* ) : null)} */}
            </section>
            <section className="section mt-4 m-0">
                <h3 className="fnt-bold m-0 py-3">DC Source</h3>
                <hr className="m-0 mb-2" />
                {/* <PageLoader loader={dataLoading} gridLoader={true}> */}
                {/* {(dataError) ? (<div className="error">{dataError}</div>) :
                    (data ? ( */}
                <ReactTableWithPlugins
                    shouldPagination
                    data={(data ? data?.dcDetails : [])}
                    columns={dcColumns}
                    renderMarkup='TableMarkup'
                    features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                    keyRT="StrategyPageTable"
                    tableId="newstore_dcTable"
                    getInstanceOnMount={(inst) => onDCSourcingInventoryMount(inst)}
                    initialSelectedRows={initialSelectedRows}
                />
                {/*  ) : null)} */}
                {/* </PageLoader> */}
            </section>
        </>
    )
}

export default DetailsTable;