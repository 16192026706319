import {
    GET_REPORT, GET_REPORT_SUCCESS, GET_REPORT_ERROR, RESET_ALL, RESET_FILTERS_DATA,
    FETCH_FILTER_SUCCESS,
    GET_POPUP,
    GET_POPUP_SUCCESS,
    GET_POPUP_ERROR,
    GENERATE_EXCEL,
    GENERATE_EXCEL_ERROR,
    GENERATE_EXCEL_SUCCESS,
    RESET_DOWNLOAD_EXCEL
} from "./ReportingAction"

import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

export const reportingState = {
    dataLoading: false,
    data: [],
    dataError: null,
    departmentOptions: [],
    genderOptions: [],
    rbuOptions: [],
    dcsOptions: [],
    level5Options: [],
    level6Options: [],
    level7Options: [],
    articleStatusOptions: [],
    storeOptions: [],
    storeNameOptions: [],
    storeGradeOptions: [],
    regionOptions: [],
    climateOptions: [],
    storeStatusOptions: [],
    articleIdOptions: [],
    channelOptions: [],
    totalCount: 0,
    nextIndex: 0,
    popupLoading: false,
    popupColumn: [],
    popupData: [],
    popupError: null,
    excelData: [],
    excelError: false,
    excelSuccess: true,
    loading: false,
    totalCountExcel: 0,
    nextIndexExcel: 0,
    downloadLoading: false ,
}

export default {
    reporting: handleActions(
        {
            [GET_REPORT]: (state, { payload }) => {
                return immutable(state, {
                    dataLoading: { $set: true },
                    totalCount: { $set: 0 },
                    nextIndex: { $set: 0 }
                });
            },
            [GET_REPORT_SUCCESS]: (state, { payload }) => {
                return immutable(state, {
                    dataLoading: { $set: false },
                    dataError: { $set: null },
                    data: { $set: (payload && payload.data) || [] },
                    totalCount: { $set: (payload && payload.totalCount) || 0 },
                    nextIndex: { $set: (payload && payload.nextIndex) || 0 }
                });
            },
            [GET_REPORT_ERROR]: (state, { payload }) => {
                return immutable(state, {
                    dataError: { $set: (payload && payload.message) || null },
                    dataLoading: { $set: false },
                    data: { $set: [] },
                });
            },

            [GET_POPUP]: (state, { payload }) => {
                return immutable(state, {
                    popupLoading: { $set: true },
                    popupColumn: { $set: [] },
                    popupData: { $set: [] },
                    popupError: { $set: null },
                });
            },
            [GET_POPUP_SUCCESS]: (state, { payload }) => {
                return immutable(state, {
                    popupLoading: { $set: false },
                    popupColumn: { $set: (payload && payload.popupColumn) || [] },
                    popupData: { $set: (payload && payload.popupData) || [] },
                    popupError: { $set: null },
                });
            },
            [GET_POPUP_ERROR]: (state, { payload }) => {
                return immutable(state, {
                    popupLoading: { $set: false },
                    popupColumn: { $set: [] },
                    popupData: { $set: [] },
                    popupError: { $set: (payload && payload.error) || null },
                });
            },

            [FETCH_FILTER_SUCCESS]: (state, { payload }) => {
                switch (payload.key) {
                    case "department":
                        return immutable(state, {
                            departmentOptions: {
                                $set:
                                    payload && payload.data.departmentOptions
                                        ? payload.data.departmentOptions
                                        : state.departmentOptions,
                            },
                            channelOptions: {
                                $set:
                                    payload && payload.data.channelOptions
                                        ? payload.data.channelOptions
                                        : state.channelOptions,
                            },
                            genderOptions: [],
                            rbuOptions: [],
                            dcsOptions: [],
                            level5Options: [],
                            level6Options: [],
                            level7Options: [],
                            storeGradeOptions: [],
                            regionOptions: [],
                            climateOptions: [],
                            storeStatusOptions: [],
                            articleStatusOptions: [],
                            articleIdOptions: [],
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "gender":
                        return immutable(state, {
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            storeNameOptions: {
                                $set:
                                    payload && payload.data.storeNameOptions
                                        ? payload.data.storeNameOptions
                                        : state.storeNameOptions,
                            },
                            genderOptions: {
                                $set:
                                    payload && payload.data.genderOptions
                                        ? payload.data.genderOptions
                                        : state.genderOptions,
                            },
                            rbuOptions: {
                                $set:
                                    payload && payload.data.rbuOptions
                                        ? payload.data.rbuOptions
                                        : state.rbuOptions,
                            },
                            dcsOptions: {
                                $set:
                                    payload && payload.data.dcsOptions
                                        ? payload.data.dcsOptions
                                        : state.dcsOptions,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            articleStatusOptions: {
                                $set:
                                    payload && payload.data.articleStatusOptions
                                        ? payload.data.articleStatusOptions
                                        : state.articleStatusOptions,
                            },
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            storeNameOptions: {
                                $set:
                                    payload && payload.data.storeNameOptions
                                        ? payload.data.storeNameOptions
                                        : state.storeNameOptions,
                            },
                            storeGradeOptions: {
                                $set:
                                    payload && payload.data.storeGradeOptions
                                        ? payload.data.storeGradeOptions
                                        : state.storeGradeOptions,
                            },
                            regionOptions: {
                                $set:
                                    payload && payload.data.regionOptions
                                        ? payload.data.regionOptions
                                        : state.regionOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            channelOptions: {
                                $set:
                                    payload && payload.data.channelOptions
                                        ? payload.data.channelOptions
                                        : state.channelOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "sub_category":
                        return immutable(state, {
                            rbuOptions: {
                                $set:
                                    payload && payload.data.rbuOptions
                                        ? payload.data.rbuOptions
                                        : state.rbuOptions,
                            },
                            dcsOptions: {
                                $set:
                                    payload && payload.data.dcsOptions
                                        ? payload.data.dcsOptions
                                        : state.dcsOptions,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            articleStatusOptions: {
                                $set:
                                    payload && payload.data.articleStatusOptions
                                        ? payload.data.articleStatusOptions
                                        : state.articleStatusOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            storeGradeOptions: {
                                $set:
                                    payload && payload.data.storeGradeOptions
                                        ? payload.data.storeGradeOptions
                                        : state.storeGradeOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            storeNameOptions: {
                                $set:
                                    payload && payload.data.storeNameOptions
                                        ? payload.data.storeNameOptions
                                        : state.storeNameOptions,
                            },
                            channelOptions: {
                                $set:
                                    payload && payload.data.channelOptions
                                        ? payload.data.channelOptions
                                        : state.channelOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "dcs":
                        return immutable(state, {
                            dcsOptions: {
                                $set:
                                    payload && payload.data.dcsOptions
                                        ? payload.data.dcsOptions
                                        : state.dcsOptions,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            articleStatusOptions: {
                                $set:
                                    payload && payload.data.articleStatusOptions
                                        ? payload.data.articleStatusOptions
                                        : state.articleStatusOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            storeGradeOptions: {
                                $set:
                                    payload && payload.data.storeGradeOptions
                                        ? payload.data.storeGradeOptions
                                        : state.storeGradeOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            storeNameOptions: {
                                $set:
                                    payload && payload.data.storeNameOptions
                                        ? payload.data.storeNameOptions
                                        : state.storeNameOptions,
                            },
                            channelOptions: {
                                $set:
                                    payload && payload.data.channelOptions
                                        ? payload.data.channelOptions
                                        : state.channelOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "l10":
                            return immutable(state, {
                                genderOptions: {
                                    $set:
                                        payload && payload.data.genderOptions
                                            ? payload.data.genderOptions
                                            : state.genderOptions,
                                },
                                channelOptions: {
                                    $set:
                                        payload && payload.data.channelOptions
                                            ? payload.data.channelOptions
                                            : state.channelOptions,
                                },
                                filterlabels: {
                                    $set:
                                        payload && payload.data.filterMapping
                                            ? payload.data.filterMapping
                                            : state.filterMapping
                                }
                            });
                    case "l11":
                            return immutable(state, {
                                rbuOptions: {
                                    $set:
                                        payload && payload.data.rbuOptions
                                            ? payload.data.rbuOptions
                                            : state.rbuOptions,
                                },
                                dcsOptions: {
                                    $set:
                                        payload && payload.data.dcsOptions
                                            ? payload.data.dcsOptions
                                            : state.dcsOptions,
                                },
                                level5Options: {
                                    $set:
                                        payload && payload.data.level5Options
                                            ? payload.data.level5Options
                                            : state.level5Options,
                                },
                                level6Options: {
                                    $set:
                                        payload && payload.data.level6Options
                                            ? payload.data.level6Options
                                            : state.level6Options,
                                },
                                level7Options: {
                                    $set:
                                        payload && payload.data.level7Options
                                            ? payload.data.level7Options
                                            : state.level7Options,
                                },
                                articleStatusOptions: {
                                    $set:
                                        payload && payload.data.articleStatusOptions
                                            ? payload.data.articleStatusOptions
                                            : state.articleStatusOptions,
                                },
                                articleIdOptions: {
                                    $set:
                                        payload && payload.data.articleIdOptions
                                            ? payload.data.articleIdOptions
                                            : state.articleIdOptions,
                                },
                                storeGradeOptions: {
                                    $set:
                                        payload && payload.data.storeGradeOptions
                                            ? payload.data.storeGradeOptions
                                            : state.storeGradeOptions,
                                },
                                climateOptions: {
                                    $set:
                                        payload && payload.data.climateOptions
                                            ? payload.data.climateOptions
                                            : state.climateOptions,
                                },
                                storeStatusOptions: {
                                    $set:
                                        payload && payload.data.storeStatusOptions
                                            ? payload.data.storeStatusOptions
                                            : state.storeStatusOptions,
                                },
                                storeOptions: {
                                    $set:
                                        payload && payload.data.storeOptions
                                            ? payload.data.storeOptions
                                            : state.storeOptions,
                                },
                                storeNameOptions: {
                                    $set:
                                        payload && payload.data.storeNameOptions
                                            ? payload.data.storeNameOptions
                                            : state.storeNameOptions,
                                },
                                channelOptions: {
                                    $set:
                                        payload && payload.data.channelOptions
                                            ? payload.data.channelOptions
                                            : state.channelOptions,
                                },
                                climateOptions: {
                                    $set:
                                        payload && payload.data.climateOptions
                                            ? payload.data.climateOptions
                                            : state.climateOptions,
                                },
                                filterlabels: {
                                    $set:
                                        payload && payload.data.filterMapping
                                            ? payload.data.filterMapping
                                            : state.filterMapping
                                }
                            });
                    case "level5":
                        return immutable(state, {
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            articleStatusOptions: {
                                $set:
                                    payload && payload.data.articleStatusOptions
                                        ? payload.data.articleStatusOptions
                                        : state.articleStatusOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            storeGradeOptions: {
                                $set:
                                    payload && payload.data.storeGradeOptions
                                        ? payload.data.storeGradeOptions
                                        : state.storeGradeOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            storeNameOptions: {
                                $set:
                                    payload && payload.data.storeNameOptions
                                        ? payload.data.storeNameOptions
                                        : state.storeNameOptions,
                            },
                            channelOptions: {
                                $set:
                                    payload && payload.data.channelOptions
                                        ? payload.data.channelOptions
                                        : state.channelOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "level6":
                        return immutable(state, {
                            level6Options: {
                                $set:
                                    payload && payload.data.level6Options
                                        ? payload.data.level6Options
                                        : state.level6Options,
                            },
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            articleStatusOptions: {
                                $set:
                                    payload && payload.data.articleStatusOptions
                                        ? payload.data.articleStatusOptions
                                        : state.articleStatusOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            storeGradeOptions: {
                                $set:
                                    payload && payload.data.storeGradeOptions
                                        ? payload.data.storeGradeOptions
                                        : state.storeGradeOptions,
                            },
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            storeNameOptions: {
                                $set:
                                    payload && payload.data.storeNameOptions
                                        ? payload.data.storeNameOptions
                                        : state.storeNameOptions,
                            },
                            channelOptions: {
                                $set:
                                    payload && payload.data.channelOptions
                                        ? payload.data.channelOptions
                                        : state.channelOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "level7":
                        return immutable(state, {
                            level7Options: {
                                $set:
                                    payload && payload.data.level7Options
                                        ? payload.data.level7Options
                                        : state.level7Options,
                            },
                            articleStatusOptions: {
                                $set:
                                    payload && payload.data.articleStatusOptions
                                        ? payload.data.articleStatusOptions
                                        : state.articleStatusOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            storeGradeOptions: {
                                $set:
                                    payload && payload.data.storeGradeOptions
                                        ? payload.data.storeGradeOptions
                                        : state.storeGradeOptions,
                            },
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            storeNameOptions: {
                                $set:
                                    payload && payload.data.storeNameOptions
                                        ? payload.data.storeNameOptions
                                        : state.storeNameOptions,
                            },
                            channelOptions: {
                                $set:
                                    payload && payload.data.channelOptions
                                        ? payload.data.channelOptions
                                        : state.channelOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "article_status":
                        return immutable(state, {
                            articleStatusOptions: {
                                $set:
                                    payload && payload.data.articleStatusOptions
                                        ? payload.data.articleStatusOptions
                                        : state.articleStatusOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            storeNameOptions: {
                                $set:
                                    payload && payload.data.storeNameOptions
                                        ? payload.data.storeNameOptions
                                        : state.storeNameOptions,
                            },
                            channelOptions: {
                                $set:
                                    payload && payload.data.channelOptions
                                        ? payload.data.channelOptions
                                        : state.channelOptions,
                            },
                            
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "store_id":
                        return immutable(state, {
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            storeNameOptions: {
                                $set:
                                    payload && payload.data.storeNameOptions
                                        ? payload.data.storeNameOptions
                                        : state.storeNameOptions,
                            },
                            storeGradeOptions: {
                                $set:
                                    payload && payload.data.storeGradeOptions
                                        ? payload.data.storeGradeOptions
                                        : state.storeGradeOptions,
                            },
                            regionOptions: {
                                $set:
                                    payload && payload.data.regionOptions
                                        ? payload.data.regionOptions
                                        : state.regionOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "store_name":
                        return immutable(state, {
                            storeNameOptions: {
                                $set:
                                    payload && payload.data.storeNameOptions
                                        ? payload.data.storeNameOptions
                                        : state.storeNameOptions,
                            },
                            storeGradeOptions: {
                                $set:
                                    payload && payload.data.storeGradeOptions
                                        ? payload.data.storeGradeOptions
                                        : state.storeGradeOptions,
                            },
                            regionOptions: {
                                $set:
                                    payload && payload.data.regionOptions
                                        ? payload.data.regionOptions
                                        : state.regionOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "store_grade":
                        return immutable(state, {
                            storeGradeOptions: {
                                $set:
                                    payload && payload.data.storeGradeOptions
                                        ? payload.data.storeGradeOptions
                                        : state.storeGradeOptions,
                            },
                            regionOptions: {
                                $set:
                                    payload && payload.data.regionOptions
                                        ? payload.data.regionOptions
                                        : state.regionOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "region":
                        return immutable(state, {
                            regionOptions: {
                                $set:
                                    payload && payload.data.regionOptions
                                        ? payload.data.regionOptions
                                        : state.regionOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "climate":
                        return immutable(state, {
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "store_status":
                        return immutable(state, {
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "articleid":
                        return immutable(state, {
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            storeNameOptions: {
                                $set:
                                    payload && payload.data.storeNameOptions
                                        ? payload.data.storeNameOptions
                                        : state.storeNameOptions,
                            },
                            storeGradeOptions: {
                                $set:
                                    payload && payload.data.storeGradeOptions
                                        ? payload.data.storeGradeOptions
                                        : state.storeGradeOptions,
                            },
                            regionOptions: {
                                $set:
                                    payload && payload.data.regionOptions
                                        ? payload.data.regionOptions
                                        : state.regionOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    default:
                        return immutable(state, {
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                };
            },
            [RESET_ALL]: (state => {
                return immutable(state, {
                    dataLoading: { $set: false },
                    data: { $set: [] },
                    dataError: { $set: null },
                    departmentOptions: { $set: [] },
                    genderOptions: { $set: [] },
                    rbuOptions: { $set: [] },
                    dcsOptions: { $set: [] },
                    level5Options: { $set: [] },
                    level6Options: { $set: [] },
                    level7Options: { $set: [] },
                    articleStatusOptions: { $set: [] },
                    storeOptions: { $set: [] },
                    storeNameOptions: { $set: [] },
                    storeGradeOptions: { $set: [] },
                    regionOptions: { $set: [] },
                    climateOptions: { $set: [] },
                    storeStatusOptions: { $set: [] },
                    articleIdOptions: { $set: [] },
                    totalCount: { $set: 0 },
                    nextIndex: { $set: 0 },
                    totalCountExcel: { $set: 0 },
                    nextIndexExcel: { $set: 0 },
                    popupLoading: { $set: false },
                    popupColumn: { $set: [] },
                    popupData: { $set: [] },
                    popupError: { $set: null },
                    excelData: { $set: [] },
                    excelError: { $set: false },
                    excelSuccess: { $set: false },
                })
            }),
            [RESET_FILTERS_DATA]: (state => {
                return immutable(state, {
                    data: { $set: [] },
                    genderOptions: { $set: [] },
                    rbuOptions: { $set: [] },
                    dcsOptions: { $set: [] },
                    level5Options: { $set: [] },
                    level6Options: { $set: [] },
                    level7Options: { $set: [] },
                    articleStatusOptions: { $set: [] },
                    storeOptions: { $set: [] },
                    storeNameOptions: { $set: [] },
                    storeGradeOptions: { $set: [] },
                    regionOptions: { $set: [] },
                    climateOptions: { $set: [] },
                    storeStatusOptions: { $set: [] },
                    articleIdOptions: { $set: [] },
                    totalCount: { $set: 0 },
                    nextIndex: { $set: 0 },
                    totalCountExcel: { $set: 0 },
                    nextIndexExcel: { $set: 0 },
                    popupLoading: { $set: false },
                    popupColumn: { $set: [] },
                    popupData: { $set: [] },
                    popupError: { $set: null },
                    excelData: { $set: [] },
                    excelError: { $set: false },
                    excelSuccess: { $set: false },
                })
            }),
            [GENERATE_EXCEL]: (state, { payload }) => {
                return immutable(state, {
                  excelSuccess: { $set: false },
                  excelError: { $set: false },
                //   downloadNextIndex: { $set: 0 },
                  downloadLoading: { $set: true }
                //   loading: { $set: true },
                //   totalCountExcel: { $set: 0 },
                //   nextIndexExcel: { $set: 0 },
                });
              },
              [GENERATE_EXCEL_ERROR]: (state, { payload }) => {
                return immutable(state, {
                  excelData: { $set: [] },
                  loading: { $set: false },
                  excelError: { $set: true },
                  excelSuccess: { $set: false },
                  totalCountExcel: { $set: 0 },
                  nextIndexExcel: { $set: 0 },
                });
              },
              [GENERATE_EXCEL_SUCCESS]: (state, { payload }) => {
                return immutable(state, {
                  excelData: { $set: (payload && payload.data) || [] },
                  loading: { $set: false },
                  excelError: { $set: false },
                  excelSuccess: { $set: true },
                  totalCountExcel: { $set: (payload && payload.totalCountExcel) || 0 },
                  nextIndexExcel: { $set: (payload && payload.nextIndexExcel) || 0 },
                  downloadNextIndex: { $set: (payload && state.downloadLoading && payload.nextIndex) || 0 },
                });
              },
              [RESET_DOWNLOAD_EXCEL]: (state, { payload }) => {
                return immutable(state, {
                  excelData: { $set: [] },
                  nextIndexExcel: { $set: 0 },
                  totalCountExcel: { $set: 0 },
                  downloadLoading: { $set: false },
                  downloadNextIndex: { $set: 0 }
                });
              },
        },
        reportingState
    )
}