import React, { useState } from "react";
import ClusterFiltersInModal from "../../../components/Clustering/ClusterFiltersInModal";
import Modal from "react-awesome-modal";
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import { useEffect } from "react";
import { connect } from "react-redux";
import {
  createStoreGroupAction,
  createStoreGroupFiltersAction,
  createStoreGroupStoreDataAction,
  resetAll,
  storeGroupAction,
} from "../StoreGroupAction";
import ReactTable from "../../../components/Table/ReactTable";
import ColumnFilter from "../../UserManagement/ColumnFilter";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import _, { isEmpty } from "lodash";
import PageLoader from "../../../components/Loader/PageLoader";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import EditIconCell from "../../../components/Table/Cellrenderer/EditIconCell";
import {
  HIDE_TABLE_COLUMNS,
  currentClientName,
  getStoreGradeColumnNamesByClient,
  isCoach,
  getLabelValueFromArray,
} from "../../../utils/commonUtilities";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const CreateEditStoreGroup = (props) => {
  const [filterData, setFilterData] = useState({});
  const [isFirst, setIsFirst] = useState(true);
  const [firstTableData, setFirstTableData] = useState([]);
  const [secondTableData, setSecondTableData] = useState([]);
  const [storeGroupTableInstance, setStoreGroupTableInstance] = useState(null);
  const [initialSelectedRows, setInitialSelectedRows] = useState({});
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [storeGroupName, setStoreGroupName] = useState(null);
  const [errorClassName, setErrorClass] = useState(null);
  const [height, setHeight] = useState("");
  const [filterReq, setFilterReq] = useState({});
  const [modalHeading, setModalHeading] = useState("");
  const [button1Label, setButton1Label] = useState("");
  const [disableAll, setDisableAll] = useState(false);
  const [filterValue, setFilterValues] = useState({});
  const [filterValueCopy, setFilterValueCopy] = useState({});
  const [initialHiddenColumns, setInitialHiddenColumns] = useState(["dummyId"]);
  const [storeGradeColumns, setStoreGradeColumns] = useState(
    getStoreGradeColumnNamesByClient()
  );

  useEffect(() => {
    let hideColumns = HIDE_TABLE_COLUMNS[currentClientName];
    hideColumns && setInitialHiddenColumns(hideColumns);
  }, []);

  const initialStoreCols = React.useMemo(
    () => [
      {
        Header: "DummyID",
        accessor: "dummyId",
        Filter: ColumnFilter,
      },
      {
        Header: "Store Number",
        accessor: "store_code",
        Filter: (instance) => (
          <ColumnFilter {...instance} placeholder="Search in bulk..." />
        ),
        filter: "bulkFilterSpaceSeperated",
      },
      {
        Header: "SAP ID",
        accessor: "sap_id",
        Filter: (instance) => (
          <ColumnFilter {...instance} placeholder="Search in bulk..." />
        ),
        filter: "bulkFilterSpaceSeperated",
      },
      {
        Header: "Country",
        accessor: "country",
        width: 250,
        Filter: ColumnFilter,
      },
      {
        Header: "Store Name",
        accessor: "store_name",
        width: 250,
        Filter: ColumnFilter,
      },
      storeGradeColumns,
      {
        Header: "Region Description",
        accessor: "region",
        Filter: ColumnFilter,
        width: 200,
      },
    ],
    [storeGradeColumns]
  );

  const storecolumns = React.useMemo(
    () => [
      {
        Header: "Store Number",
        accessor: "store_code",
        Filter: (instance) => (
          <ColumnFilter {...instance} placeholder="Search in bulk..." />
        ),
        filter: "bulkFilterSpaceSeperated",
      },
      {
        Header: "SAP ID",
        accessor: "sap_id",
        Filter: (instance) => (
          <ColumnFilter {...instance} placeholder="Search in bulk..." />
        ),
        filter: "bulkFilterSpaceSeperated",
      },
      {
        Header: "Store Name",
        accessor: "store_name",
        width: 210,
        Filter: ColumnFilter,
      },
      {
        Header: "Country",
        accessor: "country",
        width: 250,
        Filter: ColumnFilter,
      },
      storeGradeColumns,
      {
        Header: "Region Description",
        accessor: "region",
        Filter: ColumnFilter,
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: (instance) => (
          <div>
            <EditIconCell
              handleDelete={(instance) => {
                let updatedStores = secondTableData.filter(
                  (val) => val.store_code != instance.store_code
                );
                setSecondTableData(updatedStores);
              }}
              instance={instance}
            ></EditIconCell>
          </div>
        ),
        resizable: true,
        disableFilters: true,
        width: 100,
      },
    ],
    [storeGradeColumns, secondTableData]
  );

  useEffect(() => {
    if (!isEmpty(props?.createStoreData?.data)) {
      setFirstTableData(props.createStoreData?.data);
      if (
        !isEmpty(props?.department) &&
        !props.isCreateStoreGroup &&
        isEmpty(filterReq)
      ) {
        let selectedRows = props?.department?.[0]?.stores_codes;
        let secondTable = props.createStoreData.data.filter((val) =>
          selectedRows?.includes(val.store_code)
        );
        let indexValues = [];
        props.createStoreData.data?.forEach((val, idx) => {
          if (selectedRows.includes(val.store_code)) {
            indexValues.push(idx);
          }
        });
        let preSelectedRows = {};
        indexValues?.forEach((item, index) => {
          preSelectedRows[`${item}`] = true;
        });
        setInitialSelectedRows(preSelectedRows);
        setSecondTableData(secondTable);
      }
    }
  }, [props.createStoreData, props.isCreateStoreGroup, props.department]);

  useEffect(() => {
    if (props.department && !props.isCreateStoreGroup) {
      let req = {};
      let filters = props.department?.[0];
      if (isCoach()) {
        req["l1_name"] = getLabelValueFromArray(filters?.l1_name);
        req["l2_name"] = getLabelValueFromArray(filters?.l2_name);
        // req["l3_name"] = getLabelValueFromArray(filters?.l3_name)
      } else {
        req["l1_name"] = getLabelValueFromArray(filters?.l1_name);
      }
      // req = getLabelValueFromArray(filters,"storeEligibilityGroup")
      // let req = props.department?.[0]?.l1_name.map(val => {
      //     return {
      //         label: val,
      //         value: val
      //     }
      // })
      setStoreGroupName(
        props.isCreateConfigFlow ? "" : props.department?.[0]?.title
      );
      props.createStoreGroupStoreDataAction({
        ...props.department?.[0]?.filter_data?.filters,
      });
      props.getStoreFiltersApi({ ...req }, { isEdit: true });
    }
  }, [props.department, props.isCreateStoreGroup]);

  useEffect(() => {
    if (props.isCreateStoreGroup) {
      setDisableAll(false);
      setModalHeading("Create");
      setButton1Label("Back");
      props.getStoreFiltersApi({});
    } else {
      setModalHeading("Edit");
      setButton1Label("Edit Store Group");
      setDisableAll(true);
      setIsFirst(false);
    }
  }, [props.isCreateStoreGroup]);

  useEffect(() => {
    setFilterData(props.storeGroupFiltersData);
  }, [props.storeGroupFiltersData]);

  useEffect(() => {
    if (props.createStoreError == "Error") {
      setErrorClass("Name '" + storeGroupName + "' is already defined!");
    } else {
      setErrorClass(props?.createStoreError);
    }
  }, [props.createStoreError]);

  useEffect(() => {
    if (props.createStoreSuccess) {
      if (!props?.isCreateConfigFlow) {
        props.NotifySuccess(props.isCreateStoreGroup ? "Created" : "Edited");
      }
      props.storeGroupAction({});
      props.closeAlert();
      if (props?.isCreateConfigFlow) {
        props.closeAlert(true);
      }
      setShowSaveModal(false);
    }
  }, [props.createStoreSuccess, props]);

  const nextClickHandler = React.useCallback(() => {
    let selectedIds = Object.keys(
      storeGroupTableInstance?.state?.selectedRowIds
    );
    if (selectedIds?.length >= 1) {
      let secondTableData = firstTableData.filter((val, idx) =>
        selectedIds.includes(String(idx))
      );
      let initialSelectedRows = {};
      selectedIds?.forEach((item, index) => {
        initialSelectedRows[`${item}`] = true;
      });
      setInitialSelectedRows(initialSelectedRows);
      let sortedFirstTable = firstTableData.map((val, idx) => {
        if (selectedIds.includes(String(idx))) {
          return {
            ...val,
            dummyId: 1,
          };
        }
        return val;
      });
      setFilterValues(filterValueCopy);
      setFirstTableData(sortedFirstTable);
      setSecondTableData(secondTableData);
      setIsFirst(false);
      setHeight("90%");
    } else {
      Notify.error("Select atleast 1 stores to create store group!!");
    }
  }, [
    filterValueCopy,
    storeGroupTableInstance?.state?.selectedRowIds,
    firstTableData,
  ]);

  const getStores = React.useCallback(
    (p_req) => {
      setFirstTableData([]);
      setSecondTableData([]);
      setFilterValueCopy(p_req);
      !isEmpty(initialSelectedRows) && setInitialSelectedRows({});
      let req = {};
      if (!isEmpty(p_req)) {
        for (const key in p_req) {
          req[key] = p_req[key].map(function mapper(ele) {
            if (Array.isArray(ele)) {
              return ele.map(mapper);
            } else {
              return ele.value;
            }
          });
        }
        for (let i in req) {
          req[i] = req[i].flat(Infinity);
        }
      }
      props.createStoreGroupStoreDataAction(req);
      setFilterReq(req);
    },
    [initialSelectedRows, props]
  );

  const resetTableData = () => {
    setFilterReq({});
    setFirstTableData([]);
    setSecondTableData([]);
  };

  const handleCreateStoreGroup = React.useCallback(() => {
    if (secondTableData?.length >= 1) setShowSaveModal(true);
    else Notify.error("Select atleast 1 stores to create store group!!");
  }, [secondTableData]);

  const handleSave = React.useCallback(() => {
    if (isEmpty(storeGroupName)) {
      setErrorClass("Please enter store group name!!");
    } else {
      // setErrorClass(null);
      let stores_code = secondTableData.map((val) => val.store_code);
      let req;
      if (props.isCreateStoreGroup) {
        req = {
          store_group_name: storeGroupName,
          stores_codes: stores_code,
          l1_name: filterReq?.l1_name,
          filters: filterReq,
          ...filterReq,
          created_by: localStorage.getItem("email"),
        };
      } else {
        req = {
          store_group_name: storeGroupName,
          stores_codes: stores_code,
          l1_name: props.department?.[0]?.filter_data?.l1_name,
          filters: !isEmpty(filterReq)
            ? filterReq
            : props.department?.[0]?.filter_data?.filters,
          store_group_code: props.department?.[0]?.store_group_code,
          ...(!isEmpty(filterReq)
            ? { ...filterReq }
            : { ...props.department?.[0]?.filter_data?.filters }),
          created_by: localStorage.getItem("email"),
        };
        if (props?.isCreateConfigFlow) {
          delete req.store_group_code;
        }
      }
      props.createStoreGroup(req);
    }
  }, [filterReq, props, secondTableData, storeGroupName]);

  const closeNameModal = React.useCallback(() => {
    setShowSaveModal(false);
    setErrorClass(null);
  }, []);

  const handleDelete = (instance) => {
    let updatedStores = secondTableData.filter(
      (val) => val.store_code != instance.store_code
    );
    setSecondTableData(updatedStores);
  };

  const handleBackClick = React.useCallback(() => {
    setIsFirst(true);
    setHeight("");
    let retainedStores = secondTableData?.map((val) => val.store_code);
    let sortedFirstTable = firstTableData.map((val) => {
      if (!retainedStores.includes(val.store_code) && val.dummyId == 1) {
        return {
          ...val,
          dummyId: 2,
        };
      }
      if (retainedStores.includes(val.store_code) && val.dummyId == 2) {
        return {
          ...val,
          dummyId: 1,
        };
      }
      return val;
    });
    setFirstTableData(sortedFirstTable);
    if (retainedStores.length != Object.keys(initialSelectedRows).length) {
      let indexValues = [];
      firstTableData?.forEach((val, idx) => {
        if (retainedStores.includes(val.store_code)) {
          indexValues.push(idx);
        }
      });
      let selectedRows = _.pickBy(initialSelectedRows, (val, key) =>
        indexValues?.includes(Number(key))
      );
      setInitialSelectedRows(selectedRows);
    }
  }, [firstTableData, initialSelectedRows, secondTableData]);

  return (
    <>
      <div className="modal_opacit">
        <Modal
          visible={props.showAlert}
          width="85%"
          //   height={height}
          effect="fadeInDown"
          onClickAway={props.closeAlert}
        >
          {/* <Notification /> */}
          <div className="modal-container">
            <div class="modal-header">
              <h5 className="page-heading text-center m-0">
                {modalHeading} Store Eligibility Group
              </h5>
              <button type="button" class="close" data-dismiss="modal">
                <span style={{ cursor: "pointer" }}>
                  <i
                    className="fa fa-times text-right m-2 "
                    aria-hidden="true"
                    onClick={props.closeAlert}
                  />
                </span>
              </button>
            </div>
            {!false ? (
              <div className="modal-middle-container">
                {isFirst ? (
                  <>
                    <div className="mx-9 p-2">
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <ClusterFiltersInModal
                          storeFilterData={filterData}
                          disableAll={disableAll}
                          getStores={getStores}
                          resetTableData={resetTableData}
                          filterValue={filterValue}
                          filterValues={
                            props.department?.length &&
                            !props.isCreateStoreGroup
                              ? props.department?.[0]?.filter_data?.filters
                              : {}
                          }
                        />
                      </div>
                    </div>
                    <hr className="hr-dotted mt-top" />
                    <div style={{ marginTop: "2rem" }}>
                      <PageLoader loader={props.loading} gridLoader={true}>
                        <ReactTableWithPlugins
                          hideColumnsFilter
                          getInstanceOnMount={(inst) => {
                            setStoreGroupTableInstance(inst);
                          }}
                          initialHiddenColumns={initialHiddenColumns}
                          sortBy={[{ id: "dummyId", desc: false }]}
                          // shouldPagination
                          embededScroll
                          data={firstTableData}
                          initialSelectedRows={initialSelectedRows}
                          columns={initialStoreCols}
                          renderMarkup="TableMarkup"
                          keyRT="constraintsPageTable"
                          // tableWrapperStyle={{ height: 'fit-content', maxHeight: '30rem' }}
                          // headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: 4 }}
                          pageSize={10}
                          features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                          tableId="store_group_create1"
                        />
                      </PageLoader>
                    </div>
                    {/* <div className="modal-footer" style={{ padding: "10px" }}> */}
                    <div className="text-center">
                      <button
                        onClick={() => nextClickHandler()}
                        className="btn btn-primary"
                        style={{ margin: "2.5rem 1.5rem 1.5rem" }}
                      >
                        Next
                      </button>
                      {/* </div> */}
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ marginTop: "2rem" }}>
                      <PageLoader loader={props.loading} gridLoader={true}>
                        <ReactTableWithPlugins
                          hideColumnsFilter
                          initialHiddenColumns={initialHiddenColumns}
                          // shouldPagination
                          embededScroll
                          data={secondTableData}
                          columns={storecolumns}
                          renderMarkup="TableMarkup"
                          keyRT="sortAndSearch"
                          // tableWrapperStyle={{ height: 'fit-content', maxHeight: '30rem' }}
                          // headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: 4 }}
                          pageSize={10}
                          tableId="store_group_create2"
                          // features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                        />
                      </PageLoader>
                    </div>
                    {/* <div className="modal-footer" style={{ padding: "10px" }}> */}
                    <div className="text-center">
                      <button
                        style={{ margin: "2.5rem 1.5rem 1.5rem" }}
                        onClick={() => handleBackClick()}
                        className="btn btn-secondary"
                      >
                        {button1Label}
                      </button>
                      <button
                        style={{ margin: "2.5rem 1.5rem 1.5rem" }}
                        onClick={() => handleCreateStoreGroup()}
                        className="btn btn-primary"
                      >
                        Save Store Group
                      </button>
                    </div>
                    {/* </div> */}
                  </>
                )}
              </div>
            ) : (
              <div className="error modal-middle-container">
                {props.createStoreGroupError}
              </div>
            )}
          </div>
        </Modal>
      </div>
      {showSaveModal ? (
        <>
          <ModalComponent
            width="30%"
            // height="50vh"
            showAlert={showSaveModal}
            closeAlert={() => closeNameModal()}
          >
            <div className="modal-container">
              <div className="modal-header">
                <div
                  className="d-flex justify-content-between"
                  style={{ width: "100%" }}
                >
                  <div>
                    <h5 className="page-heading m-0">{""}</h5>
                  </div>
                  <div>
                    <button type="button" class="close" data-dismiss="modal">
                      <span style={{ cursor: "pointer" }}>
                        <i
                          className="fa fa-times text-right m-2 "
                          aria-hidden="true"
                          onClick={() => closeNameModal()}
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="modal-middle-container modal-body"
                style={{ padding: "auto", marginTop: "5rem" }}
              >
                <label
                  for="name"
                  style={{
                    margin: "auto",
                    fontWeight: "bold",
                    fontSize: "initial",
                  }}
                >
                  Store Eligibility Group Name
                </label>
                <input
                  className="form-input form-control w-auto"
                  type={"text"}
                  style={{ width: "50% !important", margin: "10px auto" }}
                  id="name"
                  onChange={(e) => setStoreGroupName(e.target.value)}
                  value={storeGroupName}
                  placeholder="Enter here"
                />
                {errorClassName && (
                  <div className="errorClass">{errorClassName}</div>
                )}
                <div className="center">
                  <button
                    style={{ margin: "1rem" }}
                    onClick={() => closeNameModal()}
                    className="btn btn-secondary"
                  >
                    Close
                  </button>
                  <button
                    style={{ margin: "1rem" }}
                    onClick={() => handleSave()}
                    className="btn btn-primary"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </ModalComponent>{" "}
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    storeGroupFiltersData: store?.storeGroup?.storeGroupFiltersData,
    createStoreData: store?.storeGroup?.createStoreData,
    loading: store.storeGroup?.createStoreDataLoading,
    createStoreSuccess: store.storeGroup?.createStoreSuccess,
    createStoreError: store.storeGroup?.createStoreError,
    department: store.storeGroup?.department,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStoreFiltersApi: (payload, isEdit) =>
    dispatch(createStoreGroupFiltersAction(payload, isEdit)),
  createStoreGroupStoreDataAction: (payload) =>
    dispatch(createStoreGroupStoreDataAction(payload)),
  createStoreGroup: (bodyObj) => dispatch(createStoreGroupAction(bodyObj)),
  storeGroupAction: (payload) => dispatch(storeGroupAction(payload)),
  resetAll: () => dispatch(resetAll()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditStoreGroup);
