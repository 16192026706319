import { InputSwitch } from "primereact/inputswitch";
import React, { useState, useEffect } from "react";

const SwitchInput = (props) => {
    const { label, onChangeHandler, value } = props;

    const [checked, setIsChecked] = useState(false);

    const toggleView = (isCheck) => {
        setIsChecked(isCheck);
        onChangeHandler(isCheck);
    }

    useEffect(() => {
      setIsChecked(value);
    }, [value])

  return (
    <div className="d-flex">
      <label className="graph-toggle-label">{label}</label>
      <InputSwitch
        checked={checked}
        onChange={() => {
            toggleView(!checked);
        }}
      />
    </div>
  );
};

export default SwitchInput