import React, { useState, useEffect } from "react";
import DPicker from "reactstrap-date-picker";
import moment from "moment";
import "./CellRenderer.scss";

const DatePicker = React.memo(({ ...instance }) => {
  const { value } = instance;
  const [date, setDate] = useState(null);
  const onDateChange = (value) => {
    setDate(() => moment(value).toISOString());
  };

  useEffect(() => {
    setDate(() => moment(value).toISOString());
  }, [value]);

  return (
    <div style={{ minWidth: "15rem", height: "100%" }}>
      <DPicker
        value={date}
        calendarContainer={document.querySelector("#strategy-page-table")}
        className="date-picker"
        onChange={(v, f) => {
          onDateChange(v);
        }}
        size="lg"
        dateFormat = "MM/DD/YYYY"
      />
    </div>
  );
});

export default DatePicker;

// import React, { useEffect, useState } from "react";
// import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";
// const DatePickern = React.memo(({ ...instance }) => {
//   // const [startDate, setStartDate] = useState(new Date());
//   let {
//     row,
//     column,
//     updateMyData,
//     value,
//   } = instance;
//   const [date, setDate] = useState(null);
//   const onDateChange = (value) => {
//     setDate(() => new Date(value));
//     updateMyData(
//       row.index,
//       column.id,
//       value,
//       row.id,
//       '',
//       row
//     );
//   };

//   useEffect(() => {
//     setDate(() => new Date(value));
//   }, [value]);
//   return (
//     <div>
//        <DatePicker 
//             // closeOnScroll={true}
//             closeOnScroll={(e) => e.target.scrollLeft > 0}
//             className="custom-class" 
//             calendarClassName="rasta-stripe"
//             selected={date} 
//             onChange={(date) => onDateChange(date)} 
//         />
//     </div>
//   )
// });

// export default DatePickern
