import React, { useEffect, useCallback, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce, isEmpty } from 'lodash';
import HierarchyTable from './HierarchyTable';
import moment from "moment";
import PageLoader from "../../components/Loader/PageLoader";
import { NETWORK_ERROR_MSG, EMPTY_DATA_HELPER_MSG, EMPTY_HIERARCHY_MSG } from './AdaVisualConstants';
import * as adaVisualActions from './actions/AdaVisualActions';

/**
 * Hierarchy Forecast View component
 * @param {*} props 
 * @returns 
 */

const rowCount = 100

const ForecastHierarchyView = (props) => {
    
    const { adaVisualActions, startFiscalYearWeek, endFiscalYearWeek, currentFiscalYearWeek,
        adaAllocationReqBody, adaStandAloneReqBody, isAdaAllocationModeSelected,
        hierarchyData, isHierarchyFailed, isHierarchyLoading, isReviewSuccess, totalRecords } = props;

    const [initialIndex, setInitialIndex] = useState(0)
    const [prevIndex, setPrevIndex] = useState(0)
    const [nextIndex, setNextIndex] = useState(0)
    // const [totalCount, setTotalCount] = useState(adaStandAloneReqBody.articles.length)

    useEffect(() => {
      if (!isReviewSuccess) {
          adaVisualActions.clearHierarchyData(); // clear old Hierarchy data when data is being fetched 1st time.
            if (isAdaAllocationModeSelected) {
                const { adaReq } = adaAllocationReqBody;
                const currentDateKey = moment().format("YYYY-MM-DD")
                const hierarchyReqBody = {
                    start_date: startFiscalYearWeek ? startFiscalYearWeek : adaReq.current_fw,
                    current_date: currentDateKey,
                    end_date: endFiscalYearWeek ? endFiscalYearWeek : adaReq.current_fw,
                    articles: {
                        ...adaReq?.articles,
                    },
                    l1_name:adaReq &&  adaReq?.l1_name,
                    l2_name: adaReq && adaReq?.l2_name,
                    l3_name: adaReq && adaReq?.l3_name,
                    l4_name: adaReq && adaReq?.l4_name,
                    l5_name: adaReq && adaReq?.l5_name,
                    l6_name:adaReq && adaReq?.l6_name,
                    l7_name:adaReq && adaReq?.l7_name,
                    assortment_indicator:adaReq?.assortment_indicator,
                    store_code: adaReq?.store_code,
                    store_name: adaReq?.store_name
                };
                adaVisualActions.getAdaHierarchyAllocationData(hierarchyReqBody);
            } else {
                const { current_fw, l1_name, l2_name, l3_name, l4_name,l5_name,l6_name,l7_name,  articles, assortment_indicator, store_code, store_name } = adaStandAloneReqBody;
                const currentDateKey = moment().format("YYYY-MM-DD")
                const hierarchyReqBody = {
                    start_date: startFiscalYearWeek ? startFiscalYearWeek : current_fw,
                    end_date: endFiscalYearWeek ? endFiscalYearWeek : current_fw,
                    current_date: currentDateKey,
                    l1_name: l1_name,
                    l2_name: l2_name,
                    l3_name: l3_name && l3_name ,
                    l4_name: l4_name && [...l4_name] ,
                    l5_name: l5_name && [...l5_name] ,
                    l6_name: l6_name && [...l6_name] ,
                    l7_name: l7_name && [...l7_name] ,
                    // articles: articles && [...articles] ,
                    assortment_indicator: assortment_indicator && [...assortment_indicator],
                    store_code: store_code && [...store_code],
                    store_name: store_name && [...store_name],
                };
                const articlesList = articles.filter((item) => item !== "*");
                adaVisualActions.getAdaHierarchyData({
                    request: hierarchyReqBody,
                    rowIndex: nextIndex,
                    rowCount,
                    total: 0,
                    articles: articlesList,
                })
            };
        } else {
            //No code here
        }
    }, []);

   

    return (
      <>
        {isHierarchyFailed ? (
          <div className="error">{NETWORK_ERROR_MSG}</div>
        ) : (
          <PageLoader loader={isHierarchyLoading}>
            {!isEmpty(hierarchyData) ? (
              <HierarchyTable
                data={hierarchyData}
                currentWeek={currentFiscalYearWeek}
              />
            ) : (
              <div className="empty">
                {EMPTY_HIERARCHY_MSG}
                <p>{EMPTY_DATA_HELPER_MSG}</p>
              </div>
            )}
          </PageLoader>
        )}
      </>
    );
}

const mapStateToProps = ({ adaVisual }) => ({
    startFiscalYearWeek: adaVisual.startFiscalYearWeek,
    endFiscalYearWeek: adaVisual.endFiscalYearWeek,
    currentFiscalYearWeek: adaVisual.currentFiscalYearWeek,
    adaAllocationReqBody: adaVisual.adaAllocationReqBody,
    isAdaAllocationModeSelected: adaVisual.isAdaAllocationModeSelected,
    adaStandAloneReqBody: adaVisual.adaStandAloneReqBody,
    hierarchyData: adaVisual.hierarchyData,
    hierarchyDataCopy: adaVisual.hierarchyDataCopy,
    isHierarchyLoading: adaVisual.isHierarchyLoading,
    isHierarchyFailed: adaVisual.isHierarchyFailed,
    isReviewSuccess: adaVisual.isReviewSuccess,
    totalRecords: adaVisual.totalRecords,
});

const mapDispatchToProps = (dispatch) => ({
    adaVisualActions: bindActionCreators(adaVisualActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForecastHierarchyView);