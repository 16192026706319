export const adaVisualInitialState = {
    isAdaDataLoading: false,
    isAdaReqFailed: false,
    isAdaLoadingSuccess: false,
    errorMsg: '',
    adaAllocationReqBody: {},
    isAdaAllocationModeSelected: false,
    adaVisualData: [],
    accuracy:{},
    adaVisualDataCopy: [],
    adaDriversData: {},
    isDriversDataLoading: false,
    isDriversDataFailed: false,
    timeConstraint: 'week',
    currentFiscalYearWeek: null,
    startFiscalYearWeek: null,
    endFiscalYearWeek: null,
    startDateStamp: null,
    endDateStamp: null,
    adaGraphData: null,
    isAdaFilterLoading: false,
    isAdaFilterFailed: false,
    filterLabels: {},
    departmentOptions: [],
    genderOptions: [],
    subCatOptions: [],
    dcsOptions: [],
    level5Options: [],
    level6Options: [],
    level7Options: [],
    articleIdOptions: [],
    assortmentIndicatorOptions: [],
    storeOptions: [],
    storeNameOptions: [],
    adaStandAloneReqBody: {},
    isHierarchyLoading: false,
    isHierarchyFailed: false,
    hierarchyData: [],
    hierarchyDataCopy: [],
    isReviewSuccess: false,
    isReviewFailed: false,
    isReviewReq: false,
    isForecastModified: false,
    isHierarchyModified: false,
    isSaveReq: false,
    isSaveSuccess: false,
    isSaveFailed: false,
    isHierarchyTable:false,
    downloadCsvData: [],
    downloadCsvLoading: false,
    downloadCsvError: null,
    downloadExcelLowerData: [],
    downloadExcelLowerLoading: false,
    downloadExcelLowerError: null,
    isStoreHierarchyLoading: false,
    isStoreHierarchyFailed: false,
    storeHierarchyData: {},
    updatedStoreData: {},
    updatedArticleWeek: {},
    updatedArticleData: {},
    updatedForecastData: {},
    excelData: {},
    excelError: false,
    excelSuccess: true,
    loading: false,
    totalCountExcel: 0,
    nextIndexExcel: 0,
    downloadLoading: false ,
    nextIndex: 0,
    lowerHierarchyNextIndex: 0,
    totalRecords: 0,
    currentArticlesInView: [],
};