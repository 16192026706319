export const initState = {
    departmentOptions: [],
    genderOptions: [],
    rbuOptions: [],
    dcsOptions: [],
    level5Options: [],
    level6Options: [],
    level7Options: [],
    styleOptions: [],
    colorOptions: [],
    articleOptions: [],
    storeOptions: [],
    storeNameOptions: [],
    widthOptions: [],
    filterlabels: {},
    excessTableData: [],
    excessGraphData: {},
    excelData: [],
    tableDataSuccess: false,
    tableDataLoading: false,
    tableDataError: null,
    graphDataSuccess: false,
    graphDataLoading: false,
    graphDataError: null,
    nextIndex: 0,
    totalCount: 0,
    weeks: [],
    yearData: [],
    sum: {},
    downloadNextIndex: 0,
    downloadTotalCount: 0,
    downloadExcelError: null,
    downloadLoading: false,
    quarters: []
}