import React, { useEffect, useState } from "react";
import "./ColumnSelection.css";
import Filter from "../../containers/ProductProfile/ProductProfileTable/Filter";
import { handleSelectAll } from "../../utils/SelectAllHandler/SelectAllHander";
import {
  getUserHiddenColumns,
  getDefaultHiddenColumns,
} from "../../utils/commonUtilities";
import * as Notify from "../Notification/Notifications";
import SaveOptionModal from "./SaveOptionModal";
import FreezeColumns from "./FreezeOptions";

const ColumnSelection = (props) => {
  const {
    columnConfig,
    setManualHiddenColumns,
    defaultHiddenColumns,
    tableId,
    hideColumns,
    saveFreezeColumns,
    saveChanges
  } = props;
  const [columnOptions, setColumnOptions] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [isReAssign, setIsReAssign] = useState(true);
  const [freezeColumnOptions, setFreezeColumnOptions] = useState([]);
  const [groupColumns, setGroupColumns] = useState([]);

  useEffect(() => {
    if (!columnOptions?.length) {
      createColumnOptions(columnConfig);
    }
  }, [columnConfig]);

  useEffect(() => {
    if (hideColumns?.length && isReAssign) {
      createColumnOptions(columnConfig);
      setIsReAssign(false);
    }
  }, [hideColumns]);

  let options = [];

  const createColumnOptions = (config) => {
    let freezeOptions = []
    let columnGroups = []
    let defaultColumns = defaultHiddenColumns || [];
    let selectedOptions = [];
    let hiddenColumns = [];
    config?.forEach((column) => {
      const isGroupColumn = !!(column?.parent?.Header && column?.parent?.Header?.trim()?.length)

      let label =
        column?.parent?.Header && column?.parent?.Header.trim()?.length
          ? `${column?.parent?.Header} - ${column.Header}`
          : typeof column.Header === "string"
          ? column.Header
          : "";
      

      // let label =
      // isGroupColumn
      //     ? `${column?.parent?.Header} - ${column.Header}`
      //     : typeof column.Header === "string"
      //     ? column.Header
      //     : "";
      // if (item?.columns) {
      //     let preHeader = item.Header && item.Header !== " " ? `${item.Header} - ` : ""
      //     item.columns.forEach(col => {
      //         if (col.Header && !(["Action", "Actions"].includes(col.Header)) &&
      //             !defaultColumns.includes(col.accessor)) {
      //             options.push({ label: (preHeader + col.Header).trim(), value: col.id && col.id.includes("dynamic") ? col.id?.split("dynamic")[1] : col.accessor })
      //         }
      //     })
      // }
      // else {
      if (
        column.Header &&
        !["Action", "Actions"].includes(column.Header) &&
        !defaultColumns.includes(column.id) &&
        label
      ) {
        // options.push({ label: column.Header, value: column.id && column.id.includes("dynamic") ? column.id?.split("dynamic")[1] : column.accessor })
        options.push({
          label,
          value:
            column.id && column.id.includes("dynamic")
              ? column.id?.split("dynamic")[1]
              : column.id,
        });
        if(!columnGroups.includes(column?.parent?.Header) && !columnGroups.includes(label) && !(column?.parent?.Header && column?.parent?.parent?.Header)) {
          freezeOptions.push({
            label: isGroupColumn ? column.parent.Header : label,
            value: isGroupColumn ? column.parent.Header :
              (column.id && column.id.includes("dynamic")
                ? column.id?.split("dynamic")[1]
                : column.id),
            isGroupColumn,
            childColumns: isGroupColumn ? column.parent.columns.map(col => col.id) : [],
            sticky: column?.sticky === "left" || column?.parent?.sticky === "left"
          })
        }
      }
      isGroupColumn && !columnGroups.includes(column.id) && columnGroups.push(column.id);
      isGroupColumn && !columnGroups.includes(column?.parent?.Header) && columnGroups.push(column?.parent?.Header)

      // }
      // createColumnOptions(item.columns)
    });
    // else {
    //     if (item.Header && !(["Action", "Actions"].includes(item.Header)) &&
    //         !defaultColumns.includes(item.accessor)) {
    //         options.push({ label: (preHeader + item.Header).trim(), value: item.id && item.id.includes("dynamic") ? item.id?.split("dynamic")[1] : item.accessor })
    //     }
    // }
    // })
    let distinctOptions = [
      ...new Map(options.map((item) => [item["value"], item])).values(),
    ];
    distinctOptions.forEach((col) => {
      let flag = true;
      getUserHiddenColumns(null, tableId).forEach((item) => {
        if (col.value === item.value) {
          // selectedOptions.push(item)
          flag = false;
        }
      });
      if (flag) {
        selectedOptions.push(col);
        // hiddenColumns.push(col)
      }
    });
    let hideColumnValues = getDefaultHiddenColumns().concat(hideColumns);
    options = distinctOptions.filter((col) => !hideColumnValues.includes(col.value));
    freezeOptions = freezeOptions.filter((col) => !hideColumnValues.includes(col.value) && !hideColumnValues.includes(col?.childColumns?.[0]));
    selectedOptions = selectedOptions.filter(
      (col) => !hideColumnValues.includes(col.value)
    );
    setGroupColumns(columnGroups)
    setColumnOptions(options);
    setSelectedColumns(selectedOptions);
    setFreezeColumnOptions(freezeOptions);
    // localStorage.setItem("hiddenColumns", JSON.stringify(hiddenColumns))
  };

  const onChangeHandler = (val, event, that) => {
    let valuesArray = handleSelectAll(val, event, that);
    setSelectedColumns(valuesArray[1]);
  };

  const filterObj = [
    {
      id: 1,
      isRequired: false,
      label: "Show Columns",
      name: "Columns",
      dropdownValue: selectedColumns,
      onDropDownChange: onChangeHandler,
      placeHolder: `Select columns`,
      optionsArray: columnOptions,
      isMultiSelect: true,
      // className: "setAll_filter",
      className: "fill p-0",
      labelClass: "show_columns__label",
    },
  ];

  const savePreference = async (option) => {
    const user_id = localStorage.getItem("user_id");
    let column_preferences =
      JSON.parse(localStorage.getItem("column_preferences")) || {};
    let selectedColumnValues = selectedColumns.map((col) => col.value);
    let allOptions = columnOptions.map((col) => col.value);
    let hiddenColumns =
      option === 2
        ? column_preferences?.["hiddenColumns"]?.[tableId] || []
        : column_preferences?.["hiddenColumns"]?.["global"] || [];
    let newHiddenColumns = columnOptions
      .concat(hiddenColumns)
      .filter((column) => !selectedColumnValues.includes(column.value));
    if (option === 1) {
      let hideValues = newHiddenColumns.map((col) => col.value);
      if (column_preferences?.hiddenColumns?.global) {
        let global = [...column_preferences?.hiddenColumns?.global].concat(
          [...new Set(newHiddenColumns.map(JSON.stringify))].map(JSON.parse)
        );
        global.forEach((col, index) => {
          if (hideValues.includes(col.value)) {
            global.splice(index, 1);
          }
        });
      } else {
        global = [...new Set(newHiddenColumns.map(JSON.stringify))].map(
          JSON.parse
        );
      }
      column_preferences = {
        ...column_preferences,
        hiddenColumns: {
          global: [...new Set(newHiddenColumns.map(JSON.stringify))].map(
            JSON.parse
          ),
        },
      };
    } else {
      column_preferences = {
        ...column_preferences,
        hiddenColumns: {
          ...column_preferences.hiddenColumns,
          [tableId]: [...new Set(newHiddenColumns.map(JSON.stringify))].map(
            JSON.parse
          ),
        },
      };
    }
    // localStorage.setItem("column_preferences", JSON.stringify(selectedColumns))

    // setManualHiddenColumns(allOptions.filter(column => !selectedColumnValues.includes(column)))
    setShowSaveModal(false);
    saveChanges(column_preferences)
    setManualHiddenColumns(newHiddenColumns.map((col) => col.value));
  };

  const showSaveOptionModal = () => {
    setShowSaveModal(true);
  };

  return (
    <>
    <div className="col-6">
        <Filter data={filterObj} />
        {/* <div> */}
          <button
            className="btn btn-primary px-2 mt-4 float-right"
            onClick={showSaveOptionModal}
          >
            Save Preference
          </button>
        {/* </div> */}
      </div>

        <FreezeColumns
          tableId={tableId}
          // columnOptions={columnOptions.filter(col => !groupColumns.includes(col.label))}
          columnOptions={freezeColumnOptions}
          columnConfig={columnConfig}
          freezeColumns={saveFreezeColumns}
          groupColumns={groupColumns}
        />
        {showSaveModal && (
          <SaveOptionModal
            showModal={showSaveModal}
            closeModal={() => {
              setShowSaveModal(false);
            }}
            savePreference={savePreference}
          />
        )}
        {/* <div className="font-italic py-2"> Selected columns are shown in the table</div> */}
    </>
  );
};

export default ColumnSelection;
