import { isEmpty } from "lodash";
import { takeLatest, takeEvery, put, all, call } from "redux-saga/effects";
import {
    FETCH_FILTER_DATA,
    GET_REPORT,
    GET_POPUP,
    fetchFilterDataSuccesss,
    fetchFilterDataError,
    getReportSuccess,
    getReportError,
    getReportingPopupDataSuccess,
    getReportingPopupDataError,
    GENERATE_EXCEL,
    fetchExcelDataSuccesss,
    fetchExcelDataError,
} from './ReportingAction'
import { getReportingFilters, getReporting } from "../../routes/api";
import { flattenArray } from "../../utils/commonUtilities";
import { ERROR_MSG } from "../../constants/globalConstant";

const g_get_table_mapping = {
    "level1": "l1_name",
    "level2": "l2_name",
    "level3": "l3_name",
    "level4": "l4_name",
    "level5": "l5_name",
    "level6":"l6_name",
    "level7":"l7_name",
    "region":"region",
    "style":"style",
    "store_grade":"store_grade",
    "store_code": "store_code",
    "store_name": "store_name",
    "store_status":"store_status",
    "store_type": "store_type",
    "article_status":"article_status",
    "articleid":"article",
    "climate":"climate",
    "country":"country"
  }

function* fetchFilterDataWorker(action) {
    const level_ln_map = {
        "level1": "l1_name",
        "level2": "l2_name",
        "level3": "l3_name",
        "level4": "l4_name",
        "level5": "l5_name",
        "level6":"l6_name",
        "level7":"l7_name",
        "country":"country"
      }
    try {
        let storeGradeSource = null;
        const { payload, filterKey } = action;
        let req = {};
        if (!isEmpty(payload)) {
            for (const key in payload) {
                if (key == "store_grade_source") {
                    storeGradeSource = payload[key] == "Department" ? null : payload[key]
                }
                else {
                    !isEmpty(payload[key]) && !isEmpty(payload[key][0]) && (req[g_get_table_mapping[key]|| key] = flattenArray(payload[key])?.map((ele) => ele?.value))//payload[key]?.map((ele) => ele && ele.value)
                }
            }
        }
        const res = yield call(getReportingFilters, req);
        if (res.data.status) {
            const data = {};
            data["filterMapping"] = res.data?.filterMapping
            // let topObject = Object.keys(res.data.filterData[0])[0]
            for (const key in res.data.filterData[0]) {
                let k = "";
                if (key === "l1_name") {
                    k = "departmentOptions";
                } else if (key === "l2_name") {
                    k = "genderOptions";
                } else if (key === "l3_name") {
                    k = "rbuOptions";
                } else if (key === "l4_name") {
                    k = "dcsOptions";
                } else if (key === "l5_name") {
                    k = "level5Options";
                }else if (key === "l6_name") {
                    k = "level6Options";
                }else if (key === "l7_name") {
                    k = "level7Options";
                } else if (key === "article_status") {
                    k = "articleStatusOptions";
                } else if (key === "store_code") {
                    k = "storeOptions";
                } else if (key === "store_name") {
                    k = "storeNameOptions";
                } else if (key === "store_grade") {
                    k = "storeGradeOptions";
                } else if (key === "region") {
                    k = "regionOptions";
                } else if (key === "climate") {
                    k = "climateOptions";
                } else if (key === "store_status") {
                    k = "storeStatusOptions";
                } else if (key === "article") {
                    k = "articleIdOptions"
                } else if (key === "country") {
                    k = "channelOptions"
                }

                data[k] = res.data.filterData[0][key == "store_grade" && storeGradeSource ? storeGradeSource : key]?.filter(val => val)?.map((element) => ({
                    value: element,
                    label: element,
                }));
            }
            yield put(fetchFilterDataSuccesss({ data: data, key: filterKey }));
        }
    } catch (error) {
        yield put(fetchFilterDataError({ error: ERROR_MSG }));
    }
}

function* getReportWorker(action) {
    try {
        const { payload } = action
        const filters = payload.filters
        let req = {}
        if (payload) {
            for (const key in filters) {
                if (filters[key] && filters[key].length > 0) {
                    filters[key] && (req[g_get_table_mapping[key]] = filters[key]?.map((ele) => ele.value))
                }
            }
        }
        // for (const key in filters) {
        //     if (filters[key]) {
        //         req[key] = filters[key]?.map((ele) => ele.value)
        //     }
        //     console.log('ss112',req)
        // }
        req["rowIndex"] = payload.rowIndex
        req["rowCount"] = payload.rowCount
        if (payload?.clearance != null) {
            req["clearance"] = [payload.clearance]
        }
        if (!isEmpty(payload?.searchTermReq)) {
            req["searchColumns"] = { ...payload?.searchTermReq }
        }
        if (!isEmpty(payload?.sortReq)) {
            req["sortColumn"] = { ...payload?.sortReq[0] }
        }
        const res = yield call(getReporting, req)
        if (res.data.status) {
            let data = res.data.reportingData
            const { dcs } = data[0]

            data.map(item => {
                dcs?.forEach(dcName => {
                    item[dcName] = item.dcs_value[dcName]
                })
            })
            // data.map(report => {
            //     report.clearance = report.clearance ? "Yes": "No"
            // })
            yield put(getReportSuccess({ data, totalCount: res.data.totalCount, nextIndex: payload.rowIndex + 100 }))
        }
        else {
            yield put(getReportError({ 'error': res.message }));
        }

    } catch (error) {
        yield put(getReportError(error));
    }
}

function* getReportingPopUpWorker(action) {
    try {
        const { payload } = action
        // const filters = payload.filters
        // let req = {}
        // for (const key in filters) {
        //     if (filters[key]) {
        //         req[key] = filters[key].map((ele) => ele.value)
        //     }
        // }
        // if (payload.clearance != null) {
        //     req["clearance"] = payload.clearance
        // }
        // let request = { ...req, articleid: [payload?.row?.articleid], store_id: [payload?.row?.storeid] }
        // const response = yield call(getReporting, request)
        if (payload?.row) {
            let retailSize = payload?.row?.retail_size
            let res = payload?.row
            let sizeColumns = []
            let data = {}
            for (let i in retailSize) {
                if (payload.accessor === "total_inventory") {
                    try {
                        let total_inventory = Number(res['oh_upc'][i]) + Number(res['oo_upc'][i]) + Number(res['it_upc'][i])
                        data[retailSize[i]] = total_inventory
                    }
                    catch {
                        data[retailSize[i]] = 0
                    }
                }
                else if (payload.accessor === "bulk_remaining") {
                    try {
                        let bulk_remaining = 0
                        // let bulk_remaining = Number(res['in07_upc'][i]) + Number(res['pna17_upc'][i]) + Number(res['in01_upc'][i]) + Number(res['ul01_upc'][i]) + Number(res['pna27_upc'][i]) + Number(res['pna31_upc'][i])
                        // for (const [key, value] of Object.entries(res["dcs_upc_value"])) {
                        //     bulk_remaining += value[i]
                        // }
                        bulk_remaining = Number(res?.dcs_upc_value?.[i])
                        data[retailSize[i]] = bulk_remaining
                    }
                    catch(e) {
                        data[retailSize[i]] = 0
                    }
                }
                else if (payload.accessor === "oo_upc" || payload.accessor === "oh_upc" || payload.accessor === "it_upc")
                    data[retailSize[i]] = res[payload.accessor][i] ? res[payload.accessor][i] : 0
                else
                    // data[retailSize[i]] = res["dcs_upc_value"][payload.accessor][i] ? res["dcs_upc_value"][payload.accessor][i] : 0
                    data[retailSize[i]] = res[`dc_${payload.accessor}_upc`][i] ? res[`dc_${payload.accessor}_upc`][i] : 0
            }
            for (let val of retailSize) {
                sizeColumns.push({
                    Header: val,
                    accessor: (data) => data[val],
                })
            }
            yield put(getReportingPopupDataSuccess({ popupData: [data], popupColumn: sizeColumns }))
        }
        else {
            yield put(getReportingPopupDataError({ 'error': ERROR_MSG }));
        }

    } catch (error) {
        yield put(getReportingPopupDataError(error));
    }


    // try {
    //     const { payload } = action
    //     const res = payload?.row
    //     if (!isEmpty(res)) {
    //         let retailSize = res?.retail_size
    //         let sizeColumns = []
    //         let data = {}
    //         for(let i in retailSize){
    //             if(payload.accessor === "total_inventory"){
    //                 try{
    //                     let total_inventory = Number(res['oh_upc'][i]) + Number(res['oo_upc'][i]) + Number(res['it_upc'][i])
    //                     data[retailSize[i]] = total_inventory
    //                 }
    //                 catch{
    //                     data[retailSize[i]] = 0
    //                 }
    //             }
    //             else if(payload.accessor === "bulk_remaining"){ 
    //                 try{
    //                     let bulk_remaining = Number(res['in07_upc'][i]) + Number(res['pna17_upc'][i]) + Number(res['in01_upc'][i]) + Number(res['ul01_upc'][i]) + Number(res['pna27_upc'][i]) + Number(res['pna31_upc'][i])
    //                     data[retailSize[i]] = bulk_remaining
    //                 }
    //                 catch{
    //                     data[retailSize[i]] = 0
    //                 }
    //             }
    //             else
    //                 data[retailSize[i]] = res[payload.accessor][i] ? res[payload.accessor][i] : 0
    //         }
    //         for (let val of retailSize) {
    //             sizeColumns.push({
    //               Header: val,
    //               accessor: (data) => data[val],
    //             })
    //         }
    //         yield put(getReportingPopupDataSuccess({ popupData: [data], popupColumn: sizeColumns }))
    //     }
    //     else {
    //         yield put(getReportingPopupDataError({ 'error': 'Something Went Wrong!!' }));
    //     }

    // } catch (error) {
    //     yield put(getReportingPopupDataError(error));
    // }
}

function* generateExcelWorker(action) {
    const PARALLEL_CALLS_COUNT = 10;

    try {
        const {payload} = action;
        const {request} = payload;
        let req = {}
        if (payload) {
            for (const key in request) {
                if (request[key] && request[key].length > 0) {
                    request[key] && (req[g_get_table_mapping[key]] = request[key]?.map((ele) => ele.value))
                }
            }
        }
        
        req["rowIndex"] = payload.rowIndex
        req["rowCount"] = payload.rowCount
        if (payload?.clearance != null) {
            req["clearance"] = [payload.clearance]
        }
        if (!isEmpty(payload?.searchTermReq)) {
            req["searchColumns"] = { ...payload?.searchTermReq }
        }

        // const res = yield call(getReporting, req);
        let parallelCalls = []
        let rowIndex = payload?.rowIndex;
        let count = 1;
        let run = true;
        while(run) {
            if(count >= PARALLEL_CALLS_COUNT) {
                run = false;
            }
            if(rowIndex < payload?.total) {
                parallelCalls.push(call(getReporting, {...req, rowIndex }))
            }
            rowIndex = payload.rowIndex + (count * payload.rowCount)
            count++;
        }
        const res = yield all(parallelCalls)
        if (res[0].data.status) {        
            let reportingData = []
            res?.forEach(item => {
                reportingData = [...reportingData, ...item?.data?.reportingData]
            })    
            yield put(fetchExcelDataSuccesss({data: reportingData, totalCountExcel: res[0].data.totalCount, nextIndexExcel: payload.rowIndex + (payload.rowCount * PARALLEL_CALLS_COUNT), nextIndex: payload.rowIndex + (payload.rowCount * PARALLEL_CALLS_COUNT) }));
  
        }
        else {
            yield put(fetchExcelDataError({error: ERROR_MSG }));
        }
    }
    catch (error) {
        yield put(fetchExcelDataError({error: ERROR_MSG }));
    }
  }

function* fetchFilterDataWatcher() {
    yield takeEvery(FETCH_FILTER_DATA, fetchFilterDataWorker);
}

function* getReportWatcher() {
    yield takeLatest(GET_REPORT, getReportWorker);
}

function* getReportingPopUpWatcher() {
    yield takeEvery(GET_POPUP, getReportingPopUpWorker)
}

function* generateExcelWatcher() {
    yield takeLatest(GENERATE_EXCEL, generateExcelWorker)
}

export function* reportingSaga() {
    yield all([
        getReportWatcher(),
        fetchFilterDataWatcher(),
        getReportingPopUpWatcher(),
        generateExcelWatcher()
    ])
}