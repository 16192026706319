import React, { useEffect, useState } from "react";
import "./Reporting.css";
import { connect } from "react-redux";
import ReactTable from "../../components/Table/ReactTable";
import WithDynamicPlugins from "../../components/Table/WithPlugins";
import PageLoader from '../../components/Loader/PageLoader';
import { Tab, Tabs } from 'react-bootstrap';
import ColumnFilter from "../UserManagement/ColumnFilter";
import NumberRangeColumnFilter from "../../components/Filters/NumberRangeColumnFilter";
import ReportingFilters from "./ReportingFilters"
import { generateExcel, getReport, getReportingPopupData, resetAll, resetDownloadExcel } from "./ReportingAction"
import LinkCell from "../../components/Table/Cellrenderer/LinkCell";
import { ReportingModal } from "./ReportingModal";
import { addPer } from "../../utils/formatters/valueFormatters";
import ExcessReport from "./ExcessReport/Excess";
import LostSalesReportContainer from "./LostSalesReport/LostSalesReportContainer";
import ColumnFilterServerSide from "../../components/Filters/ColumnFilterServerSide";
import { cloneDeep, isEmpty } from "lodash";
import NumberRangeColumnFilterServerSide from "../../components/Filters/NumberRangeColumnFilterServerSide";
import DeepDive from "./DeepDive/DeepDive";
import { hideToProd, HIDE_TABLE_COLUMNS, currentClientName } from "../../utils/commonUtilities";
import { levelFiltersLabelFormatter, updateArticleName } from "../../utils/filterLevelMapping";
import DailySummary from "./DailySummary/DailySummary";
import { DC_NAMES } from "./ReportingDCConstants";
import ExportFile from "../../components/ExportFile/ExportFile";
import * as Notify from "../../components/Notification/Notifications";
import DownloadProgressBar from "../../components/DownloadProgressBar/DownloadPropgressBar";
import { EXCEL_HEADERS, formatReportingExcelData } from "./ReportingExcelFormatter";
import ErrorModal from "../../components/ErrorModal/ErrorModal"

const pageSize = 10
const rowCount = 500
const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const Reporting = (props) => {

    const {downloadNextIndex} = props

    const [RTinstance, setRTinstance] = useState([])
    const [reportingData, setReportingData] = useState([])
    const [isFilterClicked, setIsFilterClicked] = useState(false)
    const [filters, setFilters] = useState({})
    const [initialIndex, setInitialIndex] = useState(0)
    const [prevIndex, setPrevIndex] = useState(0)
    const [nextIndex, setNextIndex] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [nextIndexExcel, setNextIndexExcel] = useState(0)
    const [totalCountExcel, setTotalCountExcel] = useState(0)
    const [clearance, setClearance] = useState(null)
    const [storeGradeKey, setStoreGradeKey] = useState("store_grade")
    const [openPopup, setopenPopup] = useState(false)
    const [popupTitle, setpopupTitle] = useState(null)
    const [articleId, setarticleId] = useState(null)
    const [storeId, setStoreId] = useState(null)
    const [activeTab, setActiveTab] = useState("report1")
    const [searchTermReq, setSearchTermReq] = useState({})
    const [sortReq, setSortReq] = useState({})
    const [isFirstCall, setisFirstCall] = useState(true)
    const [initialHiddenColumns, setInitialHiddenColumns] = useState([])
    const [dcColumns, setDCColumns] = useState([])
    const [downloadExcel, setDownloadExcel] = useState(false)
    const [req, setReq] = useState({})
    const [excelData, setExcelData] = useState([])
    const [downloadExcelLoading, setDownloadLoading] = useState(false)
    const [errorModalMessage, setErrorModalMessage] = useState(null);

    const resetExcelDetails = () => {
        setTotalCountExcel(0);
        setExcelData([]);
        setNextIndexExcel(0);
    }
    const getReport = (payload, clearance, storeGradeSource) => {
        setDownloadExcel(false)
        setReq(req)
        setStoreGradeKey(storeGradeSource)
        setPrevIndex(0)
        setInitialIndex(0)
        setTotalCount(0);
        setNextIndex(0);
        resetExcelDetails();
        setIsFilterClicked(true)
        setReportingData([])
        setRTinstance([])
        setFilters(payload)
        setClearance(clearance)
        props.getReport({ filters: payload, clearance, rowIndex: 0, rowCount: 100 })
        setSearchTermReq({})
        setSortReq({})
        setisFirstCall(true)
    }

    // const fetchData = (index) => {
    //     if (prevIndex >= index || nextIndex >= totalCount)
    //         return;
    //     // if (prevIndex >= index || index < nextIndex || nextIndex >= totalCount)
    //     setPrevIndex(index)
    //     setInitialIndex(Math.floor(nextIndex / pageSize - 1))
    //     props.getReport({ filters, clearance, rowIndex: nextIndex, rowCount, searchTermReq, sortReq })
    // }

    const onReportingPopupHandler = (popupTitle, p_row, p_accessor) => {
        setopenPopup(true)
        setpopupTitle(popupTitle)
        setarticleId(p_row.article)
        setStoreId(p_row.store_code)
        props.getReportingPopupData({ filters, row: p_row, accessor: p_accessor });
    }

    const closeAlert = () => {
        setopenPopup(false)
    };


    useEffect(() => {
        setTotalCount(props.totalCount)
        setNextIndex(props.nextIndex)
    }, [props.totalCount, props.nextIndex])

    useEffect(() => {
        setDownloadExcel(false)
        if (props.data?.length > 0) {
            if (RTinstance && RTinstance.data) {
                setReportingData([...RTinstance.data, ...props.data]);
            }
            else {
                setReportingData([...props.data]);
            }
            // setReportingData(true)
        }
        else {
            setReportingData([])
            setRTinstance(null)
        }
        if (isFilterClicked) {
            setInitialIndex(0)
            setPrevIndex(0)
            setIsFilterClicked(false)
        }
    }, [props.data])

    const generateExcel = () => {
        if(totalCount > 100000) {
            setErrorModalMessage("Please limit the number of products that you are attempting to export and try again")
        }
        else {
            setExcelData(reportingData)
            setDownloadLoading(true)
            // resetExcelDetails();
            setDownloadExcel(false)
            if(totalCount > reportingData?.length) {
                props.generateExcel({request:filters,clearance,searchTermReq,rowIndex: nextIndex, rowCount, total: totalCount })
            }
            else {
                formatExcelData(reportingData)
            }
        }
    }
    const fetchData = (index) => {
        if (prevIndex >= index || nextIndex >= totalCount)
            return;
        // if (prevIndex >= index || index < nextIndex || nextIndex >= totalCount)
        setPrevIndex(index)
        setInitialIndex(Math.floor(nextIndex / pageSize - 1))
        props.getReport({ filters, clearance, rowIndex: nextIndex, rowCount: 100, searchTermReq, sortReq })
    }

    useEffect(() => {
        if (props.excelData?.length > 0) {
            if (!isEmpty(excelData)) {
                setExcelData([...excelData, ...props.excelData]);
            }
            else {
                setExcelData([...props.excelData]);
            }
        }
        else {
            setExcelData([])
        }
        setTotalCountExcel(props.totalCountExcel)
        setNextIndexExcel(props.nextIndexExcel)
    }, [props.excelData,props.totalCountExcel, props.nextIndexExcel])
    

    useEffect(() => {
        if (downloadExcelLoading && downloadNextIndex < props.totalCountExcel) {
            props.generateExcel({request:filters,clearance,searchTermReq,rowIndex: downloadNextIndex, rowCount, total: props.totalCountExcel })
        }
        else if (downloadNextIndex > props.totalCountExcel) {
            formatExcelData(excelData.concat(props.excelData))
        }
    }, [downloadNextIndex])

    const formatExcelData = (data) => {

        setExcelData(formatReportingExcelData(data))
        setTimeout(() => {
            setDownloadLoading(false)
            setDownloadExcel(true)
        }, 1000)
        
        setTimeout(() => {
            setExcelData([])
            props.resetDownloadExcel()
        }, 2000)
    }

    useEffect(() => {
        downloadExcel && setDownloadExcel(false);
    },[downloadExcel])


    useEffect(() => {
        if(props.excelError){
            Notify.error("Error in downloading Excel!!")
        }
    },[props.excelError])

    useEffect(() => {
        if (reportingData) {
            // const {dcs} = props.storeData[0]
            let dcColumnNames = []
            DC_NAMES[currentClientName].forEach(dcName => {
                dcColumnNames.push({
                    Header: dcName?.name,
                    accessor: dcName?.acc,
                    Cell: (inst) => (
                        <LinkCell
                            {...inst}
                            onClick={() =>
                                onReportingPopupHandler(
                                    dcName?.name,
                                    inst.row.original,
                                    dcName?.acc
                                )
                            }
                        />
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.[dcName?.acc]?.value?.min} maxSearchTermValue={searchTermReq?.[dcName?.acc]?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
                    ),
                    filter: 'between',
                })
            })
            setDCColumns(dcColumnNames)
        }
    }, [reportingData])

    useEffect(() => {
        return () => {
            props.resetAll()
        }
    }, [])

    const handleTabSelect = (key) => {
        setActiveTab(key)
    }

    const fecthDataWithSearchTerm = (p_term, p_id, p_type, split_type) => {
        if (p_type === "array") {
            if(split_type) {
              p_term = p_term.replace(/\s*,\s*/g, ",")
            }
            setSearchTermReq((old) => {
              let l_searchTermReq = cloneDeep(old);
              var id = p_term.split(split_type ? split_type : " ")
              if (p_term?.trim() == 0) {
                l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id];
              } else {
                l_searchTermReq[p_id] = {
                  type: p_type,
                  values: id,
                  search: p_term,
                };
              }
              return {
                ...l_searchTermReq,
              };
            });
          } 
        else if (Array.isArray(p_term)) {
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                let min = p_term[0] == "" ? null : p_term[0]
                let max = p_term[1] == "" ? null : p_term[1]
                if (min == null && max == null) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {
                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "value": {
                            "min": min,
                            "max": max,
                        }
                    }
                }
                return {
                    ...l_searchTermReq,
                }
            })
        }
        else {
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                if (p_term?.trim() == 0) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {
                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "value": p_term
                    }
                }
                return {
                    ...l_searchTermReq,
                }
            })
        }
        isFirstCall && setisFirstCall(false)
    }

    const fecthDataWithSorting = (p_sort) => {
        if (!isEmpty(sortReq))
            isFirstCall && setisFirstCall(false)
        setSortReq(p_sort)
    }

    useEffect(() => {
        if ((!isEmpty(searchTermReq) || !isFirstCall || !isEmpty(sortReq)) && !isEmpty(filters)) {
            setReportingData([])
            setRTinstance(null)
            setInitialIndex(0)
            setPrevIndex(0)
            props.getReport({ filters, clearance, rowIndex: 0, rowCount: 100, searchTermReq, sortReq })
        }
    }, [searchTermReq, sortReq])

    const columns = [
        {
            Header: " ",
            sticky: "left",
            columns: [{
                Header: "Store Number",
                accessor: "store_code",
                Filter: (instance) => (
                    <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.store_code?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
                ),
                filter: 'bulkFilterSpaceSeperated',
                width: 100
            }]
        },
        {
            Header: "  ",
            sticky: "left",
            columns: [{
                Header: "SAP ID",
                accessor: "sap_id",
                Filter: (instance) => (
                    <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.sap_id?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
                ),
                filter: 'bulkFilterSpaceSeperated',
                width: 100
            }]
        },
        {
            Header: " ",
            sticky: "left",
            columns: [{
                Header: "Store Name",
                accessor: "store_name",
                Filter: (instance) => (
                    <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.store_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
                ),
            }]
        },
        {
            Header: levelFiltersLabelFormatter("level1"),
            accessor: "l1_name",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l1_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
        },
        {
            Header: levelFiltersLabelFormatter("level2"),
            accessor: "l2_name",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l2_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
        },
        {
            Header: levelFiltersLabelFormatter("level3"),
            accessor: "l3_name",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l3_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
        },
        {
            Header: levelFiltersLabelFormatter("level4"),
            accessor: "l4_name",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l4_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
        },
        {
            Header: levelFiltersLabelFormatter("level5"),
            accessor: "l5_name",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l5_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
        },
        {
            Header: levelFiltersLabelFormatter("level6"),
            accessor: "l6_name",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l6_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),        
        },
        {
            Header: levelFiltersLabelFormatter("level7"),
            accessor: "l7_name",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l7_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),        
        },
        {
            Header: "Store Grade",
            accessor: storeGradeKey,
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.store_grade?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, "store_grade", "exact")} />
            ),
            width: 100,
            // filter: 'equals',
        },
        {
            Header: "Region",
            accessor: "region",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.region?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
        },
        {
            Header: "Climate",
            accessor: "climate",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.climate?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
        },
        {
            Header:  `${updateArticleName(false)} ID`,
            accessor: "article",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} placeholder={"Search in bulk by ','"} searchTermValue={searchTermReq?.article?.search} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "array", ",")} />
            ),
            filter: 'bulkFilterCommaSeperated',
        },
        // {
        //     Header: 'Style ID',
        //     accessor: 'style_code',
        //     Filter: (instance) => (
        //         <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.style_code?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
        //     ),
        // },
        // {
        //     Header: 'Color ID',
        //     accessor: 'color_code',
        //     Filter: (instance) => (
        //         <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color_code?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
        //     ),
        // },
        {
            Header: "Style Description",
            accessor: "style_name",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.style_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
            width: 350
        },
        {
            Header: "Style Color",
            accessor: "color_desc",
            width: 200,
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color_desc?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),

        },
        {
            Header: "Assortment Indicator",
            accessor: "assortment_indicator",
            width: 200,
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.assortment_indicator?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
        },
        {
            Header: "Door Profile Indicator",
            accessor: "dpi_description",
            width: 200,
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.dpi_description?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
        },
        {
            Header: "On Hand",
            accessor: "oh",
            Cell: (inst) => (
                <LinkCell
                    {...inst}
                    onClick={() =>
                        onReportingPopupHandler(
                            "On Hand",
                            inst.row.original,
                            "oh_upc"
                        )
                    }
                />
            ),
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.oh?.value?.min} maxSearchTermValue={searchTermReq?.oh?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            filter: 'between',
        },
        {
            Header: "In Transit",
            accessor: "it",
            Cell: (inst) => (
                <LinkCell
                    {...inst}
                    onClick={() =>
                        onReportingPopupHandler(
                            "In Transit",
                            inst.row.original,
                            "it_upc"
                        )
                    }
                />
            ),
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.it?.value?.min} maxSearchTermValue={searchTermReq?.it?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            filter: 'between',
        },
        {
            Header: "On Order",
            accessor: "oo",
            Cell: (inst) => (
                <LinkCell
                    {...inst}
                    onClick={() =>
                        onReportingPopupHandler(
                            "On Order",
                            inst.row.original,
                            "oo_upc"
                        )
                    }
                />
            ),
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.oo?.value?.min} maxSearchTermValue={searchTermReq?.oo?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            filter: 'between',
        },
        {
            Header: "Total Inventory",
            accessor: "total_inventory",
            Cell: (inst) => (
                <LinkCell
                    {...inst}
                    onClick={() =>
                        onReportingPopupHandler(
                            "Total Inventory",
                            inst.row.original,
                            "total_inventory"
                        )
                    }
                />
            ),
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.total_inventory?.value?.min} maxSearchTermValue={searchTermReq?.total_inventory?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            filter: 'between',
        },
        {
            Header: "LW Units",
            accessor: "lw_qty",
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.lw_qty?.value?.min} maxSearchTermValue={searchTermReq?.lw_qty?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            filter: 'between',
        },
        {
            Header: "WOS",
            accessor: "wos",
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.wos?.value?.min} maxSearchTermValue={searchTermReq?.wos?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            filter: 'between',
        },
        {
            Header: "Size Integrity",
            columns: [
                {
                    Header: "OH",
                    accessor: "si_oh",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addPer(inst.value)
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.si_oh?.value?.min} maxSearchTermValue={searchTermReq?.si_oh?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
                    ),
                    filter: 'between',
                },
                {
                    Header: "IT",
                    accessor: "si_it",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addPer(inst.value)
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.si_it?.value?.min} maxSearchTermValue={searchTermReq?.si_it?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
                    ),
                    filter: 'between',
                },
                {
                    Header: "DC",
                    accessor: "si_dc",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addPer(inst.value)
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.si_dc?.value?.min} maxSearchTermValue={searchTermReq?.si_dc?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
                    ),
                    filter: 'between',
                },
                {
                    Header: "Total",
                    accessor: "si_all",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addPer(inst.value)
                                : null}
                        </div>
                    ),
                    Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.si_all?.value?.min} maxSearchTermValue={searchTermReq?.si_all?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
                    ),
                    filter: 'between',
                }
            ]
        },
        {
            Header: "DC Inventory",
            columns: dcColumns
            // columns: [
            //     {
            //         Header: "U.S. Continental",
            //         accessor: "in07",
            //         Cell: (inst) => (
            //             <LinkCell
            //                 {...inst}
            //                 onClick={() =>
            //                     onReportingPopupHandler(
            //                         "U.S. Continental",
            //                         inst.row.original,
            //                         "in07_upc"
            //                     )
            //                 }
            //             />
            //         ),
            //         Filter: (instance) => (
            //             <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.in07?.value?.min} maxSearchTermValue={searchTermReq?.in07?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            //         ),
            //         filter: 'between',
            //     },
            //     {
            //         Header: "Retail Bulk",
            //         accessor: "pna17",
            //         Cell: (inst) => (
            //             <LinkCell
            //                 {...inst}
            //                 onClick={() =>
            //                     onReportingPopupHandler(
            //                         "Retail Bulk",
            //                         inst.row.original,
            //                         "pna17_upc"
            //                     )
            //                 }
            //             />
            //         ),
            //         Filter: (instance) => (
            //             <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.pna17?.value?.min} maxSearchTermValue={searchTermReq?.pna17?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            //         ),
            //         filter: 'between',
            //     },
            //     {
            //         Header: "Infinity",
            //         accessor: "in01",
            //         Cell: (inst) => (
            //             <LinkCell
            //                 {...inst}
            //                 onClick={() =>
            //                     onReportingPopupHandler(
            //                         "Infinity",
            //                         inst.row.original,
            //                         "in01_upc"
            //                     )
            //                 }
            //             />
            //         ),
            //         Filter: (instance) => (
            //             <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.in01?.value?.min} maxSearchTermValue={searchTermReq?.in01?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            //         ),
            //         filter: 'between',
            //     },
            //     {
            //         Header: "United Legwear WHS",
            //         accessor: "ul01",
            //         Cell: (inst) => (
            //             <LinkCell
            //                 {...inst}
            //                 onClick={() =>
            //                     onReportingPopupHandler(
            //                         "United Legwear WHS",
            //                         inst.row.original,
            //                         "ul01_upc"
            //                     )
            //                 }
            //             />
            //         ),
            //         Filter: (instance) => (
            //             <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.ul01?.value?.min} maxSearchTermValue={searchTermReq?.ul01?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            //         ),
            //         filter: 'between',
            //     },
            //     {
            //         Header: "Retail Bulk WH20",
            //         accessor: "pna27",
            //         Cell: (inst) => (
            //             <LinkCell
            //                 {...inst}
            //                 onClick={() =>
            //                     onReportingPopupHandler(
            //                         "Retail Bulk WH20",
            //                         inst.row.original,
            //                         "pna27_upc"
            //                     )
            //                 }
            //             />
            //         ),
            //         Filter: (instance) => (
            //             <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.pna27?.value?.min} maxSearchTermValue={searchTermReq?.pna27?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            //         ),
            //         filter: 'between',
            //     },
            //     {
            //         Header: "PUMA Retail Stores WH10",
            //         accessor: "pna31",
            //         Cell: (inst) => (
            //             <LinkCell
            //                 {...inst}
            //                 onClick={() =>
            //                     onReportingPopupHandler(
            //                         "PUMA Retail Stores WH10",
            //                         inst.row.original,
            //                         "pna31_upc"
            //                     )
            //                 }
            //             />
            //         ),
            //         Filter: (instance) => (
            //             <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.pna31?.value?.min} maxSearchTermValue={searchTermReq?.pna31?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            //         ),
            //         filter: 'between',
            //     }
            // ]
        },
        {
            Header: "Bulk Remaining",
            accessor: "bulk_remaining",
            Cell: (inst) => (
                <LinkCell
                    {...inst}
                    onClick={() =>
                        onReportingPopupHandler(
                            "Bulk Remaining",
                            inst.row.original,
                            "bulk_remaining"
                        )
                    }
                />
            ),
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.bulk_remaining?.value?.min} maxSearchTermValue={searchTermReq?.bulk_remaining?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            filter: 'between',
        },
        {
            Header: "Article Status",
            accessor: "article_status",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.article_status?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
            width: 250
        },
        {
            Header: "Store Status",
            accessor: "store_status",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.store_status?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
            width: 250
        },
        {
            Header: "Clearance",
            accessor: "clearance",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.clearance?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
        }
    ]

    const showTab = () => {
        return process.env.REACT_APP_ENV_ID != 3
    }

    const reset=()=>{
        setSearchTermReq({})
        setFilters({})
        setisFirstCall(true)
        setSortReq({})
    }

    const closeDownloadModal = () => {
        setDownloadLoading(false)
        resetDownloadExcel()
    }

    const closeModal = () => {
        setErrorModalMessage(null);
    }

    return (
        <div className="container__wrapper">
        {errorModalMessage && <ErrorModal message={errorModalMessage} handleCancel={() => closeModal()} handleOk={() => closeModal()} handleClose={() => closeModal()} />}
            {/* <Notification/> */}

            <div className="container__header" style={{ marginLeft: "2rem" }}>
                <h1 className="fnt-lg fnt-bold">Reporting</h1>
            </div>
            <div className="container__body">
                <div className="container__tabs">
                <PageLoader loader={props?.loading} loaderMessage="Download in progress">
                    <DownloadProgressBar nextIndex={excelData?.length} totalCount={totalCountExcel || totalCount} showModal={downloadExcelLoading}
                        closeModal={closeDownloadModal} />
                    <Tabs defaultActiveKey="report1" activeKey={activeTab}
                        onSelect={handleTabSelect}>
                          <Tab eventKey="report1" title="Store Stock Drill Down">
                            {
                                activeTab == "report1" &&
                                <>

                                    <div className="filter row reporting-filters" style={{ paddingTop: "0" }}>
                                        <ReportingFilters applyFilters={getReport} reset={reset} activeTab={activeTab}/>
                                    </div>

                                    <PageLoader loader={props.dataLoading}>
                                        <div className="deepdive__export__wrapper">
                                            {reportingData.length ? <ExportFile text={"Excel"} downloadExcelData={downloadExcel} data={excelData} sheetName={"Store Stock Drill Down"} callFunc={generateExcel} fileName={"store_stock_drill_down"} type={1} tableId="reporting" headers={EXCEL_HEADERS} /> : null}
                                        </div>
                                        <ReactTableWithPlugins
                                            shouldPagination
                                            hideDropdown
                                            totalRecordsLen={reportingData?.length}
                                            totalCount={totalCount}
                                            data={reportingData ? reportingData : []}
                                            columns={columns}
                                            renderMarkup='TableMarkup'
                                            keyRT="sortAndSearch"
                                            fetchData={(data) => fetchData(data)}
                                            initialPageIndex={initialIndex}
                                            pageSize={pageSize}
                                            getInstanceOnMount={(instance) => {
                                                setRTinstance(instance)
                                            }}
                                            getManualSortByOptions={(instance) => {
                                                fecthDataWithSorting(instance)
                                            }}
                                            manualSortBy={true}
                                            tableId="reporting"
                                        />
                                    </PageLoader>
                                    {openPopup && (
                                        <ReportingModal
                                            openPopup={openPopup}
                                            closeAlert={closeAlert}
                                            popupTitle={popupTitle}
                                            articleId={articleId}
                                            storeId={storeId}
                                            data={props.popupData}
                                            column={props.popupColumn}
                                            popupLoading={props.popupLoading}
                                            popupError={props.popupError}
                                        />
                                    )}
                                </> 
                            }
                        </Tab>  
                        <Tab eventKey="lost" title="Lost Sales">
                            {
                                activeTab == "lost" &&
                                <LostSalesReportContainer activeTab={activeTab} />
                            }
                        </Tab>

                        <Tab eventKey="excess" title="Excess Inventory">
                            {
                                activeTab == "excess" &&
                                <ExcessReport activeTab={activeTab} />
                            }
                        </Tab>
                        {
                            // hideToProd() &&
                            <Tab eventKey="deepDive" title="Allocation Deep Dive">
                                {
                                    activeTab == "deepDive" &&
                                    <DeepDive activeTab={activeTab} />
                                }
                            </Tab>
                        }
                        <Tab eventKey="dailySummary" title="Daily Allocation Summary">
                            {
                                activeTab == "dailySummary" &&
                                <DailySummary activeTab={activeTab} />
                            }
                        </Tab>
                    </Tabs>
                </PageLoader>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ reporting }) => {
    return {
        data: reporting.data,
        dataLoading: reporting.dataLoading,
        dataError: reporting.dataError,
        totalCount: reporting.totalCount,
        nextIndex: reporting.nextIndex,
        popupColumn: reporting.popupColumn,
        popupData: reporting.popupData,
        popupLoading: reporting.popupLoading,
        popupError: reporting.popupError,
        excelData: reporting.excelData,
        loading: reporting.loading,
        excelError: reporting.excelError,
        excelSuccess: reporting.excelSuccess,
        totalCountExcel: reporting.totalCountExcel,
        nextIndexExcel: reporting.nextIndexExcel,
        downloadNextIndex: reporting.downloadNextIndex
    }
};

const mapDispatchToProps = (dispatch) => ({
    getReport: (payload) => dispatch(getReport(payload)),
    resetAll: () => dispatch(resetAll()),
    getReportingPopupData: (payload) => dispatch(getReportingPopupData(payload)),
    generateExcel: (payload) => dispatch(generateExcel(payload)),
    resetDownloadExcel: () => dispatch(resetDownloadExcel()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reporting);
