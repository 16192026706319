import React, { useEffect, useState } from "react";
import { intersectionWith, isEmpty, isEqual } from "lodash";
import { connect } from "react-redux";
import "../../components/Filters/Filters.css"
import {
  resetFiltersOptions,
  fetchFilterData,
  fetchDefaultConstFilterData,
} from "../../containers/Constraints/ConstraintsAction";
import * as Notify from "../../components/Notification/Notifications"
import { handleSelectAll } from "../../utils/SelectAllHandler/SelectAllHander";
import { getUserPreferenceFilter, setUserPreferenceFilter, getLevelFiltersForUserPreference, getDefaultFiltersCondition, isMandatory } from "../../utils/commonUtilities";
import MultiSelect from "../../components/Filters/MultiSelect";
import Select from "../../components/Filters/SingleSelect";

const ConstraintsFilter = React.memo(
  ({
    // channelOptions,
    // departmentOptions,
    // level3Options,
    // dcsOptions,
    // styleOptions,
    // colourOptions,
    // sizeOptions,
    ...props
  }) => {
    const [channel, setChannel] = useState(null);
    const [department, setDepartment] = useState(null);
    const [level3, setLevel3] = useState(null);
    const [assortInd, setAssortInd] = useState(null);
    const [size, setSize] = useState(null);
    const [store, setStore] = useState(null)
    const [request, setRequest] = useState(null)
    const [channelOptions, setChannelOptions] = useState(null);
    const [departmentOptions, setDepartmentOptions] = useState(null);
    const [level3Options, setLevel3Options] = useState(null);
    const [assortIndOptions, setAssortIndOptions] = useState(null);
    const [sizeOptions, setSizeOptions] = useState(null);
    const [storeOptions, setStoreOptions] = useState(null);
    const [storeNameOptions, setStoreNameOptions] = useState(null);
    const [storeName, setStoreName] = useState(null)


    useEffect(() => {
      setChannelOptions(() => props.channelOptions);
    }, [props.channelOptions]);


    useEffect(() => {
      setDepartmentOptions(() => props.departmentOptions);
    }, [props.departmentOptions]);

    useEffect(() => {
      setLevel3Options(() => props.level3Options);
    }, [props.level3Options]);

    useEffect(() => {
      setAssortIndOptions(() => props.assortIndOptions);
    }, [props.assortIndOptions]);

    useEffect(() => {
      setSizeOptions(() => props.sizeOptions);
    }, [props.sizeOptions]);

    useEffect(() => {
      setStoreOptions(() => props.storeOptions);
    }, [props.storeOptions]);

    useEffect(() => {
      setStoreNameOptions(() => props.storeNameOptions);
    }, [props.storeNameOptions]);

    useEffect(() => {
      getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, { onl1Change: onChannelChange, onl2Change: onDepartmentChange, onl3Change: onLevel3Change })
      props.fetchFilterData({}, { key: 'l1' });
    }, []);

    const resetRestFilters = (p_index) => {
      storeName && setStoreName(null);
      storeNameOptions && setStoreNameOptions(null);
      if (p_index == 0) {
        department && setDepartment(null);
        departmentOptions && setDepartmentOptions(null)
      }
      if (p_index == 0 || p_index == 1) {
        level3 && setLevel3(null);
        level3Options && setLevel3Options(null)
      }
      if(p_index <= 2) {
        size && setSize(null);
        store && setStore(null);
        sizeOptions && setSizeOptions(null)
        storeOptions && setStoreOptions(null)
        assortInd && setAssortInd(null)
        assortIndOptions && setAssortIndOptions(null);
      }
      // if (p_index == 0 || p_index == 1 || p_index == 2) {
      //   dcs && setDCS(null);
      //   dcsOptions && setDCSOptions(null)
      // }
      // if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3) {
      //   level5 && setLevel5(null);
      //   level5Options && setLevel5Options(null)
      // }
      // if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3 || p_index == 4) {
      //   level6 && setLevel6(null);
      //   level6Options && setLevel6Options(null)
      // }
      // if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3 || p_index == 4 || p_index == 5) {
      //   level7 && setLevel7(null);
      //   level7Options && setLevel7Options(null)
      // }

    };

    const onChannelChange = (val, p_userPreference) => {
      setRequest(old => {
        return {
          ...old,
          'level2': null,
          'level3': null,
          'assortment_group': null,
          'size': null,
          'store_code': null,
        }
      })
      setChannel(val);
      resetRestFilters(0);
      if (p_userPreference?.user_preference) {
        props.fetchFilterData({ level1: [val] }, { key: 'l10' });
      }
      else {
        props.fetchFilterData({ level1: [val] }, { key: 'l2' });
      }
    };
    const onDepartmentChange = (val, event, that) => {
      let valuesArray = handleSelectAll(val, event, that)
      setDepartment(valuesArray[1]);
      setRequest(old => {
        return {
          ...old,
          'level2': valuesArray[0],
          'level3': null,
          'assortment_group': null,
          'size': null,
          'store_code': null,
        }
      })
      let req_val = intersectionWith(valuesArray[0], departmentOptions, isEqual);

      resetRestFilters(1);
      //API call to get next filter
      if (event?.user_preference) {
        props.fetchFilterData({ level1: [event?.l1_name], level2: Array.isArray(val) ? val : [val] }, { key: 'l3' });
      }
      else {
        props.fetchFilterData({ level1: [channel], level2: req_val }, { key: 'l3' });
      }
    };
    const onLevel3Change = (val, event, that) => {
      let valuesArray = handleSelectAll(val, event, that)

      setLevel3(valuesArray[1]);
      setRequest(old => {
        return {
          ...old,
          'level3': valuesArray[0],
          'assortment_group': null,
          'size': null,
          'store_code': null,
        }
      })
      resetRestFilters(2);
      //API call to get next filter
      let req_val = intersectionWith(valuesArray[0], level3Options, isEqual);

      let req = {
        level1: [channel],
        level3: req_val
      }
      if (department) {
        req['level2'] = [department]
      }
      if (event?.user_preference) {
        let l_req = {
          level1: [event?.l1_name],
          level2: [event?.l2_name],
          level3: [val]
        }
        props.fetchFilterData(l_req, { key: 'l4' });
      }
      else {
        props.fetchFilterData(req, { key: 'l4' });
      }
    };

    const onSizeChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      // let value = intersectionWith(val, sizeOptions, isEqual);
      setRequest(old => {
        return {
          ...old,
          'size': valuesArray[0]
        }
      })
      setSize(valuesArray[1]);
    };

    const onStoreChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      // let value = intersectionWith(val, sizeOptions, isEqual);
      setRequest(old => {
        return {
          ...old,
          'store_code': valuesArray[0]
        }
      })
      setStore(valuesArray[1]);
      let req_val = intersectionWith(valuesArray[0], storeOptions, isEqual);

      let req = {
        level1: [channel],
        level3: level3,
        store_code: req_val
      }
      if (department) {
        req['level2'] = [department]
      }

      props.fetchFilterData(req, { key: 'l5' });
      
      resetRestFilters(3);
    };

    const onStoreNameChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      // let value = intersectionWith(val, sizeOptions, isEqual);
      setRequest(old => {
        return {
          ...old,
          'store_name': valuesArray[0]
        }
      })
      setStoreName(valuesArray[1]);
    };

    const onAssortIndChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      setRequest(old => {
        return {
          ...old,
          'assortment_group': valuesArray[0]
        }
      })
      setAssortInd(valuesArray[1]);
    }

    const applyFilters = () => {
      if (getDefaultFiltersCondition({ l1_name: channel, l2_name: department })) {
        setUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, getLevelFiltersForUserPreference({ l1_name: channel, l2_name: department, l3_name: level3 }))
        props.applyFilters({
          channel: channel,
          department: department,
          level3: level3,
          assortment_group: request?.assortment_group,
          size: request?.size,
          store_code: request?.store_code,
          store_name: storeName
        });
      } else {
        Notify.error("Please Select All Mandatory Options!!");
      }
    };

    const resetFilters = () => {
      setRequest(null)
      setChannel(null);
      setDepartment(null);
      setLevel3(null);
      setSize(null);
      setStore(null);
      setAssortInd(null);
      setStoreName(null);
      props.resetTableData();
      props.resetFiltersOptions([]);
      props.setFilters(null)
    };

    return (
      <>
        <section className="row section">
          {/* <Notification /> */}
          <div className="col-md-3 required">
            <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level1}</label>
            <Select
              name="channel"
              value={channel}
              onChange={onChannelChange}
              placeHolder={`Select ${props.filterLabels?.level1}`}
              options={channelOptions?.length ? channelOptions : []}
            />
          </div>
          <div className={`col-md-3 ${isMandatory("l2_name")}`}>
            <label className="fnt-md fnt-bold">{props.filterLabels?.level2}</label>
            <MultiSelect
              name="department"
              dropdownValue={department}
              onDropDownChange={onDepartmentChange}
              placeHolder={`Select ${props.filterLabels?.level2}`}
              optionsArray={departmentOptions?.length ? departmentOptions : []}
            />
          </div>
          <div className={`col-md-3`}>
            <label className="fnt-md fnt-bold">{props.filterLabels?.level3}</label>
            <MultiSelect
              name="level3"
              dropdownValue={level3}
              onDropDownChange={onLevel3Change}
              placeHolder={`Select ${props.filterLabels?.level3 ? props.filterLabels?.level3 : ""}`}
              optionsArray={level3Options?.length ? level3Options : []}
            />
          </div>
          {/* <div className="col-md-3">
            <label className="fnt-md fnt-bold">Assortment Group</label>
            <MultiSelect
              name="assortmentIndicator"
              dropdownValue={assortInd}
              onDropDownChange={onAssortIndChange}
              placeHolder={"Select Assort Group"}
              optionsArray={assortIndOptions?.length ? assortIndOptions : []}
            />
          </div> */}
          <div className="col-md-3">
            <label className="fnt-md fnt-bold">Size</label>
            <MultiSelect
              name="size"
              dropdownValue={size}
              onDropDownChange={onSizeChange}
              placeHolder={"Select Size"}
              optionsArray={sizeOptions?.length ? sizeOptions : []}
            />
          </div>
          <div className="col-md-3">
            <label className="fnt-md fnt-bold">Store Number</label>
            <MultiSelect
              name="store"
              dropdownValue={store}
              onDropDownChange={onStoreChange}
              placeHolder={"Select Store"}
              optionsArray={storeOptions?.length ? storeOptions : []}
            />
          </div>
          <div className="col-md-3">
            <label className="fnt-md fnt-bold">Store Name</label>
            <MultiSelect
              name="store_name"
              dropdownValue={storeName}
              onDropDownChange={onStoreNameChange}
              placeHolder={"Select Store Name"}
              optionsArray={storeNameOptions?.length ? storeNameOptions : []}
            />
          </div>
          <div className="col-md-3">
            <button
              onClick={applyFilters}
              className="btn btn-primary filter-constraints-mode"
              title="Apply filters"
            >
              <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
            </button>
            <button
              onClick={() => resetFilters("All")}
              className="btn undo-constraints-mode"
              title="Reset filters"
            >
              <i className="fa fa-undo mr-2"></i>Reset
            </button>
          </div>
          <div className="col-md-3 required fnt-md pt-5" style={{ color: "red" }}>
            <label></label>Fields are mandatory
          </div>
        </section>
      </>
    );
  }
);

const mapStateToProps = ({ constraints }) => ({
  filterLabels: constraints.filterlabels,
  channelOptions: constraints.channelOptions,
  departmentOptions: constraints.departmentOptions,
  level3Options: constraints.level3Options,
  assortIndOptions: constraints.assortIndOptions,
  sizeOptions: constraints.sizeOptions,
  storeOptions: constraints.storeOptions,
  storeNameOptions: constraints.storeNameOptions,
});

const mapDispatchToProps = (dispatch) => ({
  resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
  fetchFilterData: (payload, filterKey, filterType) =>
    dispatch(fetchDefaultConstFilterData(payload, filterKey, filterType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConstraintsFilter);
