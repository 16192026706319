import { getLabel } from "../../../utils/commonUtilities";
import { updateArticleName } from "../../../utils/filterLevelMapping";

export const DAILY_SUMMARY_TABLE_HEADERS = {
    ARTICLE_ID: `${updateArticleName(false)} ID`,
    STYLE_DESCRIPTION: "Style Description",
    STYLE_COLOR: "Style Color",
    COLOR: "Color",
    ALLOCATION_ID: "Plan Name",
    STORE_ID: "Store Number",
    STORE_NAME: "Store Name",
    SAP_ID: "SAP ID",
    UNITS_ALLOCATED: "Units Allocated",
    DC_UNITS_OH: "DC Units OH",
    STYLE_ID: "Style ID",
    COLOR_ID: "Color ID",
    RESERVE_QTY: "Reserved Qty",
    TOTAL: "Total"
};

export const CARDS = {
    CARD1: "# of Allocation",
    CARD2: "# of Style colors Allocation",
    CARD3: "# of Units Allocation",
    CARD4: "DC Units OH",
    CARD5: "Reserve Quantity",
}

export const formatExcelData = (data) => {
    let excelData = [];
    data.forEach(item => {
      excelData.push({
        [`${updateArticleName(false)} ID`]: item.article,
        "Style Description": item.style_name,
        "Style Color": item.color_desc,
        "Color": item.color,
        "Channel": item.l1_name,
        "Department": item.l2_name,
        "Class": item.l3_name,
        "Style Group": item.l4_name,
        "Silhoutte": item.l5_name,
        "Sub Collect": item.l6_name,
        "Product Type": item.l7_name,
        "Store Number": item.store,
        "SAP ID": item.sap_id,
        "Store Name": item.store_name,
        "Size": item.retail_size_cd,
        "DC": item.dc_codes,
        "Allocation Code": item.allocation_code,
        "Allocated Units": item.units_allocated,
        "DC Available": item.dc_available,
        "OH": item.oh,
        "OO": item.oo,
        "IT": item.it
      })
    })
    return excelData;
  }